import React from 'react'
import { Radio as AntdRadio, RadioProps } from 'antd'
import styled from 'styled-components'
import { Text } from 'rebass'

const AntdRadioWrapper = styled(AntdRadio)`
  .ant-radio-checked {
    .ant-radio-inner {
      border: 1px solid #48e8cc;
    }
  }
  .ant-radio-inner {
    border: 1px solid #d7f0ea;
    &:hover,
    &:focus,
    &:active {
      border: 1px solid #48e8cc;
    }
    &::after {
      background: #48e8cc;
    }
  }
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border: 1px solid #48e8cc;
  }
  &.ant-radio-wrapper:first-child {
    margin-right: 24px;
  }
`

interface Props extends RadioProps {
  defaultValue: string
  options: string[]
}

const Radio: React.FC<Props> = ({ defaultValue, options, ...rest }: Props) => {
  return (
    <AntdRadioWrapper.Group value={defaultValue} {...rest}>
      {options.map(op => (
        <AntdRadioWrapper key={op} value={op}>
          <Text fontSize={14} color="#3B199F" lineHeight={'17px'}>
            {op}
          </Text>
        </AntdRadioWrapper>
      ))}
    </AntdRadioWrapper.Group>
  )
}

export default Radio
