import React, { useMemo } from 'react'
import styled from 'styled-components'
import SwapDetails from '../../components/swap/SwapDetails'
import { ItemContentDark } from '../../components/swap/styleds'
import { formatExecutionPrice } from '../../utils/prices'
import IconSelect from '../../assets/Swap/icon_selected.svg'
import IconPullMessage from '../../assets/Swap/icon_select.svg'
import { Trade } from 'so-swap-sdk'
import SwapPopMessage from './SwapPopMessage'
import Arrow from '../../components/Arrow'
import SwapArrow from './SwapArrow'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { useDerivedMintInfo } from '../../state/mint/hooks'
import { ReactComponent as IconGas } from '../../assets/Swap/icon_gas.svg'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import { useTokensListData } from '../../state/info/hook'
import { BigNumber } from 'bignumber.js'
import { APPROVE_GAS, SWAP_GAS } from '../../constants/gas'
import { Text } from 'rebass'

interface SwapPop {
  trade: Trade
  allowedSlippage: number
  showInverted: boolean
  showDetails: boolean
  setShowDetails: React.Dispatch<boolean>
}

const SwapPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 16px 12px;
  :hover {
    cursor: pointer;
  }
  > div {
    display: flex;
    align-items: center;
  }
  .gas-value {
    display: flex;
    align-items: center;
  }
`
export default function SwapPrice({ trade, allowedSlippage, showInverted, showDetails, setShowDetails }: SwapPop) {
  const pair = trade?.route?.pairs?.[0]
  const currency0 = unwrappedToken(pair?.token0)
  const currency1 = unwrappedToken(pair?.token1)
  const { price } = useDerivedMintInfo(currency0 ?? undefined, currency1 ?? undefined)
  const [approval] = useApproveCallbackFromTrade(trade, allowedSlippage)
  const { data: tokensData } = useTokensListData(false)

  const executionPrice = useMemo(() => {
    const targetAmount = pair?.token0?.symbol?.includes(trade?.inputAmount?.currency?.symbol)
      ? price?.invert()?.toSignificant()
      : price?.toSignificant()

    return `1 ${trade?.outputAmount?.currency?.symbol} = ${targetAmount} ${trade?.inputAmount?.currency?.symbol}`
  }, [pair?.token0?.symbol, price, trade?.inputAmount?.currency?.symbol, trade?.outputAmount?.currency?.symbol])

  const btcUsdPrice = useMemo(() => {
    return tokensData.find(token => token.symbol === 'BTC')?.priceNum
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tokensData)])

  const gasUsdPrice = useMemo(() => {
    const gasAmount = approval === ApprovalState.APPROVED ? SWAP_GAS : SWAP_GAS + APPROVE_GAS
    return new BigNumber(gasAmount).multipliedBy(btcUsdPrice).toFixed(2)
  }, [approval, btcUsdPrice])

  return (
    <SwapPriceWrapper onClick={() => setShowDetails(!showDetails)}>
      <div>
        {/*<SwapPopMessage content={<SwapDetails trade={trade} allowedSlippage={allowedSlippage} showSlippage={true} />} />*/}
        {/*<ItemContentDark>{formatExecutionPrice(trade, !showInverted)}</ItemContentDark>*/}
        <ItemContentDark>{executionPrice}</ItemContentDark>
      </div>
      <div>
        <div className={'gas-value'}>
          <IconGas />
          <Text padding={'0 12px 0 6px'} fontSize={14} fontWeight={400} color={'#988EB7'} lineHeight={'17px'}>
            ${gasUsdPrice}
          </Text>
        </div>
        <SwapArrow isUp={showDetails} />
      </div>
    </SwapPriceWrapper>
  )
}
