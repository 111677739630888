import { Steps as AntdSteps } from 'antd'
import React from 'react'
import styled from 'styled-components'

const { Step } = AntdSteps

const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  .ant-steps-item {
    .ant-steps-item-container {
      .ant-steps-item-icon {
        border: 1px solid #988eb7;
        .ant-steps-icon {
          font-size: 16px;
          color: #988eb7;
          font-weight: 500;
        }
      }
      .ant-steps-item-content {
        .ant-steps-item-title {
          font-size: 16px;
          font-weight: 400;
          color: #988eb7;
          //line-height: 24px;
          &:after {
            background-color: #d4d9f0;
          }
        }
      }
    }
    &.ant-steps-item-active {
      .ant-steps-item-icon {
        background: #6d4ec9;
        .ant-steps-icon {
          color: white;
        }
      }
    }
    &.ant-steps-item-finish {
      .ant-steps-item-icon {
        background: #6d4ec9;
        .ant-steps-icon {
          color: white;
          font-weight: 600;
        }
      }
    }
  }
  .step-content {
    width: 100%;
  }
`

export type StepItem = { key: string; title: string; content: React.ReactNode; description?: string }

interface Props {
  current: number
  onChange: (val: number) => void
  stepItems: StepItem[]
  direction?: 'horizontal' | 'vertical'
}

export const Steps: React.FC<Props> = ({ current, onChange, stepItems, direction = 'horizontal' }: Props) => {
  return (
    <StepsWrapper>
      <AntdSteps direction={direction} current={current}>
        {stepItems.map(stepItem => (
          <Step title={stepItem.title} key={stepItem.key} description={stepItem.description} />
        ))}
      </AntdSteps>
      <div className="step-content">{stepItems[current].content}</div>
    </StepsWrapper>
  )
}
