import BigNumber from 'bignumber.js'

export const retainEffectiveDigits = (num: number, digits = 2): string => {
  if (num < 1) {
    return new BigNumber(num.toPrecision(2)).toFixed()
  }
  const str = num.toString()
  const decimalIndex = str.indexOf('.')

  // 如果没有小数部分，直接返回
  if (decimalIndex === -1) return num.toFixed(digits)

  const nonZeroIndex = str.slice(decimalIndex).search(/[^0\.]/) + decimalIndex

  // 如果小数部分没有非零数字，直接返回
  if (nonZeroIndex === -1) return num.toString()

  const end = nonZeroIndex + digits
  return str.slice(0, end)
}
