enum BridgeChain {
  bitcoin = 'Bitcoin',
  chainx = 'BEVM'
}

enum BridgeTokenType {
  token = 'token',
  inscription = 'inscription'
}

enum BridgeTxType {
  Bridge = 'Bridge',
  InscribeTransfer = 'Inscribe transaction inscriptions'
}

export type BridgeTokenItem = {
  type: string
  symbol: string
  address: string
  tick: string
  decimal: number
}

export { BridgeChain, BridgeTokenType, BridgeTxType }
