import { useEffect, useState } from 'react'
import { useActiveWeb3React } from './index'
import { getClaimBTCContract } from '../utils';
import { BigNumber } from 'bignumber.js';
import useRedeemStore from '../store/redeem';
import { useAllTransactions } from '../state/transactions/hooks';
import { truncateFixed } from '../helper/truncateFixed';

export const useCheckEligibility = (): { isEligibilityUser: string, hasLpStatus: string, allLpBalance, ordiMaxWBTC: string, currentWBTCBalance: string, currentWBTCOrdiBalance: string, currentWBTCPcxBalance: string, currentWBTCSatsBalance: string } => {
  const { account, library } = useActiveWeb3React()
  const claimBTCContract = getClaimBTCContract(library, account)
  const {setCheckLpLoading, setHasLP, setCurrentRedeemStep, setDisableRedeem} = useRedeemStore()
  const [isEligibilityUser, setIsEligibilityUser] = useState('checking')
  const [hasLpStatus, setHasLpStatus] = useState('')
  const [ordiMaxWBTC, setOrdiMaxWBTC] = useState('')
  const [currentWBTCBalance, setCurrentWBTCBalance] = useState('')
  const [currentWBTCOrdiBalance, setCurrentWBTCOrdiBalance] = useState('')
  const [currentWBTCPcxBalance, setCurrentWBTCPcxBalance] = useState('')
  const [currentWBTCSatsBalance, setCurrentWBTCSatsBalance] = useState('')
  const [allLpBalance, setAllLpBalance] = useState('0.0')
  const allTransactions = useAllTransactions()
  const { showRedeemSwap } = useRedeemStore()

  useEffect(() => {
    const getUserStatus = async () => {
      console.log('checking eligibility')
      setIsEligibilityUser('checking')
      setDisableRedeem(true)
      const result = await claimBTCContract?.check_eligibility(account)
      if(result && result[0]){
        const OrdiMaxWBTC = new BigNumber(result[1].toString()).dividedBy(Math.pow(10,18))
        if(OrdiMaxWBTC.isGreaterThan(0)){
          setIsEligibilityUser( 'eligibility')
          setCurrentRedeemStep(1)
        }else{
          setIsEligibilityUser( 'claimed')
          setDisableRedeem(true)
          return
        }
        setCurrentRedeemStep(OrdiMaxWBTC.isGreaterThan(0) ? 1 : 0)
        setCurrentWBTCBalance(new BigNumber(result[2].toString()).dividedBy(Math.pow(10,18)).toString())
        const WBTCOrdi = new BigNumber(result[3].toString()).dividedBy(Math.pow(10,18))
        const WBTCPcx = new BigNumber(result[4].toString()).dividedBy(Math.pow(10,18))
        const WBTCSats = new BigNumber(result[5].toString()).dividedBy(Math.pow(10,18))
        setOrdiMaxWBTC(OrdiMaxWBTC.toString())
        setCurrentWBTCOrdiBalance(WBTCOrdi.toString())
        setCurrentWBTCPcxBalance(WBTCPcx.toString())
        setCurrentWBTCSatsBalance(WBTCSats.toString())
        setAllLpBalance(truncateFixed(WBTCOrdi.plus(WBTCPcx).plus(WBTCSats).toString()))
        if(WBTCOrdi.isGreaterThan(0) || WBTCPcx.isGreaterThan(0) || WBTCSats.isGreaterThan(0)){
          setHasLpStatus('hasLP')
          setHasLP(true)
        }else{
          setHasLpStatus('noLP')
          setCurrentRedeemStep(2)
          setHasLP(false)
          setDisableRedeem(false)
        }
      }else {
        setCurrentRedeemStep(0)
        setIsEligibilityUser('unable')
        setCheckLpLoading(false)
      }
    }
    getUserStatus()
  }, [account, library, Object.keys(allTransactions).length, showRedeemSwap] )


  return { isEligibilityUser, hasLpStatus, allLpBalance, ordiMaxWBTC, currentWBTCBalance, currentWBTCOrdiBalance, currentWBTCPcxBalance, currentWBTCSatsBalance }
}
