import styled from 'styled-components'
import { Table } from 'antd'

export const TableWrapper = styled(Table)`
  .ant-table {
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
    background: #ffffff;
    overflow: scroll !important;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    &.ant-table-empty {
      border-radius: 24px;
    }
  }
  .ant-spin-container {
    background: white;
    box-shadow: 0 2px 4px 0 rgba(109, 78, 201, 0.06);
    border-radius: 24px;
    &::after {
      border-radius: 24px;
    }
  }
  .ant-table-thead {
    .ant-table-cell {
      font-size: 14px;
      font-weight: 600;
      color: #6d4ec9;
      line-height: 22px;
      background: white;
      border-bottom: none;

      &.transaction-name {
        padding-left: 35px;
      }
      &:first-child {
        border-top-left-radius: 24px;
      }
      &:last-child {
        border-top-right-radius: 24px;
      }
      &::before {
        display: none;
      }
      .ant-table-column-sorters {
        width: fit-content;
      }
      .ant-table-column-sorter {
        display: flex;
        align-items: center;

        .ant-table-column-sorter-up,
        .ant-table-column-sorter-down {
          &.active {
            color: #38dcbf;
          }
        }
      }
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      font-size: 16px;
      font-weight: 600;
      color: #281071;
      line-height: 20px;
      border-bottom: none;
      width: 200px;

      &.token-name {
        width: 400px;
      }

      &.transaction-name {
        padding-left: 35px;
      }

      &.ant-table-column-sort {
        background: white;
      }

      &.ant-table-cell-row-hover {
        &.ant-table-column-sort {
          background: #f5f1fc;
        }
        background: #f5f1fc;
      }

      &.prefix-id {
        width: 5px;
        &-empty {
          width: 1px;
        }
      }
    }
  }

  .ant-pagination {
    padding-bottom: 22px;
    margin-top: 16px;
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    display: flex;
    align-items: center;
    justify-content: center;
    &.ant-pagination-disabled {
      svg path {
        fill: #e6e6e6;
      }
    }
  }

  .ant-pagination-simple-pager {
    font-size: 14px;
    font-weight: 600;
    color: #281071;
    line-height: 22px;
  }
`

export const AddressLinkWrapper = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #38dcbf;
  line-height: 20px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const PriceChangerWrapper = styled.div<{ isRise: boolean }>`
  color: ${({ isRise }) => (isRise ? '#38DCBF' : '#E93E5E')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
`

export const TokenNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .token-name {
    padding: 0 8px;
  }
`

export const TableTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #281071;
  line-height: 21px;
  padding: 32px 0 16px 0;
`

export const RadioWrapper = styled.div`
  padding: 8px 0 16px 0;
`