import React from 'react'
import { Popover, PopoverProps } from 'antd'
import PopoverIcon from '../../assets/images/icon_tip.svg'
import './index.css'

interface Props extends PopoverProps {
  content?: any
  title?: React.ReactNode
  trigger?: string
  icon?: string
}

const PoPover: React.FC<Props> = ({ title, content, trigger, icon, ...rest }: Props) => {
  return (
    <Popover trigger={trigger} content={content}>
      <img
        src={icon ? icon : PopoverIcon}
        alt=""
        style={{ width: '16px', height: '16px', marginLeft: '4px' }}
        {...rest}
      />
    </Popover>
  )
}

export default PoPover
