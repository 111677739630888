import axios from 'axios'
import { create } from 'zustand'
import { useState } from 'react';

interface RedeemStore {
  currentRedeemStep: number
  setCurrentRedeemStep: (val: number) => void
  agreeWarning: boolean
  setAgreeWarning: (val: boolean) => void
  checkLpLoading: boolean
  setCheckLpLoading: (val: boolean) => void
  hasLP: boolean
  setHasLP: (val: boolean) => void
  disableRedeem: boolean
  setDisableRedeem: (val: boolean) => void
  showRedeemSwap: boolean
  setShowRedeemSwap: (val: boolean) => void
}


const useRedeemStore = create<RedeemStore>((set, get) => ({
  currentRedeemStep: 0,
  setCurrentRedeemStep: val => {
    set({currentRedeemStep: val})
  },
  agreeWarning: false,
  setAgreeWarning: val => {
    set({agreeWarning: val})
  },
  checkLpLoading: true,
  setCheckLpLoading: val => {
    set({checkLpLoading: val})
  },
  hasLP: false,
  setHasLP: val => {
    set({hasLP: val})
  },
  disableRedeem: true,
  setDisableRedeem: val => {
    set({disableRedeem: val})
  },
  showRedeemSwap: false,
  setShowRedeemSwap: val => {
    set({showRedeemSwap: val})
  },
}))

export default useRedeemStore
