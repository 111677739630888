import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Slider as AntdSlider, SliderSingleProps } from 'antd'

const AntdSliderWrapper = styled(AntdSlider)`
  .ant-slider-rail {
    background: #e8f6f3;
    border-radius: 2px;

    &:hover {
      background: #e8f6f3;
    }
  }

  .ant-slider-handle {
    background: #47dfc4;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    border-radius: 100%;
    border: 2px solid #ffffff;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
    margin-top: -11px;

    &:hover,
    &:focus {
      border-color: white;
    }
  }

  .ant-slider-track {
    background: #47dfc4;
    border-radius: 2px;
  }

  &:hover,
  &:focus {
    .ant-slider-rail {
      background: #e8f6f3 !important;
    }
    .ant-slider-handle {
      border-color: white !important;
    }
    .ant-slider-track {
      background: #47dfc4 !important;
    }
  }
`

export default function Slider({
  value,
  onChange,
  min = 0,
  step = 1,
  max = 100
}: SliderSingleProps): React.ReactElement {
  const changeCallback = useCallback(
    value => {
      onChange(parseInt(value))
    },
    [onChange]
  )

  return (
    <AntdSliderWrapper value={value} onChange={changeCallback} step={step} min={min} max={max} tooltipVisible={false} />
  )
}
