import React, { useCallback, useEffect, useState } from 'react'
import { Currency, ChainId } from 'so-swap-sdk'
import ReactGA from 'react-ga'

import Dialog from './index'
import useLast from '../../hooks/useLast'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../state'
import { selectList } from '../../state/lists/actions'
import { ListSelect } from '../SearchModal/ListSelect'
import { CurrencySearch } from '../SearchModal/CurrencySearch'
import { Text } from 'rebass'
import QuestionHelper from '../QuestionHelper'
import { useActiveWeb3React } from '../../hooks'
import { DEFAULT_TOKEN_LIST_URL } from '../../constants/tokenLists'
interface CurrencySearchModalProps {
  isOpen: boolean
  onDismiss: () => void
  selectedCurrency?: Currency | null
  onCurrencySelect?: (currency: Currency) => void
  otherSelectedCurrency?: Currency | null
  showCommonBases?: boolean
}

const CurrencySearchDialog: React.FC<CurrencySearchModalProps> = ({
  isOpen,
  onDismiss,
  onCurrencySelect,
  selectedCurrency,
  otherSelectedCurrency,
  showCommonBases = false
}: CurrencySearchModalProps) => {
  const [listView, setListView] = useState<boolean>(false)
  const lastOpen = useLast(isOpen)

  useEffect(() => {
    if (isOpen && !lastOpen) {
      setListView(false)
    }
  }, [isOpen, lastOpen])

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      onCurrencySelect(currency)
      onDismiss()
    },
    [onDismiss, onCurrencySelect]
  )

  const handleClickChangeList = useCallback(() => {
    ReactGA.event({
      category: 'Lists',
      action: 'Change Lists'
    })
    setListView(true)
  }, [])
  const handleClickBack = useCallback(() => {
    ReactGA.event({
      category: 'Lists',
      action: 'Back'
    })
    setListView(false)
  }, [])

  return (
    <Dialog
      className="currency-search-dialog"
      bodyStyle={{
        padding: '0'
      }}
      title={
        <Text fontWeight={600} fontSize={16} color="#281071" lineHeight="20px">
          Select a token
          <QuestionHelper text="Find a token by searching for its name or symbol or by pasting its address below." />
        </Text>
      }
      visible={isOpen}
      onCancel={onDismiss}
      footer={null}
      minHeight={'80vh'}
    >
      {listView ? (
        <ListSelect onDismiss={onDismiss} onBack={handleClickBack} />
      ) : (
        <CurrencySearch
          isOpen={isOpen}
          onDismiss={onDismiss}
          onCurrencySelect={handleCurrencySelect}
          onChangeList={handleClickChangeList}
          selectedCurrency={selectedCurrency}
          otherSelectedCurrency={otherSelectedCurrency}
          showCommonBases={showCommonBases}
        />
      )}
    </Dialog>
  )
}

export default CurrencySearchDialog
