import { ChainId, JSBI, Percent, Token, WBTC } from 'so-swap-sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { fortmatic, injected, portis, walletconnect, walletlink } from '../connectors'
import { SUPPORTED_TOKENS } from './redEnvelope'

// export const ROUTER_ADDRESS = '0xC7Dd38D6D161e2a440617508308639B2d701F633'
//route地址
export const ROUTER_ADDRESS = '0xC7Dd38D6D161e2a440617508308639B2d701F633'
export const MINI_PCX_STAKING_ADDRESS = '0x5a8f56FF3dfbF579CaFd673f26C282fd911cb926'
export const SBTC_PCX_STAKING_ADDRESS = '0x20dc6f916539d537108B5F9D634DCb3e6181BE08'
export const PCX_SO_STAKING_ADDRESS = '0x196c7490d76D4d4324e5a35d5B98A995De5FDf82'
// export const SOSWAP_STAKING_ADDRESS = '0xaf8A2ded3A2CCE678D6150bf13255b184823Ad1d'
export const SOSWAP_STAKING_ADDRESS = '0xDBD1Cc42412B6377bE167c0Df7f87CA5745b21eB'

export const GRAB_ADDRESS = '0xb203100A84990b4C9317275cF11240053006Fd5e'
// export const GRAB_ADDRESS = '0xD9aE39742f18B57628CD7d3Be948C228Fc6b0Fd5'

export const GRAB_LUSDT_TOKEN = '0x955FF7714AdD018E7f5fDfB1EfD786447CDC3670'
export const GRAB_USDT_TOKEN = '0x4B53739D798EF0BEa5607c254336b40a93c75b52'
export const GRAB_LUCK_TOKEN = '0x57db980aec0C06F2AF376fd8C1A77d837493482F'


// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

// export const SBTC = new Token(ChainId.SHERPAX, '0x3469E1DaC06611030AEce8209F07501E9A7aCC69', 18, 'SBTC', 'SBTC')
// export const USDC = new Token(ChainId.SHERPAX, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6, 'USDC', 'USD//C')
export const MINI = new Token(ChainId.CHAINX, '0x91AAC463B5473BDe2Fdd910f09101ed687BCB97A', 8, 'MINI', 'MINI')
export const sBTC = new Token(ChainId.CHAINX, '0xc91a8e500E814990b06BDb4fC10745124fdBE748', 8, 'sBTC', 'sBTC')
export const DOT = new Token(ChainId.CHAINX, '0x0F5d9Ac5833C4d089097Fe2eAbBE546b9f10c422', 10, 'DOT', 'Polkadot')
export const KSM = new Token(ChainId.CHAINX, '0xb513E3Cf3BD0CcFfB02Aefbc4794df3D01aB2515', 12, 'KSM', 'Kusama')
export const AMPL = new Token(ChainId.CHAINX, '0xD46bA6D942050d489DBd938a2C909A5d5039A161', 9, 'AMPL', 'Ampleforth')
// BSC
export const BUSD = new Token(
  ChainId.BSC,
  '0x77eD6a802aB1d60A86F2e3c45B43a0Cd7Ee2572B',
  18,
  'BUSD',
  'Binance BUSD Token'
)
export const USDC = new Token(ChainId.BSC, '0xa017362eB5B22302e4E5c55786f651214BD168A2', 18, 'USDC', 'Binance USD Coin')
export const USDT = new Token(
  ChainId.BSC,
  '0x1635583ACf7beF762E8119887b2f3B9F9BcD1742',
  18,
  'USDT',
  'Binance Tether USD'
)
export const SATS = new Token(ChainId.CHAINX, SUPPORTED_TOKENS.SATS.address, 18, 'SATS', 'BRC-20 SATS')
export const ORDI = new Token(ChainId.CHAINX, SUPPORTED_TOKENS.ORDI.address, 18, 'ORDI', 'BRC-20 ORDI')
export const PCX = new Token(ChainId.CHAINX, '0xf3607524cAB05762cB5F0cAb17e4cA3A0F0b4E87', 8, 'PCX', 'Chainx')
export const BPCX = new Token(ChainId.CHAINX, '0xdf8cf14ecb8f2cbb26d1e74c9774344fb7caecf7', 18, 'BPCX', 'BRC-20 BPCX')

const WBTC_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WBTC[ChainId.MAINNET]],
  [ChainId.ROPSTEN]: [WBTC[ChainId.ROPSTEN]],
  [ChainId.RINKEBY]: [WBTC[ChainId.RINKEBY]],
  [ChainId.GÖRLI]: [WBTC[ChainId.GÖRLI]],
  [ChainId.KOVAN]: [WBTC[ChainId.KOVAN]],
  [ChainId.MATIC]: [WBTC[ChainId.MATIC]],
  [ChainId.BSC]: [WBTC[ChainId.BSC]],
  [ChainId.BSCTEST]: [WBTC[ChainId.BSCTEST]],
  [ChainId.BEVMTEST]: [WBTC[ChainId.BEVMTEST]],
  [ChainId.CHAINX]: [WBTC[ChainId.CHAINX]]
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WBTC_ONLY,
  [ChainId.CHAINX]: [...WBTC_ONLY[ChainId.CHAINX]]
  // [ChainId.SHERPAXTEST]: [...WETH_ONLY[ChainId.SHERPAXTEST]]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.CHAINX]: {
    [AMPL.address]: [WBTC[ChainId.CHAINX]]
  },
  [ChainId.BSC]: {
    [AMPL.address]: [WBTC[ChainId.BSC]]
  }
  // [ChainId.CHAINXEVM]: {
  //   [AMPL.address]: [WBTC[ChainId.CHAINXEVM]]
  // }
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WBTC_ONLY
  // [ChainId.CHAINX]: [PCX]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WBTC_ONLY,
  [ChainId.CHAINX]: [...WBTC_ONLY[ChainId.CHAINX], SATS, BPCX, ORDI, PCX],

}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.CHAINX]: [
    // [
    //   new Token(ChainId.CHAINX, '0xf3607524cAB05762cB5F0cAb17e4cA3A0F0b4E87', 8, 'PCX', 'Bevm'),
    //   new Token(ChainId.CHAINX, '0xdf8cf14ecb8f2cbb26d1e74c9774344fb7caecf7', 18, 'BPCX', 'BRC-20 BPCX')
    // ]
    // [
    //   new Token(ChainId.CHAINX, '0x1d1ce1d02ba582cffd0fc0093fd6bab678708b4e', 18, 'ORDI', 'BRC-20 ORDI'),
    //   new Token(ChainId.CHAINX, '0x6e128a3bcfc042d539cae6fe761ab3ef6d0e298c', 18, 'SATS', 'BRC-20 SATS')
    // ]
  ]
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const MOBILE_SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  }
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
    mobile: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
    // mobile: true
  }
  // WALLET_CONNECT: {
  //   connector: walletconnect,
  //   name: 'WalletConnect',
  //   iconName: 'walletConnectIcon.svg',
  //   description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
  //   href: null,
  //   color: '#4196FC',
  //   mobile: true
  // },
  // WALLET_LINK: {
  //   connector: walletlink,
  //   name: 'Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Use Coinbase Wallet app on mobile device',
  //   href: null,
  //   color: '#315CF5'
  // },
  // COINBASE_LINK: {
  //   name: 'Open in Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Open in Coinbase Wallet app.',
  //   href: 'https://go.cb-w.com/mtUDhEZPy1',
  //   color: '#315CF5',
  //   mobile: true,
  //   mobileOnly: true
  // },
  // FORTMATIC: {
  //   connector: fortmatic,
  //   name: 'Fortmatic',
  //   iconName: 'fortmaticIcon.png',
  //   description: 'Login using Fortmatic hosted wallet',
  //   href: null,
  //   color: '#6748FF',
  //   mobile: true
  // },
  // Portis: {
  //   connector: portis,
  //   name: 'Portis',
  //   iconName: 'portisIcon.png',
  //   description: 'Login using Portis hosted wallet',
  //   href: null,
  //   color: '#4A6C9B',
  //   mobile: true
  // },
  // WALLET_CONNECT_V2: {
  //   //@ts-ignore
  //   connector: walletconnectV2,
  //   name: 'WalletConnectV2',
  //   iconName: 'walletConnectIcon.svg',
  //   description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
  //   href: null,
  //   color: '#4196FC',
  //   mobile: true
  // }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much BTC so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(13)) // .01 BTC
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))
