import React, { useState } from 'react'
import { Text } from 'rebass'
import styled from 'styled-components';
import { Radio } from 'antd';
import { StepItem, RedeemSteps } from '../../components/RedeemSteps'
import { useCheckEligibility } from '../../hooks/useCheckEligibility';
import Dialog from '../../components/Dialog';
import { ButtonLightGreenLink, ButtonPrimary } from '../../components/Button';
import { WhiteCard } from '../../components/Card';
import { Container } from '../PoolDetails/styles';
import { ReactComponent as IconWarning } from '../../assets/icon_warning.svg'
import Modal from '../../components/Modal';
import useRedeemStore from '../../store/redeem';
import { Link } from 'react-router-dom';

const RedeemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .notice-step{
    background: #F1F5FB;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
  }
  .github-link{
    font-size: 16px;
    color: #48E8CC;
    line-height: 20px;
    text-decoration-line: underline;
    cursor: pointer;
  }
  .check-radio{
    padding: 24px 0;
  }
`

const LPInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  gap: 12px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  .skip-btn{
    background: #E2FFFA;
    border-radius: 8px;
    color: #48E8CC;
    border: none;
    padding: 6px 8px;
  }
  .skip-btn-disable{
    background: #D4D9F0;
    border-radius: 8px;
    color: white;
    border: none;
    padding: 6px 8px;
  }
  .link-btn{
    background: #F0E2FE;
    border-radius: 8px;
    border: none;
    padding: 6px 8px;
  }
`
const RedeemStep: React.FC = () => {
  const {checkLpLoading, agreeWarning, setAgreeWarning, currentRedeemStep, setCurrentRedeemStep, setDisableRedeem} = useRedeemStore()
  const [skipWarning, setSkipWarning] = useState(false)
  const { isEligibilityUser, hasLpStatus, allLpBalance, ordiMaxWBTC, currentWBTCBalance, currentWBTCOrdiBalance, currentWBTCPcxBalance, currentWBTCSatsBalance } = useCheckEligibility()
  const [checkAgreeSkipWarning, setCheckAgreeSkipWarning] = useState(false)
  const { disableRedeem, setShowRedeemSwap } = useRedeemStore()

  const LPInfo: React.ReactNode = <div className="flex flex-col">
    <Text maxWidth={'365px'} flexWrap={'wrap'} fontSize={14} color={'#988EB7'} lineHeight={'17px'}>
      We have recognized your Liquidity Provider (LP) Token on BSwap. You are entitled to withdraw {allLpBalance} WBTC from BSwap. The gas fee required is 0.00001080 BTC per transaction. You have the choice to skip, indicating your willingness to relinquish a portion of your redemption rights.
    </Text>
    <LPInfoWrapper>
      <button className={currentRedeemStep === 3 ? 'skip-btn-disable' : 'skip-btn'} disabled={currentRedeemStep === 3} onClick={()=>setSkipWarning(true)}>{currentRedeemStep === 3 ? 'Skipped' : 'Skip'}</button>
      <button className="link-btn"><Link style={{color: '#6D4EC9'}} to={'/pools/myPosition'}>Withdraw LP Token</Link></button>
    </LPInfoWrapper>
  </div>

  const lpStatusMap = {
    noLP: <Text fontSize={14} color={'#988EB7'} lineHeight={'17px'}>You don't have any LP Token in BSwap</Text>,
    hasLP: LPInfo
  }

  const redeemEligibility = {
    checking: <Text fontSize={14} color={'#988EB7'} lineHeight={'17px'}>checking...</Text>,
    eligibility: <></>,
    // unable: <Text fontSize={14} color={'#FF4E82'} lineHeight={'17px'}>You can't redeem BTC</Text>,
    claimed: <Text fontSize={14} color={'#FF4E82'} lineHeight={'17px'}>You have redeemed BTC</Text>
  }

  const stepItems: StepItem[] = [
    {
      key: '1',
      title: isEligibilityUser === 'unable' ? <Text fontSize={16} color={'#FF4E82'} lineHeight={'20px'}>You can't redeem BTC</Text> : `You can redeem ${ordiMaxWBTC} BTC`,
      description: redeemEligibility[isEligibilityUser],
    },
    {
      key: '2',
      title: `Detect your LP Token`,
      description: currentRedeemStep >= 1 ? lpStatusMap[hasLpStatus] : <></>,
    },
    { key: '3', title: 'You can acess the Redemption Page by clicking the button below ' }
  ]

  return (
    <><WhiteCard style={{ maxWidth: '424px', marginBottom: '24px', borderRadius: '6px' }}>
      <Modal isOpen={skipWarning} onDismiss={()=>{
        setSkipWarning(false)
        // setAgreeWarning(false)
      }} maxHeight={90} minHeight={50}>
        <WhiteCard style={{ maxWidth: '424px' }}>
          <RedeemWrapper>
            <IconWarning style={{ width: '88px' }} />
            <Text fontSize={18} fontWeight={500} lineHeight={'21px'} color={'#3B199F'} padding={'20px 0 16px 0'}>
              Redeem Disclaimer
            </Text>
            <div className={'notice-step'}>
              <Text fontSize={16} lineHeight={'20px'} color={'#988EB7'}>
                1.You have only one opportunity for redemption.
              </Text>
              <Text fontSize={16} lineHeight={'20px'} color={'#988EB7'}>
                2.Do not transfer WBTC/LP Token to any other address,the snapshot has already been taken.
              </Text>
              <Text fontSize={16} lineHeight={'20px'} color={'#988EB7'}>
                3.The maximum redemption amount for each address isfixed based on the snapshot.
              </Text>
              <Text fontSize={16} lineHeight={'20px'} color={'#988EB7'}>
                4.Snapshot date and information are <span onClick={()=>window.open('https://docs.google.com/spreadsheets/d/10S6C4EIoG5IEFZEh_I-fGwRVL-oRXIT_TTMAYpJT7lA/edit?usp=sharing')} className="github-link">here</span>
              </Text>
            </div>
            <div className="check-radio">
              <Radio  checked={checkAgreeSkipWarning} onClick={()=>{
                setCheckAgreeSkipWarning(!checkAgreeSkipWarning)
              }}><Text fontSize={16} lineHeight={'20px'} color={'#988EB7'}>
                Select to confirm agreement to the disclaimer.
              </Text></Radio>
            </div>
            <ButtonPrimary disabled={!checkAgreeSkipWarning} onClick={()=>{
              setSkipWarning(false)
              setCurrentRedeemStep(3)
              setDisableRedeem(false)
            }
            }>I Get it</ButtonPrimary>
          </RedeemWrapper>
        </WhiteCard>
      </Modal>
      <RedeemSteps direction="vertical" current={currentRedeemStep} onChange={setCurrentRedeemStep} stepItems={stepItems}  />
    </WhiteCard><ButtonPrimary disabled={disableRedeem} onClick={()=>{setShowRedeemSwap(true)}}>Go to Redeem</ButtonPrimary></>
  )
}

export default RedeemStep
