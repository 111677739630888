import { CurrencyAmount, JSBI, Token, Trade } from 'so-swap-sdk'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReactGA from 'react-ga'
import { Text } from 'rebass'
import { ButtonConfirmed, ButtonError, ButtonLight, ButtonPrimary } from '../../components/Button'
import { AutoRow, RowBetween } from '../../components/Row'
import BetterTradeLink from '../../components/swap/BetterTradeLink'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from '../../components/swap/styleds'
import TokenWarningModal from '../../components/TokenWarningModal'
import ProgressSteps from '../../components/ProgressSteps'

import { BETTER_TRADE_LINK_THRESHOLD } from '../../constants'
import { getTradeVersion, isTradeBetter } from '../../data/V1'
import { useAllTokens, useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useToggledVersion, { Version } from '../../hooks/useToggledVersion'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState
} from '../../state/swap/hooks'
import { useExpertModeManager, useUserDeadline, useUserSlippageTolerance } from '../../state/user/hooks'
import { LinkStyledButton, PaddingWrapper, TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import Loader from '../../components/Loader'
import PanelCard from '../../components/PanelCard'
import PanelContainer from '../../components/PanelContainer'
import switchIconNormal from '../../assets/Swap/icon_switch.svg'
import switchIconHover from '../../assets/Swap/icon_trande_exchange.svg'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import AddressInputPanel from '../../components/AddressInputPanel'
import { ArrowDown } from 'react-feather'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import SwapTxDetails from './SwapDetails'
import { switchToSherpax } from './changeNetWorkToSherpax'
import { ChainId } from 'so-swap-sdk'
import { switchToNetwork } from '../../utils/switchToNetwork'
import { SupportedChainId } from '../../constants/chains'
import AppBody from '../AppBody'
import { CardHeader } from '../Pool/styleds'
import Settings from '../../components/Settings'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { RemoveNoticeCard } from '../../components/Card/RemoveNoticeCard'
import { RedeemCard } from '../../components/Card/RedeemCard';

export default function Swap(): React.ReactElement {
  const loadedUrlParams = useDefaultsFromURLSearch()

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId)
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  const { account, chainId, library, error } = useWeb3React()

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [deadline] = useUserDeadline()
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    v1Trade,
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError
  } = useDerivedSwapInfo()
  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)
  const [isSherpaxChain, setisSherpaxChain] = useState(true)
  useEffect(() => {
    setisSherpaxChain(chainId === ChainId.CHAINX)
  }, [chainId])

  const toggledVersion = useToggledVersion()
  const trade = showWrap
    ? undefined
    : {
        [Version.v1]: v1Trade,
        [Version.v2]: v2Trade
      }[toggledVersion]

  const betterTradeLinkVersion: Version | undefined =
    toggledVersion === Version.v2 && isTradeBetter(v2Trade, v1Trade, BETTER_TRADE_LINK_THRESHOLD)
      ? Version.v1
      : toggledVersion === Version.v1 && isTradeBetter(v1Trade, v2Trade)
      ? Version.v2
      : undefined

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
      }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )
  const noRoute = !route

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    deadline,
    recipient
  )

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  const handleSwap = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
      return
    }
    if (!swapCallback) {
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then(hash => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })

        ReactGA.event({
          category: 'Swap',
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === account
              ? 'Swap w/o Send + recipient'
              : 'Swap w/ Send',
          label: [
            trade?.inputAmount?.currency?.symbol,
            trade?.outputAmount?.currency?.symbol,
            getTradeVersion(trade)
          ].join('/')
        })
      })
      .catch(error => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined
        })
      })
  }, [tradeToConfirm, account, priceImpactWithoutFee, recipient, recipientAddress, showConfirm, swapCallback, trade])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    inputCurrency => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(outputCurrency => onCurrencySelection(Field.OUTPUT, outputCurrency), [
    onCurrencySelection
  ])

  const top = (
    <PanelCard
      label={independentField === Field.OUTPUT && !showWrap && trade ? 'From (estimated)' : 'From'}
      value={formattedAmounts[Field.INPUT]}
      showMaxButton={currencies[Field.INPUT] ? true : false && !atMaxAmountInput}
      currency={currencies[Field.INPUT]}
      onUserInput={handleTypeInput}
      onMax={handleMaxInput}
      onCurrencySelect={handleInputSelect}
      otherCurrency={currencies[Field.OUTPUT]}
      id="swap-currency-input"
    />
  )

  const bottom = (
    <PanelCard
      value={formattedAmounts[Field.OUTPUT]}
      label={independentField === Field.INPUT && !showWrap && trade ? 'To (estimated)' : 'To'}
      showMaxButton={currencies[Field.OUTPUT] ? true : false}
      onMax={handleMaxInput}
      onUserInput={handleTypeOutput}
      currency={currencies[Field.OUTPUT]}
      onCurrencySelect={handleOutputSelect}
      otherCurrency={currencies[Field.INPUT]}
      id="swap-currency-output"
    />
  )

  const [showRoute, setShowRoute] = useState(false)

  useEffect(() => {
    setShowRoute(Boolean(trade && trade.route.path.length > 2))
  }, [trade])

  const ExpectModalOption = (
    <>
      {recipient === null && !showWrap && isExpertMode ? (
        <LinkStyledButton id="add-recipient-button" onClick={() => onChangeRecipient('')}>
          + Add a send (optional)
        </LinkStyledButton>
      ) : null}
    </>
  )

  const allTokens = useAllTokens()
  const { search } = useLocation()
  const params = queryString.parse(search)

  useEffect(() => {
    if (params.from) {
      onCurrencySelection(Field.INPUT, allTokens[params.from as string])
    }
    if (params.to) {
      onCurrencySelection(Field.OUTPUT, allTokens[params.to as string])
    }
  }, [allTokens, onCurrencySelection, params.from, params.to])

  const [showDetails, setShowDetails] = useState(false)

  const [switchIcon, setSwitchIcon] = useState(switchIconNormal)
  const myOverFunction = () => {
    setSwitchIcon(switchIconHover)
  }

  const myLeaveFunction = () => {
    setSwitchIcon(switchIconNormal)
  }

  return <RedeemCard />

  return (
    <AppBody>
      <CardHeader>
        <div>Swap</div>
        <Settings />
      </CardHeader>

      <TokenWarningModal
        isOpen={urlLoadedTokens.length > 0 && !dismissTokenWarning}
        tokens={urlLoadedTokens}
        onConfirm={handleConfirmTokenWarning}
      />
      <PaddingWrapper showTopBorder={true}>
        <Wrapper id="swap-page">
          <ConfirmSwapModal
            isOpen={showConfirm}
            trade={trade}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            recipient={recipient}
            allowedSlippage={allowedSlippage}
            onConfirm={handleSwap}
            swapErrorMessage={swapErrorMessage}
            onDismiss={handleConfirmDismiss}
          />
          <PanelContainer
            topBox={top}
            bottomBox={bottom}
            canChangeSwitchIcon={true}
            onMouseOver={() => myOverFunction()}
            onMouseLeave={() => myLeaveFunction()}
            onClick={() => {
              setApprovalSubmitted(false) // reset 2 step UI for approvals
              onSwitchTokens()
            }}
            switchIcon={<img src={switchIcon} alt="" />}
            switchIconPosition={isExpertMode && 'justify-content:space-between'}
            switchContent={isExpertMode && ExpectModalOption}
          />
          {recipient !== null && !showWrap ? (
            <>
              <AutoRow justify="space-between" style={{ padding: '12px 16px' }}>
                <ArrowWrapper clickable={false} style={{ display: 'flex', alignItems: 'center' }}>
                  <ArrowDown size="16" color={'#3B199F'} />
                </ArrowWrapper>
                <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                  - Remove send
                </LinkStyledButton>
              </AutoRow>
              <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
            </>
          ) : null}
          {/*{!trade && !currencies[Field.INPUT] && <span>111</span>}*/}
          {trade && (
            <SwapTxDetails
              trade={trade}
              allowedSlippage={allowedSlippage}
              showInverted={showInverted}
              showDetails={showDetails}
              setShowDetails={setShowDetails}
              showRoute={showRoute}
            />
          )}
          {!account ? (
            <BottomGrouping>
              {error instanceof UnsupportedChainIdError ? (
                <ButtonLight
                  onClick={() => switchToNetwork({ library, chainId: (ChainId.CHAINX as unknown) as SupportedChainId })}
                >
                  Switch Network
                </ButtonLight>
              ) : (
                <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
              )}
            </BottomGrouping>
          ) : chainId === ChainId.CHAINX ? (
            <BottomGrouping>
              {showWrap ? (
                <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
                  {wrapInputError ??
                    (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
                </ButtonPrimary>
              ) : noRoute && userHasSpecifiedInputOutput ? (
                <ButtonError disabled={true} style={{ textAlign: 'center', borderRadius: '20px' }}>
                  <TYPE.main fontSize={'20px'} lineHeight={'24px'} color={'white'}>
                    Insufficient liquidity for this trade.
                  </TYPE.main>
                </ButtonError>
              ) : showApproveFlow ? (
                <RowBetween>
                  <ButtonConfirmed
                    onClick={approveCallback}
                    disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                    width="48%"
                    altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                    confirmed={approval === ApprovalState.APPROVED}
                  >
                    {approval === ApprovalState.PENDING ? (
                      <AutoRow gap="6px" justify="center">
                        <TYPE.main fontSize={'20px'} lineHeight={'24px'} color={'white'}>
                          Approving
                        </TYPE.main>
                        <Loader stroke="white" />
                      </AutoRow>
                    ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                      <TYPE.main fontSize={'20px'} lineHeight={'24px'} color={'white'}>
                        Approved
                      </TYPE.main>
                    ) : (
                      <TYPE.main fontSize={'20px'} lineHeight={'24px'} color={'white'}>
                        {'Approve ' + currencies[Field.INPUT]?.symbol}
                      </TYPE.main>
                    )}
                  </ButtonConfirmed>
                  <ButtonError
                    onClick={() => {
                      if (isExpertMode) {
                        handleSwap()
                      } else {
                        setSwapState({
                          tradeToConfirm: trade,
                          attemptingTxn: false,
                          swapErrorMessage: undefined,
                          showConfirm: true,
                          txHash: undefined
                        })
                      }
                    }}
                    width="48%"
                    fontSize={20}
                    id="swap-button"
                    disabled={
                      !isValid || approval !== ApprovalState.APPROVED || (priceImpactSeverity > 3 && !isExpertMode)
                    }
                    error={isValid && priceImpactSeverity > 2}
                  >
                    <Text fontSize={20} fontWeight={500}>
                      {priceImpactSeverity > 3 && !isExpertMode
                        ? `Price Impact High`
                        : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
                    </Text>
                  </ButtonError>
                </RowBetween>
              ) : (
                <ButtonError
                  onClick={() => {
                    if (isExpertMode) {
                      handleSwap()
                    } else {
                      setSwapState({
                        tradeToConfirm: trade,
                        attemptingTxn: false,
                        swapErrorMessage: undefined,
                        showConfirm: true,
                        txHash: undefined
                      })
                    }
                  }}
                  fontSize={20}
                  id="swap-button"
                  disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError}
                  error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
                >
                  <Text fontSize={20} fontWeight={500}>
                    {swapInputError
                      ? swapInputError
                      : priceImpactSeverity > 3 && !isExpertMode
                      ? `Price Impact Too High`
                      : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
                  </Text>
                </ButtonError>
              )}
              {showApproveFlow && <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />}
              {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
              {betterTradeLinkVersion && <BetterTradeLink version={betterTradeLinkVersion} />}
            </BottomGrouping>
          ) : (
            <BottomGrouping>
              <ButtonLight
                onClick={() => switchToNetwork({ library, chainId: (ChainId.CHAINX as unknown) as SupportedChainId })}
              >
                Switch Network
              </ButtonLight>
            </BottomGrouping>
          )}
        </Wrapper>
      </PaddingWrapper>
    </AppBody>
  )
}
