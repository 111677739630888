import React from 'react'
import styled from 'styled-components'
import { CardProps, Text } from 'rebass'
import { Box } from 'rebass/styled-components'

const Card = styled(Box)<{ padding?: string; border?: string; borderRadius?: string }>`
  width: 100%;
  border-radius: 16px;
  padding: 1rem;
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
`
export default Card

export const LightCard = styled(Card)`
  border: 2px solid ${({ theme }) => theme.bg2};
  background-color: transparent;
`

export const GreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.bg3};
  &.white {
    background-color: rgba(63, 12, 192, 0.1);
  }
`

export const OutlineCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.bg3};
`

export const YellowCard = styled(Card)`
  background-color: rgba(242, 132, 30, 0.15);
  color: ${({ theme }) => theme.yellow2};
  font-weight: 500;
`

export const PinkCard = styled(Card)`
  background-color: rgba(255, 0, 122, 0.03);
  color: ${({ theme }) => theme.primary1};
  font-weight: 500;
`

export const PricePoolCard = styled(Card)`
  background: #c9c8e7;
  border-radius: 24px;
  border: 1px solid #ffffff;
`

export const WhiteCard = styled(Card)`
  background: white;
  box-shadow: 0 2px 4px 0 rgba(109, 78, 201, 0.06);
  border-radius: 24px;
  //border: 1px solid #c9c8e7;
`

export const PercentAmountCard = styled(Card)`
  background: #dcdcf2;
  border-radius: 24px;
  border: 1px solid #ffffff;
  padding: 0;
`

export const MyStakedCard = styled(Card)<{ backgroundColor?: string; isMobile?: boolean }>`
  border-radius: 16px;
  background: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '#d6d5f4')};
  border: ${({ isMobile }) => (isMobile ? 'none' : '1px solid rgba(153, 143, 184, 0.18)')};
  padding: 0;
  white-space: nowrap;
  width: 100%;
`

export const PoolsCard = styled.div`
  min-width: 1190px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 24px;
  border: 1px solid #ffffff;
  min-height: 500px;
  .card-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    .btn-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      a {
        margin-right: 12px;
      }
    }
  }
`

const BlueCardStyled = styled(Card)`
  width: 100%;
  background: #b5adff;
  box-shadow: 0 2px 4px 0 rgba(109, 78, 201, 0.06);
  border-radius: 24px;
  border: 2px solid #ffffff;
  padding: 16px 24px;
`

export const BlueCard = ({ children, ...rest }: CardProps) => {
  return (
    <BlueCardStyled {...rest}>
      <Text fontWeight={500} color="#2172E5">
        {children}
      </Text>
    </BlueCardStyled>
  )
}
