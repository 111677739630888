import { BigNumber } from '@ethersproject/bignumber'
import { hexStripZeros } from '@ethersproject/bytes'
import { Web3Provider } from '@ethersproject/providers'
import { CHAIN_INFO, SupportedChainId } from '../constants/chains'

interface SwitchNetworkArguments {
  library: Web3Provider
  chainId: SupportedChainId
}

// provider.info returns Promise<any>, but wallet_switchEthereumChain must return null or throw
// see https://github.com/rekmarks/EIPs/blob/3326-create/EIPS/eip-3326.md for more info on wallet_switchEthereumChain
export async function switchToNetwork({ library, chainId }: SwitchNetworkArguments): Promise<null | void> {
  // if (!library?.provider?.request) {
  //   return
  // }
  const formattedChainId = hexStripZeros(BigNumber.from(chainId).toHexString())
  const info = CHAIN_INFO[chainId]

  try {
    await (window?.ethereum as any).request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: formattedChainId,
          chainName: info.label,
          rpcUrls: [info.addNetworkInfo.rpcUrl],
          nativeCurrency: info.addNetworkInfo.nativeCurrency,
          blockExplorerUrls: [info.explorer]
        }
      ]
    })
  } catch (error) {
    // 4902 is the error code for attempting to switch to an unrecognized chainId
    throw error
  }
}
