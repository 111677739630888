import React, { useCallback, useEffect } from 'react';
import { Text } from 'rebass'
import { WhiteCard } from './index'
import { useWalletModalToggle } from '../../state/application/hooks'
import { ButtonPrimary } from '../Button';
import { useWeb3React } from '@web3-react/core';
import RedeemStep from '../../pages/Swap/RedeemStep';
import AppBody from '../../pages/AppBody';
import { CardHeader } from '../../pages/Pool/styleds';
import { PaddingWrapper } from '../../theme';
import useRedeemStore from '../../store/redeem';
import { RedeemWithdraw } from './RedeemWithdraw';
import { Link } from 'react-router-dom';

export const RedeemCard: React.FC = () => {
  const { account, connector, error, chainId } = useWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const { showRedeemSwap, setShowRedeemSwap } = useRedeemStore()

  const connectWallet = ():React.ReactNode => {
    return <><WhiteCard style={{ maxWidth: '424px', marginBottom: '24px', borderRadius: '6px', padding: '24px' }}>
      <Text maxWidth={'382px'} flexWrap={'wrap'} fontSize={16} fontWeight={500} color={'#988EB7'} lineHeight={'17px'}>Please connect wallet to enter the redemption page.</Text>
    </WhiteCard><ButtonPrimary onClick={toggleWalletModal}>Connect wallet</ButtonPrimary></>
  }

  useEffect(() => {
    account && setShowRedeemSwap(false)
  }, [account]);

  const checkUserLp = useCallback((address: string): React.ReactNode => {
    if(!address) return connectWallet()
    try {
      return showRedeemSwap ? <RedeemWithdraw /> : <RedeemStep />
    } catch (e) {
      console.error(e)
    } finally {
    }
    return <></>
  },[showRedeemSwap])

  return (
    <AppBody>
      <CardHeader>
        <div>Redeem</div>
        {showRedeemSwap && <div style={{display:'flex', flexDirection:'row', gap:'4px', cursor:'pointer'}} onClick={()=>window.open('https://docs.google.com/spreadsheets/d/10S6C4EIoG5IEFZEh_I-fGwRVL-oRXIT_TTMAYpJT7lA/edit?usp=sharing')}>
          <Text style={{textDecoration : 'underline'}} fontSize={14} fontWeight={500} color={'#3B199F'} lineHeight={'17px'}>WBTC Snapshot</Text>
          <Text style={{textDecoration : 'underline'}} fontSize={14} fontWeight={500} color={'#21D2B3'} lineHeight={'17px'}>↗</Text>
        </div>}
      </CardHeader>
      <PaddingWrapper showTopBorder={true}>
        {checkUserLp(account)}
      </PaddingWrapper>
    </AppBody>

  )
}
