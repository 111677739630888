import React, { useEffect, Dispatch, SetStateAction } from 'react'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { formatAmount } from '../../../helper/formatInfoNumbers'
import dayjs from 'dayjs'

export type LineChartProps = {
  data: any[]
  height?: string
  chartHeight?: string
  setHoverValue: Dispatch<SetStateAction<number | undefined>> // used for value on hover
  setHoverDate: Dispatch<SetStateAction<string | undefined>> // used for label of value
} & React.HTMLAttributes<HTMLDivElement>

const CustomBar = ({
  x,
  y,
  width,
  height,
  fill
}: {
  x: number
  y: number
  width: number
  height: number
  fill: string
}) => {
  return (
    <g>
      <rect x={x} y={y} fill={fill} width={width} height={height} rx="2" />
    </g>
  )
}

// Calls setHoverValue and setHoverDate when part of chart is hovered
// Note: this NEEDs to be wrapped inside component and useEffect, if you plug it as is it will create big render problems (try and see console)
const HoverUpdater = ({ locale, payload, setHoverValue, setHoverDate }) => {
  useEffect(() => {
    setHoverValue(payload.value)
    setHoverDate(payload.time)
  }, [locale, payload.value, payload.time, setHoverValue, setHoverDate])

  return null
}

const Chart = ({ data, setHoverValue, setHoverDate }: LineChartProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 15,
          left: 0,
          bottom: 5
        }}
        onMouseLeave={() => {
          setHoverDate(undefined)
          setHoverValue(undefined)
        }}
      >
        <XAxis
          dataKey="time"
          axisLine={false}
          tickLine={false}
          tickFormatter={time => dayjs(time,{utc:true}).format('MM/DD')}
          minTickGap={10}
        />
        <YAxis
          dataKey="value"
          tickCount={6}
          scale="linear"
          axisLine={false}
          tickLine={false}
          color={'#7A6EAA'}
          fontSize="12px"
          tickFormatter={val => `$${formatAmount(val)}`}
          orientation="left"
          tick={{ fill: '#7A6EAA' }}
        />
        <Tooltip
          cursor={{ fill: '#E9EAEB' }}
          contentStyle={{ display: 'none' }}
          formatter={(tooltipValue, name, props) => (
            <HoverUpdater
              locale={''}
              payload={props.payload}
              setHoverValue={setHoverValue}
              setHoverDate={setHoverDate}
            />
          )}
        />
        <Bar
          dataKey="value"
          fill={'#1FC7D4'}
          shape={props => (
            <CustomBar height={props.height} width={props.width} x={props.x} y={props.y} fill={'#1FC7D4'} />
          )}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default Chart
