import { ChainId } from 'so-swap-sdk'
import MULTICALL_ABI from './abi.json'

//多链，链合约地址
const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  [ChainId.KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.MATIC]: '0x4408f4b8d6154065175Ee67Df9de70cAccc17c0C',
  // [ChainId.SHERPAX]: '0xb2d6370F2a500dF8E694c7FCA32E69f71197F1c9',
  // [ChainId.SHERPAXTEST]: '0x7b21801C4B7219BDeb3494AC98e948abBD25b2e9',
  [ChainId.BSC]: '0x41263cba59eb80dc200f3e2544eda4ed6a90e76c',
  [ChainId.BSCTEST]: '0xae11C5B5f29A6a25e955F0CB8ddCc416f522AF5C',
  [ChainId.CHAINX]: '0x79eA96a9857b3fcf7B037E5EE98D4da63Ff1934A',
  [ChainId.BEVMTEST]: '0x79eA96a9857b3fcf7B037E5EE98D4da63Ff1934A'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
