import React, { useEffect, useMemo, useState } from 'react'
import { formatAmount } from '../../helper/formatInfoNumbers'
import LineChart from './LineChart/index'
import { CardWrapper, InfoTitle, InfoWrapper, Text } from './style'
import BarChart from './BarChart'
import dayjs from 'dayjs'
import TopTokensTable from '../../components/InfoTable/TopTokensTable'
import TopPoolsTable from '../../components/InfoTable/TopPoolsTable'
import TransactionsTable from '../../components/InfoTable/TransactionsTable'
import { useLiquidityListData } from '../../state/info/hook'
import { Skeleton } from 'antd'

const Info: React.FC = () => {
  const [liquidityHover, setLiquidityHover] = useState<number | undefined>()
  const [liquidityDateHover, setLiquidityDateHover] = useState<string | undefined>()
  const currentDate = Number(new Date(new Date().setHours(0, 0, 0, 0)))
  const [volumeHover, setVolumeHover] = useState<number>(-1)
  const [volumeDateHover, setVolumeDateHover] = useState<string | undefined>()

  const liquidityList = useLiquidityListData('liquidity', 1645977600, currentDate)
  const volumeList = useLiquidityListData('volume', 1645977600, currentDate)

  useEffect(() => {
    if (volumeHover == null && volumeList) {
      const latestVolume = volumeList?.data?.slice(-1)[0]
      setVolumeHover(latestVolume?.value)
    }
  }, [volumeList, volumeHover])

  useEffect(() => {
    if (liquidityHover == null && liquidityList) {
      const latestLiquidity = liquidityList?.data?.slice(-1)[0]
      setLiquidityHover(latestLiquidity?.value)
    }
  }, [liquidityHover, liquidityList])

  return (
    <InfoWrapper>
      <InfoTitle>
        <div className="title">BSwap Overview</div>
      </InfoTitle>
      <CardWrapper>
        <div className="card">
          <div className="title">TVL</div>
          {liquidityHover > 0 ? (
            <Text bold={600} fontSize={'24px'}>
              ${formatAmount(liquidityHover)}
            </Text>
          ) : (
            <Skeleton.Input active={true} size={'small'} />
          )}
          <Text>{dayjs(liquidityDateHover ? liquidityDateHover : currentDate, { utc: true }).format('MM/DD')}</Text>
          <div style={{ height: '280px' }}>
            <LineChart
              data={liquidityList?.data}
              setHoverValue={setLiquidityHover}
              setHoverDate={setLiquidityDateHover}
            />
          </div>
        </div>
        <div className="card">
          <div className="title">Volume 24H</div>
          {volumeHover >= 0 ? (
            <Text bold={600} fontSize={'24px'}>
              ${formatAmount(volumeHover)}
            </Text>
          ) : (
            <Skeleton.Input active={true} size={'small'} />
          )}
          <Text>{dayjs(volumeDateHover ?? currentDate).format('MM/DD')}</Text>
          <div style={{ height: '280px' }}>
            <BarChart data={volumeList?.data} setHoverValue={setVolumeHover} setHoverDate={setVolumeDateHover} />
          </div>
        </div>
      </CardWrapper>
      <TopTokensTable />
      <TopPoolsTable />
      <TransactionsTable />
    </InfoWrapper>
  )
}

export default Info
