import { AbstractConnector } from '@web3-react/abstract-connector'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import React, { useMemo } from 'react'
import { Activity } from 'react-feather'
import styled from 'styled-components'
import CoinbaseWalletIcon from '../../assets/images/coinbaseWalletIcon.svg'
import FortmaticIcon from '../../assets/images/fortmaticIcon.png'
import PortisIcon from '../../assets/images/portisIcon.png'
import WalletConnectIcon from '../../assets/images/walletConnectIcon.svg'
import { fortmatic, injected, portis, walletconnect, walletlink } from '../../connectors'
import { NetworkContextName } from '../../constants'
import useENSName from '../../hooks/useENSName'
import { useWalletModalToggle } from '../../state/application/hooks'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/reducer'
import { shortenAddress } from '../../utils'
import metaMaskIcon from '../../assets/Header/icon_MetaMask.svg'
// import mobileMetaMaskIcon from '../../assets/Header/icon_MobileMetaMask.svg'
import { ReactComponent as MetaMaskIcon } from '../../assets/Header/icon_MetaMask.svg'

import EvmAvatar from '../Avatar/EvmAvatar'
import Loader from '../Loader'

import WalletModal from '../WalletModal'
import useDeviceSize from '../../hooks/useDeviceSize'
import DoubleIcon from '../DoubleIcon'

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  & > * {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
`

const Web3StatusGeneric = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-radius: 16px;
  cursor: pointer;
  user-select: none;
  background-color: white;
`
const Web3StatusError = styled(Web3StatusGeneric)`
  border: 2px solid white;
  color: white;
  font-weight: 500;
  background-color: #e93e5e;
  :hover,
  :focus {
    background-color: #ff3541;
  }
`

const Web3StatusConnect = styled(Web3StatusGeneric)<{ faded?: boolean }>`
  background-color: #d7f0ea;
  color: #298a9a;
  border-radius: 16px;
  border: 2px solid white;
  padding: 8px 10px 8px 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  max-height: 48px;
  :active,
  :hover,
  :focus {
    background: #b5dcdc;
    color: #298a9a !important;
  }
  > div {
    display: flex;
    align-items: center;
    padding: 2px 0;
    > svg {
      width: 24px;
      margin-right: 6px;
    }
    > span {
      line-height: 16px;
    }
  }
`

const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean }>`
  color: #298a9a;
  font-weight: 500;
  background-color: white;
  padding: 2px;
  height: 48px;
  > img {
    margin: 6px;
  }
  .item {
    padding: 12px 12px;
    background-color: #d7f0ea;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 16px;
    line-height: 16px;
    & > div {
      height: 16px !important;
      display: flex;
      align-items: center;
    }
    & > span {
      line-height: 16px;
      padding-right: 6px;
    }
  }
`

const Text = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
  padding: 9px 10px 9px 0;
`

const NetworkIcon = styled(Activity)`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

const MobileWeb3StatusConnected = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #e4e8f2;
  color: #6d4ec9;
  border-radius: 16px;
  border: 2px solid white;
  padding: 8px 10px;
  min-width: 28px;
`

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

// eslint-disable-next-line react/prop-types
export function StatusIcon({ connector, size }: { connector: AbstractConnector; size?: number }) {
  if (connector === injected) {
    return <EvmAvatar size={size} />
  } else if (connector === walletconnect) {
    return (
      <IconWrapper size={16}>
        <img src={WalletConnectIcon} alt={''} />
      </IconWrapper>
    )
  } else if (connector === walletlink) {
    return (
      <IconWrapper size={16}>
        <img src={CoinbaseWalletIcon} alt={''} />
      </IconWrapper>
    )
  } else if (connector === fortmatic) {
    return (
      <IconWrapper size={16}>
        <img src={FortmaticIcon} alt={''} />
      </IconWrapper>
    )
  } else if (connector === portis) {
    return (
      <IconWrapper size={16}>
        <img src={PortisIcon} alt={''} />
      </IconWrapper>
    )
  }
  return null
}

function Web3StatusInner() {
  const { account, connector, error, chainId } = useWeb3React()
  const { isSmallScreen } = useDeviceSize()

  const { ENSName } = useENSName(account ?? undefined)

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash)

  const hasPendingTransactions = !!pending.length
  const toggleWalletModal = useWalletModalToggle()

  if (!isSmallScreen && account) {
    return (
      <Web3StatusConnected id="web3-status-connected" onClick={toggleWalletModal} pending={hasPendingTransactions}>
        <>
          <DoubleIcon
            leftIcon={null}
            // (chainId === 1506 && <img src={SHERPAX} alt="" width={28} />) ||
            // (chainId === 1501 && <img src={CHAINX} alt="" width={28} />)
            rightIcon={<MetaMaskIcon />}
          />
          {hasPendingTransactions ? (
            <div className="item" style={{ backgroundColor: '#E93E5E', color: 'white' }}>
              <Loader stroke="white" />
              <span style={{ marginLeft: '8px' }}>Pending</span>
            </div>
          ) : (
            <div className="item">
              <span>{shortenAddress(account)}</span>
              {!hasPendingTransactions && connector && <StatusIcon connector={connector} size={14} />}
            </div>
          )}
        </>
      </Web3StatusConnected>
    )
  } else if (isSmallScreen && account) {
    return (
      <MobileWeb3StatusConnected id="web3-status-connected" onClick={toggleWalletModal}>
        <img src={metaMaskIcon} alt="" width={28} />
      </MobileWeb3StatusConnected>
    )
  } else if (error) {
    return (
      <Web3StatusError onClick={toggleWalletModal}>
        <NetworkIcon />
        <Text>{error instanceof UnsupportedChainIdError ? 'Wrong Network' : 'Error'}</Text>
      </Web3StatusError>
    )
  } else {
    return (
      <Web3StatusConnect id="connect-wallet" onClick={toggleWalletModal} faded={!account}>
        <div>
          <MetaMaskIcon />
          <span>Connect wallet</span>
        </div>
      </Web3StatusConnect>
    )
  }
}

export default function Web3Status() {
  const { active, account } = useWeb3React()
  const contextNetwork = useWeb3React(NetworkContextName)

  const { ENSName } = useENSName(account ?? undefined)

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash)
  const confirmed = sortedRecentTransactions.filter(tx => tx.receipt).map(tx => tx.hash)

  if (!contextNetwork.active && !active) {
    return null
  }

  return (
    <>
      <Web3StatusInner />
      <WalletModal ENSName={ENSName ?? undefined} pendingTransactions={pending} confirmedTransactions={confirmed} />
    </>
  )
}
