import React from 'react'
import { PanenlContent, StyledBalanceMax } from '../PanelCard/panel'

interface Props {
  balanceValue: string
  balanceOnMax: () => void
  transferAll: boolean
}

const BalanceMax: React.FC<Props> = ({ balanceValue, balanceOnMax, transferAll }: Props) => {
  return (
    <div style={{ minHeight: '29px', display: 'flex', alignItems: 'center' }}>
      <PanenlContent>{`${balanceValue ? 'Balance: ' + balanceValue : ' '}`}</PanenlContent>
      {transferAll && <StyledBalanceMax onClick={balanceOnMax}>MAX</StyledBalanceMax>}
    </div>
  )
}

export default BalanceMax
