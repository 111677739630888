import { Modal, Drawer } from 'antd'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

export const AntdModalWrapper = styled(isMobile ? Drawer : Modal)<{
  minheight: string
  overflow?: string
}>`
  .ant-modal-content {
    border-radius: 24px;
    .ant-modal-header {
      padding: ${isMobile ? '6.4vw 5.3vw' : '24px'};
      border-radius: 24px 24px 0 0;
      .ant-modal-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .ant-modal-body {
      max-height: 80vh;
      overflow: ${({ overflow }) => (overflow ? overflow : 'hidden')};
      min-height: ${({ minheight }) => (minheight ? minheight : '0')};
      height: 100%;
      display: flex;
      background: #f2f6fa;
      border-radius: 0 0 24px 24px;
      padding: 16px 24px;
      font-size: 16px;
    }
  }
  .ant-drawer-content {
    border-radius: 6.4vw 6.4vw 0 0;
    .ant-drawer-title {
      display: flex;
      justify-content: space-between;
    }
    .ant-drawer-header {
      padding: 6.4vw 5.3vw;
    }
    .ant-drawer-body {
      max-height: 80vh;
      background: #f1f5fb;
    }
  }
`
