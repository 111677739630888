import React, { useState } from 'react'
import styled from 'styled-components'
import ImgBg from '../../assets/RedEnvelope/img_created_bg.png'
import { ReactComponent as IconSelected } from '../../assets/RedEnvelope/icon_selected.svg'
import { Text } from 'rebass'
import { ButtonLightGreen, ButtonNext } from '../../components/Button'
import useRedEnvelopeStore from '../../store/redEnvelope'
import QRCodeGenerator from '../../components/QRCodeGenerator'

const Wrapper = styled.div`
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    max-height: 200px;
  }
  .text {
    display: flex;
    align-items: center;
    padding-top: 30px;
    svg {
      width: 26px;
      height: 26px;
    }
  }
  .button-group {
    padding-top: 36px;
    display: flex;
    align-items: center;
    gap: 16px;
  }
`

interface Props {
  backToStart: () => void
}

export const Step3: React.FC<Props> = ({ backToStart }: Props) => {
  const { clearCreateParams, createParams } = useRedEnvelopeStore()
  const [qrcodeVisible, setQrcodeVisible] = useState(false)

  return (
    <Wrapper>
      <img src={ImgBg} alt="" />
      <div className={'text'}>
        <IconSelected />
        <Text fontSize={14} fontWeight={400} color={'#281071'} lineHeight={'17px'} marginLeft={'4px'}>
          {createParams.tokenInfo.symbol} Red Envelope Created
        </Text>
      </div>
      <div className="button-group">
        <ButtonLightGreen
          borderRadius={'20px'}
          padding={'12px 0'}
          width={'124px'}
          onClick={() => {
            backToStart()
            clearCreateParams()
          }}
        >
          Create Again
        </ButtonLightGreen>
        <ButtonNext onClick={() => setQrcodeVisible(true)}>Share</ButtonNext>
      </div>
      <QRCodeGenerator visible={qrcodeVisible} onClose={() => setQrcodeVisible(false)} />
    </Wrapper>
  )
}
