// the Uniswap Default token list lives here
export const DEFAULT_TOKEN_LIST_URL =
  'https://raw.githubusercontent.com/chainx-org/token-list/bswap/bevm.tokenlist.json'

// export const DEFAULT_TOKEN_LIST_URL =
//   'https://raw.githubusercontent.com/chainx-org/token-list/main/bevm-test.tokenlist.json'
// export const DEFAULT_TOKEN_LIST_URL = testBTC
export const DEFAULT_TOKEN_TEST_LIST_URL =
  'https://raw.githubusercontent.com/chainx-org/token-list/bswap/bevm.tokenlist.json'
// export const CHAINX_TOKEN_LIST_URL =
//   'https://raw.githubusercontent.com/chainx-org/token-list/main/chainxEvm.tokenlist.json'

export const DEFAULT_LIST_OF_LISTS: string[] = [DEFAULT_TOKEN_LIST_URL]
