import { useLayoutEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

function useRoute<T>(rootRouting: string, routeOption: T[], defaultRoute = routeOption[0]): { route: T } {
  // @ts-ignore
  const [route, setRoute] = useState<T>('')
  const { pathname } = useLocation()

  useLayoutEffect(() => {
    const index = pathname.indexOf(rootRouting)

    setRoute((pathname.slice(index + rootRouting.length + 1) as unknown) as T)
  }, [rootRouting, pathname])

  return { route }
}

export default useRoute
