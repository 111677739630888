import BigNumber from 'bignumber.js'
import chainxBridgeAbi from './abis/chainxBridgeAbi.json'
import { BridgeChain, BridgeTokenType } from './bridge'

// const BITCOIN_API = 'https://bitcoin.coming.chat/ord_testnet' //测试网
const BITCOIN_API = 'https://bitcoin.coming.chat/ord' //主网

// const UNISAT_WALLET_API = 'https://unisat.io/testnet/wallet-api-v4' //测试网
const UNISAT_WALLET_API = 'https://unisat.io/wallet-api-v4' //主网

const BTC_DESTINATION_ADDRESS = 'bc1p43kqxnf7yxcz5gacmqu98cr2r5gndtauzrwpypdzmsgp7n3lssgs5wruvy'

const BRC20_DESTINATION_ADDRESS = 'bc1pa9w29wrs9ec9z7nnkwguth6xj8tr9weezmg6pchq2exjw7wrw27srcadrw'

const CHAINX_BRIDGE_ADDRESS = '0xd1543A52d770D32854fc5f5F076a74033D49D41f' //主网
// const CHAINX_BRIDGE_ADDRESS = '0x8464B9a1ea020C2CBa1e14B62F8AE354A852174A' //测试网

const COMING_ZERO_API = 'https://coming-zero-wallet.coming.chat'

const TX_FEE_RATE_API = 'https://mempool.space/api/v1/fees/recommended'

const MEMPOOL_API = 'https://mempool.space/api'

const OKLINK_API = 'https://www.oklink.com/api'

const OKLINK_API_KEY = '9b42613e-d1b5-4611-9d87-3ded9cbe2b12'

const MIN_BTC_AMOUNT_IN_BITCOIN = new BigNumber(10000).dividedBy(Math.pow(10, 8)).toNumber()

const MIN_BTC_AMOUNT_IN_CHAINX = new BigNumber(450000).dividedBy(Math.pow(10, 8)).toNumber()

const BRIDGE_FEE = 30000

const MIN_BTC_AMOUNT = {
  [BridgeChain.bitcoin]: MIN_BTC_AMOUNT_IN_BITCOIN,
  [BridgeChain.chainx]: MIN_BTC_AMOUNT_IN_CHAINX
}

const DEFAULT_BTC = {
  type: BridgeTokenType.token,
  symbol: 'BTC',
  address: '0x0000000000000000000000000000000000000000',
  tick: '',
  decimal: 18
}

export {
  BITCOIN_API,
  BTC_DESTINATION_ADDRESS,
  BRC20_DESTINATION_ADDRESS,
  UNISAT_WALLET_API,
  CHAINX_BRIDGE_ADDRESS,
  chainxBridgeAbi,
  COMING_ZERO_API,
  TX_FEE_RATE_API,
  MIN_BTC_AMOUNT_IN_BITCOIN,
  MIN_BTC_AMOUNT_IN_CHAINX,
  MIN_BTC_AMOUNT,
  MEMPOOL_API,
  BRIDGE_FEE,
  DEFAULT_BTC,
  OKLINK_API,
  OKLINK_API_KEY
}
