import React, { useCallback, useMemo } from 'react'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { usePairs } from '../../data/Reserves'
import { Pair } from 'so-swap-sdk'
import PoolItem from './PoolItem'
import { PoolItem as PoolData } from '../../hooks/usePoolsData'
import { Spin } from 'antd'
import { unwrappedToken } from '../../utils/wrappedCurrency'

enum InfoItem {
  liquidity = 'liquidity',
  apr = 'apr'
}

interface Props {
  allPools: PoolData[]
}

const MyPosition = ({ allPools }: Props): React.ReactElement => {
  const { account } = useActiveWeb3React()
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map(tokens => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map(tpwlt => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )
  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))
  const isLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some(V2Pair => !V2Pair)

  const getPoolInfo = useCallback(
    (tokenASymbol: string, tokenBSymbol: string, infoType: InfoItem) => {
      const targetPool = (allPools || []).find(pool => {
        const poolName = pool.poolName.toLowerCase()
        return poolName.includes(tokenASymbol.toLowerCase()) && poolName.includes(tokenBSymbol.toLowerCase())
      })

      if (targetPool) {
        switch (infoType) {
          case 'liquidity':
            return targetPool.liquidityUsd
          case 'apr':
            return targetPool.aprDaily
          default:
            return ''
        }
      }

      return ''
    },
    [allPools]
  )

  return (
    <div style={{ padding: '0 24px 24px 24px' }}>
      <Spin style={{ padding: '24px' }} spinning={isLoading}>
        {allV2PairsWithLiquidity.map(pair => (
          <PoolItem
            key={pair.liquidityToken.address}
            pair={pair}
            liquidityValue={getPoolInfo(
              unwrappedToken(pair.token0).symbol,
              unwrappedToken(pair.token1).symbol,
              InfoItem.liquidity
            )}
            apr={getPoolInfo(unwrappedToken(pair.token0).symbol, unwrappedToken(pair.token1).symbol, InfoItem.apr)}
          />
        ))}
      </Spin>
    </div>
  )
}

export default MyPosition
