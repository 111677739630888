import React, { useEffect, useState } from 'react'
import { formatInfoTablePrice } from '../../helper/formatPrice'
import InfoTable, { DEFAULT_SORT_OPTIONS } from '../../components/InfoTable'
import TokenName from '../../components/InfoTable/TokenName'
import { ReactComponent as IconArrow } from '../../assets/Pools/icon_arrow_right.svg'
import styled from 'styled-components'
import { PoolItem } from '../../hooks/usePoolsData'
import { useHistory } from 'react-router-dom'

const Action = styled.div`
  background: #fbfbfd;
  padding: 10px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
`

const columns = [
  {
    title: 'Pools',
    dataIndex: 'pools',
    key: 'pools',
    align: 'left',
    width: 240
  },
  {
    title: 'Liquidity',
    dataIndex: 'liquidity',
    key: 'liquidity',
    align: 'center',
    render: text => `$ ${formatInfoTablePrice(text)}`
  },
  {
    title: 'VOLUME (24H)',
    dataIndex: 'volumeDaily',
    key: 'volumeDaily',
    align: 'left',
    sortDirections: DEFAULT_SORT_OPTIONS,
    showSorterTooltip: false,
    sorter: (item0, item1) => item0.volumeDaily - item1.volumeDaily,
    render: text => `$ ${formatInfoTablePrice(text)}`
  },
  {
    title: 'APR (24H)',
    dataIndex: 'aprDaily',
    key: 'aprDaily',
    align: 'left',
    sortDirections: DEFAULT_SORT_OPTIONS,
    showSorterTooltip: false,
    sorter: (item0, item1) => parseFloat(item0.aprDaily) - parseFloat(item1.aprDaily),
    render: text => `${text}%`
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    align: 'center'
  }
]

interface Props {
  data: PoolItem[]
}

const Pools = ({ data }: Props): React.ReactElement => {
  const [dataSource, setDataSource] = useState(null)
  const { push } = useHistory()

  useEffect(() => {
    data &&
      data.length > 0 &&
      setDataSource(
        data.map(i => ({
          key: `${i.poolName}-${i.id}`,
          pools: <TokenName name={i.poolName} subName={null} />,
          liquidity: i.liquidityUsd,
          volumeDaily: i.dailyVolumeUsd,
          aprDaily: i.aprDaily,
          action: (
            <Action onClick={() => push(`/add/${i.tokenA}/${i.tokenB}`)}>
              <IconArrow />
            </Action>
          ),
          tokenA: i.tokenA,
          tokenB: i.tokenB
        }))
      )
  }, [data])

  return (
    <div style={{ padding: '0 24px 24px 24px' }}>
      <InfoTable
        onRow={record => {
          return {
            onClick: () => push(`/add/${record.tokenA}/${record.tokenB}`)
          }
        }}
        columns={columns}
        transactionData={dataSource}
      />
    </div>
  )
}

export default React.memo(Pools)
