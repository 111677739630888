import React, { useMemo } from 'react'
import InfoTable, { DEFAULT_SORT_OPTIONS } from './index'
import PriceChanger from './PriceChanger'
import { useTokensListData } from '../../state/info/hook'
import { TableTitle } from './styles'
import TokenName from './TokenName'
import { isMobile } from 'react-device-detect'

const columns = [
  {
    title: '#',
    dataIndex: '#',
    key: '#',
    align: 'center',
    className: 'prefix-id',
    render: (text, record, index) => `${index + 1}`
  },
  {
    title: 'NAME',
    dataIndex: 'name',
    key: 'name',
    className: 'token-name'
  },
  {
    title: 'PRICE(sats)',
    dataIndex: 'priceSats',
    key: 'priceSats',
    align: 'left',
    sortDirections: DEFAULT_SORT_OPTIONS,
    showSorterTooltip: false,
    sorter: (item0, item1) => {
      return item0.priceSats - item1.priceSats
    }
  },
  {
    title: 'PRICE',
    dataIndex: 'price',
    key: 'price',
    align: 'left',
    sortDirections: DEFAULT_SORT_OPTIONS,
    showSorterTooltip: false,
    sorter: (item0, item1) => {
      return item0.priceNum - item1.priceNum
    }
  },
  {
    title: 'PRICE CHANGE',
    dataIndex: 'priceChange',
    key: 'priceChange',
    align: 'left',
    sortDirections: DEFAULT_SORT_OPTIONS,
    showSorterTooltip: false,
    sorter: (item0, item1) => {
      return item0.priceChangePercent - item1.priceChangePercent
    }
  },
  {
    title: 'VOLUME 24H',
    dataIndex: 'volumeDaily',
    key: 'volumeDaily',
    align: 'left',
    sortDirections: DEFAULT_SORT_OPTIONS,
    showSorterTooltip: false,
    sorter: (item0, item1) => {
      return item0.dailyVolumeUsdNum - item1.dailyVolumeUsdNum
    }
  },
  {
    title: 'TVL',
    dataIndex: 'tvl',
    key: 'tvl',
    align: 'left',
    sortDirections: DEFAULT_SORT_OPTIONS,
    defaultSortOrder: 'descend',
    showSorterTooltip: false,
    sorter: (item0, item1) => {
      return item0.tvlNum - item1.tvlNum
    }
  }
]

const mobileColumns = [
  {
    title: 'NAME',
    dataIndex: 'name',
    key: 'name',
    className: 'token-name'
  },
  {
    title: 'VOLUME 24H',
    dataIndex: 'volumeDaily',
    key: 'volumeDaily',
    align: 'left',
    sortDirections: DEFAULT_SORT_OPTIONS,
    showSorterTooltip: false,
    sorter: (item0, item1) => {
      return item0.dailyVolumeUsdNum - item1.dailyVolumeUsdNum
    }
  }
]

const TopTokensTable: React.FC = () => {
  const { data } = useTokensListData()

  const dataSource = useMemo(() => {
    return data?.map(token => {
      return {
        key: Math.random(),
        name: <TokenName name={token.symbol} subName={token.name ? `(${token.name})` : ''} />,
        price: token.price,
        priceChange: <PriceChanger priceChangePercent={token.priceChangePercent} />,
        volumeDaily: token.dailyVolumeUsd,
        tvl: token.liquidityUsd,
        tvlNum: token.tvlNum,
        priceNum: token.priceNum,
        dailyVolumeUsdNum: token.dailyVolumeUsdNum,
        priceChangePercent: token.priceChangePercent,
        priceSats: parseFloat(token.priceSats.toFixed(6))
      }
    })
  }, [data])

  return (
    <>
      <TableTitle>Top Tokens</TableTitle>
      <InfoTable
        columns={isMobile ? mobileColumns : columns}
        transactionData={dataSource}
        paginationClassName={'pagination-top-tokens'}
      />
    </>
  )
}

export default React.memo(TopTokensTable)
