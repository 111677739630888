import React from 'react'
import { Text } from 'rebass'
import { ChainId, Currency, currencyEquals, ETHER, Token } from 'so-swap-sdk'
import styled from 'styled-components'

import { SUGGESTED_BASES } from '../../constants'
import { AutoColumn } from '../Column'
import { AutoRow } from '../Row'
import CurrencyLogo from '../CurrencyLogo'

const BaseWrapper = styled.div<{ disable?: boolean }>`
  border: 1px solid ${({ disable }) => (disable ? '#E4E8F2' : '#e8eaf1')};

  border-radius: 16px;
  display: flex;
  padding: 12px 8px;

  align-items: center;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background-color: ${({ disable }) => !disable && 'white'};
  }

  background-color: ${({ disable }) => disable && '#E4E8F2'};
  // opacity: ${({ disable }) => disable && '0.4'};
`

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency
}: {
  chainId?: ChainId
  selectedCurrency?: Currency | null
  onSelect: (currency: Currency) => void
}) {
  return (
    <AutoColumn gap="md">
      <AutoRow gap="4px">
        <BaseWrapper
          onClick={() => {
            if (!selectedCurrency || !currencyEquals(selectedCurrency, ETHER)) {
              onSelect(ETHER)
            }
          }}
          disable={selectedCurrency === ETHER}
        >
          <CurrencyLogo currency={ETHER} style={{ marginRight: 8 }} />
          <Text fontWeight={500} fontSize={16}>
            BTC
          </Text>
        </BaseWrapper>
        {/*{(chainId ? SUGGESTED_BASES[chainId] : []).map((token: Token) => {*/}
        {/*  const selected = selectedCurrency instanceof Token && selectedCurrency.address === token.address*/}
        {/*  return (*/}
        {/*    <BaseWrapper onClick={() => !selected && onSelect(token)} disable={selected} key={token.address}>*/}
        {/*      <CurrencyLogo currency={token} style={{ marginRight: 8 }} />*/}
        {/*      <Text fontWeight={500} fontSize={16}>*/}
        {/*        {token.symbol}*/}
        {/*      </Text>*/}
        {/*    </BaseWrapper>*/}
        {/*  )*/}
        {/*})}*/}
      </AutoRow>
    </AutoColumn>
  )
}
