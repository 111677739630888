import { Pagination, Popover, Spin } from 'antd'
import React, { useMemo } from 'react'
import { Pair } from 'so-swap-sdk'
import { PoolDetailsCard } from '../../components/Card/PoolDetailsCard'
import Collapse from '../../components/Collapse'
import usePagination from '../../hooks/usePagination'
import { useBridgeIcon } from '../../hooks/useBridgeIcon'
import dayjs from 'dayjs'
import { Text } from 'rebass'
import { CollapseContentWrapper, LastAction } from './styles'
import { retainEffectiveDigits } from '../../utils/retainEffectiveDigits'
import { ReactComponent as IconChart } from '../../assets/Pools/icon_chart.svg'
import { ReactComponent as IconTip } from '../../assets/Pools/icon_tip.svg'

type PositionChangedDataItem = {
  timestamp: number
  token0Symbol: string
  token1Symbol: string
  token0PriceUsd: number
  token1PriceUsd: number
  token0Asset: number
  token1Asset: number
  token0Changed: number
  token1Changed: number
  totalValueUsd: number
}

interface Props {
  pair: Pair
  data: PositionChangedDataItem[]
  fetchPage: (page: number) => void
  currentPage: number
  total: number
  loading: boolean
}

const PositionChange: React.FC<Props> = ({ pair, data, fetchPage, total, currentPage, loading }: Props) => {
  const onPageChange = (page, pageSize) => {
    fetchPage(page - 1)
  }

  const paginationOptions = usePagination(total, 'position-change', onPageChange, 3)

  return (
    <PoolDetailsCard title={'Position Change'}>
      <Spin wrapperClassName={'spin-wrapper'} spinning={loading}>
        {(data || []).map((i, index) => (
          <Collapse
            key={i.timestamp}
            panelKey={i.timestamp}
            header={<CollapseHeader timestamp={i.timestamp} isLastAction={currentPage === 0 && index === 1} />}
            extra={`$ ${i.totalValueUsd.toFixed(2)}`}
            style={{ marginBottom: '12px' }}
            defaultOpen={i.timestamp === 0}
          >
            {currentPage === 0 && index === 0 ? (
              <FirstCollapseContent dataItem={i} />
            ) : (
              <CollapseContent dataItem={i} />
            )}
          </Collapse>
        ))}
      </Spin>
      {total > 0 && <Pagination {...paginationOptions} pageSize={3} />}
    </PoolDetailsCard>
  )
}

export default React.memo(PositionChange)

const CollapseHeader: React.FC<{ timestamp: number; isLastAction: boolean }> = ({
  timestamp,
  isLastAction
}: {
  timestamp: number
  isLastAction: boolean
}) => {
  return (
    <>
      {timestamp > 0 ? dayjs.unix(timestamp).format('YYYY/M/D HH:mm:ss') : 'Now'}
      {isLastAction && <LastAction>last action</LastAction>}
    </>
  )
}

interface CollapseContentProps {
  dataItem: PositionChangedDataItem
}

const FirstCollapseContent: React.FC<CollapseContentProps> = ({ dataItem }: CollapseContentProps) => {
  const getIcon = useBridgeIcon()

  const items = useMemo(() => {
    return [
      {
        icon: getIcon(dataItem.token0Symbol),
        symbol: dataItem.token0Symbol,
        valueUsd: retainEffectiveDigits(dataItem.token0PriceUsd),
        changed: retainEffectiveDigits(dataItem.token0Changed)
      },
      {
        icon: getIcon(dataItem.token1Symbol),
        symbol: dataItem.token1Symbol,
        valueUsd: retainEffectiveDigits(dataItem.token1PriceUsd),
        changed: retainEffectiveDigits(dataItem.token1Changed)
      }
    ]
  }, [
    dataItem.token0Changed,
    dataItem.token0Symbol,
    dataItem.token0PriceUsd,
    dataItem.token1Changed,
    dataItem.token1Symbol,
    dataItem.token1PriceUsd,
    getIcon
  ])

  return (
    <CollapseContentWrapper>
      <div className={'first-action'}>
        <IconChart />
        Comparison since last action
      </div>
      {items.map(i => (
        <div key={i.symbol} className={'content-item'}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {i.icon}
            <Text padding={'0 8px'} fontSize={14} fontWeight={500} lineHeight={'18px'} color={'#988EB7'}>
              {i.symbol}
            </Text>
            <Text fontSize={14} fontWeight={500} lineHeight={'18px'} color={'#281071'}>
              $ {parseFloat(i.valueUsd) === 0 ? '<0.00000001' : i.valueUsd}
            </Text>
          </div>
          <Text
            className={parseFloat(i.changed) >= 0 ? 'increase' : 'reduce'}
            fontSize={14}
            fontWeight={500}
            lineHeight={'18px'}
          >
            {parseFloat(i.changed) < 0 ? i.changed : `+${i.changed}`}
          </Text>
        </div>
      ))}
    </CollapseContentWrapper>
  )
}

const CollapseContent: React.FC<CollapseContentProps> = ({ dataItem }: CollapseContentProps) => {
  const getIcon = useBridgeIcon()

  const assetItems = useMemo(() => {
    return [
      {
        icon: getIcon(dataItem.token0Symbol),
        symbol: dataItem.token0Symbol,
        valueUsd: retainEffectiveDigits(dataItem.token0PriceUsd),
        amount: retainEffectiveDigits(dataItem.token0Asset)
      },
      {
        icon: getIcon(dataItem.token1Symbol),
        symbol: dataItem.token1Symbol,
        valueUsd: retainEffectiveDigits(dataItem.token1PriceUsd),
        amount: retainEffectiveDigits(dataItem.token1Asset)
      }
    ]
  }, [
    dataItem.token0Asset,
    dataItem.token0Symbol,
    dataItem.token0PriceUsd,
    dataItem.token1Asset,
    dataItem.token1Symbol,
    dataItem.token1PriceUsd,
    getIcon
  ])

  const changedItems = useMemo(() => {
    return [
      {
        icon: getIcon(dataItem.token0Symbol),
        symbol: dataItem.token0Symbol,
        amount: retainEffectiveDigits(dataItem.token0Changed)
      },
      {
        icon: getIcon(dataItem.token1Symbol),
        symbol: dataItem.token1Symbol,
        amount: retainEffectiveDigits(dataItem.token1Changed)
      }
    ]
  }, [dataItem.token0Changed, dataItem.token0Symbol, dataItem.token1Changed, dataItem.token1Symbol, getIcon])

  const contents = {
    'Position record': {
      items: assetItems,
      tip: 'The assets in position after the action.'
    },
    'Comparison with previous': {
      items: changedItems,
      tip:
        'Comparison of assets since previous action, including deposits, witharawals, earned fees and the lmpermanent Loss.'
    }
  }
  return (
    <CollapseContentWrapper>
      {Object.keys(contents).map(i => (
        <div key={i} className={'action-item'}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '16px' }}>
            <Text fontSize={14} fontWeight={500} color={'#988EB7'} lineHeight={'18px'}>
              {i}
            </Text>
            <Text
              style={{ display: 'flex', alignItems: 'center' }}
              fontSize={14}
              fontWeight={500}
              color={'#988EB7'}
              lineHeight={'18px'}
            >
              Assets
              <Popover trigger={'hover'} content={contents[i].tip}>
                <IconTip className={'asset-tip'} />
              </Popover>
            </Text>
          </div>
          {contents[i].items.map(i => (
            <div key={i.symbol} className={'content-item'}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {i.icon}
                <Text padding={'0 8px'} fontSize={14} fontWeight={500} lineHeight={'18px'} color={'#988EB7'}>
                  {i.symbol}
                </Text>
                {!!i.valueUsd && (
                  <Text fontSize={14} fontWeight={500} lineHeight={'18px'} color={'#281071'}>
                    $ {parseFloat(i.valueUsd) === 0 ? '<0.00000001' : i.valueUsd}
                  </Text>
                )}
              </div>
              <Text
                className={!!i.valueUsd ? 'normal' : i.amount >= 0 ? 'increase' : 'reduce'}
                fontSize={14}
                fontWeight={500}
                lineHeight={'18px'}
              >
                {!!i.valueUsd ? i.amount : i.amount < 0 ? i.amount : `+${i.amount}`}
              </Text>
            </div>
          ))}
        </div>
      ))}
    </CollapseContentWrapper>
  )
}
