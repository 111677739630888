import styled from 'styled-components'

export const LastAction = styled.div`
  background: #b5adff;
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  line-height: 15px;
  margin-left: 12px;
`

export const CollapseContentWrapper = styled.div`
  padding: 0 8px;
  .first-action {
    font-size: 14px;
    font-weight: 500;
    color: #988eb7;
    line-height: 17px;
    width: 100%;
    text-align: center;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 10px;
    }
  }
  .asset-tip {
    margin-left: 6px;
    path {
      fill: #988eb7;
    }
  }
  .action-item {
    padding-bottom: 16px;
    border-bottom: 1px solid #e6e6ee;
    &:last-child {
      padding: 16px 0 0 0;
      border: none;
    }
  }
  .content-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 8px;
    }
    .normal {
      color: #281071;
    }
    .increase {
      color: #38dcbf;
    }
    .reduce {
      color: #e93e5e;
    }
  }
`

export const PoolStatsWrapper = styled.div`
  border-radius: 12px;
  width: 100%;
  .token-liquidity {
    background: #f5f6f9;
    border-radius: 12px;
    width: 100%;
    padding: 22px 0;
    display: flex;
    align-items: center;
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: 50%;
      &:first-child {
        border-right: 1px solid #e6e6ee;
      }
    }
  }
  .info-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0 12px 0;
    .info-item {
      flex-basis: 25%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
  }
`

export const EarningContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .left {
    width: 250px;
    height: 249px;
    border-radius: 16px;
    margin-right: 24px;
    overflow: hidden;
    position: relative;
    .pool-name {
      position: absolute;
      bottom: 20px;
      width: 100%;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
      line-height: 21px;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    flex: 1;
    .per-price {
      display: flex;
      align-items: center;
      .per-price-text {
        display: flex;
        align-items: center;
        svg {
          margin-right: 4px;
          &:last-child {
            margin-left: 4px;
          }
        }
      }
    }
    .exchange {
      width: 32px;
      height: 32px;
      background: #fbfbfd;
      border-radius: 50%;
      border: 1px solid #f0f2f5;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        width: 13px;
        height: 13px;
      }
    }
    .liquidity {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 0;
      width: 100%;
    }
    .percent-card {
      padding: 24px;
      background: #ffffff;
      border-radius: 16px;
      border: 1px solid #e6e6ee;
      width: 100%;
      .token-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e6e6ee;
        padding-bottom: 24px;
        .token {
          display: flex;
          align-items: center;
        }
        .abr {
          background: #f5f6f9;
          border-radius: 8px;
          padding: 5px 16px;
          font-size: 16px;
          font-weight: 500;
          color: #281071;
          line-height: 22px;
        }
        &:nth-child(2) {
          border: none;
          padding: 24px 0 0 0;
        }
      }
    }
  }
`

export const Container = styled.div`
  padding: 0px 24px 16px 24px;
  .ant-pagination {
    margin: 20px 0 6px 0;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    display: flex;
    align-items: center;
    justify-content: center;
    &.ant-pagination-disabled {
      svg path {
        fill: #e6e6e6;
      }
    }
  }

  .ant-pagination-simple-pager {
    font-size: 14px;
    font-weight: 600;
    color: #281071;
    line-height: 22px;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  .action-group {
    display: flex;
    align-items: center;
    a {
      margin-right: 12px;
    }
  }
`
