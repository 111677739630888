import { ReactComponent as IconBitCoin } from '../assets/images/icon.xbtc.svg'
import { ReactComponent as IconBEVM } from '../assets/images/icon_BEVM.svg'
import { ReactComponent as IconPCX } from '../assets/images/icon_chainx.svg'
import React from 'react'
import { BridgeChain } from '../constants/bridge'
import { useSelectedTokenList } from '../state/lists/hooks'
import { useActiveWeb3React } from './index'

const style = {
  width: '24px',
  height: '24px'
}

export const useBridgeIcon = (): ((chainOrToken: string, customStyle?: React.CSSProperties) => React.ReactNode) => {
  const allTokens = useSelectedTokenList()
  const { chainId } = useActiveWeb3React()

  return (chainOrToken: string, customStyle?: React.CSSProperties) => {
    const logoURI = Object.values(allTokens[chainId]).find(i => i.tokenInfo.symbol === chainOrToken)?.tokenInfo?.logoURI
    switch (chainOrToken) {
      case BridgeChain.bitcoin:
      case 'BTC':
      case 'WBTC':
      case 'XBTC':
      case 'Wrapped BTC':
        return <IconBitCoin style={{ ...style, ...customStyle }} />
      case 'PCX':
        return <IconPCX style={{ ...style, ...customStyle }} />
      case BridgeChain.chainx:
        return <IconBEVM style={{ ...style, ...customStyle }} />
      case undefined:
      case null:
        return null
      default:
        return (
          <>
            {logoURI ? <img style={{...customStyle}} width={24} height={24} src={logoURI} alt='' /> : (
              <div
                style={{
                  ...style,
                  backgroundColor: '#7569E5',
                  borderRadius: '50%',
                  color: 'white',
                  textAlign: 'center',
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  ...customStyle
                }}
              >
                {chainOrToken?.slice(0, 1)?.toUpperCase()}
              </div>
            )}
          </>
        )
    }
  }
}
