import React, { useContext, useEffect, useState } from 'react'
import { X } from 'react-feather'
import { useSpring } from 'react-spring/web'
import styled, { ThemeContext } from 'styled-components'
import { animated } from 'react-spring'
import { PopupContent } from '../../state/application/actions'
import ListUpdatePopup from '../Popups/ListUpdatePopup'
import TransactionPopup from '../Popups/TransactionPopup'
import { isMobile } from 'react-device-detect'

export const StyledClose = styled(X)`
  position: absolute;
  right: 10px;
  top: 10px;
  :hover {
    cursor: pointer;
  }
`
export const Popup = styled.div`
  display: flex;
  position: relative;
  padding: 16px;
  overflow: hidden;
  background: #fafbfc;
  box-shadow: 5px 15px 15px 2px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  border: 1px solid #dce0e2;
  width: 390px;
  ${isMobile && 'width:91vw'};
`
const Fader = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => '#C8D0DA'};
`

const AnimatedFader = animated(Fader)

export default function PopupPolka({
  removeAfterMs,
  content,
  popKey,
  changeStatus
}: {
  removeAfterMs: number | null
  content?: PopupContent
  popKey: string
  changeStatus: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const [showPopUp, setShowPopUp] = useState(true)
  const removePopup = () => {
    changeStatus(false)
  }
  useEffect(() => {
    if (removeAfterMs === null) return undefined

    const timeout = setTimeout(() => {
      removePopup()
    }, removeAfterMs)

    return () => {
      clearTimeout(timeout)
    }
  }, [removeAfterMs])

  const theme = useContext(ThemeContext)

  let popupContent
  if ('txn' in content) {
    const {
      txn: { type, hash, success, summary }
    } = content
    popupContent = <TransactionPopup type={type} hash={hash} success={success} summary={summary} />
  } else if ('listUpdate' in content) {
    const {
      listUpdate: { listUrl, oldList, newList, auto }
    } = content
    popupContent = <ListUpdatePopup popKey={popKey} listUrl={listUrl} oldList={oldList} newList={newList} auto={auto} />
  }

  const faderStyle = useSpring({
    from: { width: '100%' },
    to: { width: '0%' },
    config: { duration: removeAfterMs ?? undefined }
  })

  return (
    <div style={{ position: 'fixed', top: isMobile ? '1rem' : '5rem', right: '1rem', zIndex: '10001' }}>
      {showPopUp ? (
        <Popup>
          {popupContent}
          {removeAfterMs !== null ? <AnimatedFader style={faderStyle} /> : null}
          <StyledClose
            color={'#3B199F'}
            onClick={removePopup}
            style={{ top: '0px', right: '0px', position: 'relative' }}
          />
        </Popup>
      ) : null}
    </div>
  )
}
