import React, { useCallback, useContext, useEffect, useState } from 'react'
import PopupPolka from '../PopupPolka'

export interface TxPopProps {
  operatorResult: boolean
  messages: any
  setAmount?: React.Dispatch<string>
  type: string
  hash: string
}

export default function TxPopMessage({ type, hash, operatorResult, messages, setAmount }: TxPopProps) {
  const [showPop, setShowPop] = useState(false)
  const [popContent, setPopContent] = useState({
    txn: {
      type: '',
      hash: '',
      success: true,
      summary: ''
    }
  })

  const popUpMessage = useCallback((type: string, hash: string, result: boolean, summary: string) => {
    setShowPop(false)
    setPopContent({
      txn: {
        type: type,
        hash: hash,
        success: result,
        summary: summary
      }
    })
    return setShowPop(true)
  }, [])

  useEffect(() => {
    popUpMessage(type, hash, operatorResult, messages)
  }, [])

  return (
    <>{showPop && <PopupPolka popKey={'1'} removeAfterMs={4000} content={popContent} changeStatus={setShowPop} />}</>
  )
}
