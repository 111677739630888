import axios from 'axios'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import useSWR from 'swr'
import { formatInfoTablePrice } from '../../helper/formatPrice'
import BigNumber from 'bignumber.js'

dayjs.extend(relativeTime)

export const CHAINX_EVM_INFO_API = 'https://multiscan-api.coming.chat/chainxevm/info'
const SHERPAX_EVM_BLOCK_SCAN = 'https://scan-canary.bevm.io'

const formatPrice = (price: number) => {
  return `$ ${formatInfoTablePrice(price)}`
}

export type Token = {
  address: string
  name: string
  symbol: string
  priceUsd: number
  priceChangePercent: number
  dailyVolumeUsd: number
  liquidityUsd: number
}

export type TokenListData = {
  id: number
  blockLink: string
  name: string
  symbol: string
  price: string
  priceChangePercent: number
  dailyVolumeUsd: string
  liquidityUsd: string
  priceNum: number
  dailyVolumeUsdNum: number
  tvlNum: number
  priceSats: number
  address: string
}

export const useTokensListData = (
  refresh = true
): { data: TokenListData[]; error: Error; refetch: () => Promise<void>; loading: boolean } => {
  const { data, mutate, error, isLoading, isValidating } = useSWR(
    `${CHAINX_EVM_INFO_API}/tokens`,
    async (url: string) => {
      const { data } = await axios.get(url)
      const btcPrice = data?.tokenList.find(i => i.symbol === 'BTC')?.priceUsd || 1
      return data.tokenList.map((token: Token, index: number) => {
        const { address, name, symbol, priceChangePercent, priceUsd, dailyVolumeUsd, liquidityUsd } = token

        const priceSats = new BigNumber(priceUsd)
          .dividedBy(btcPrice)
          .multipliedBy(Math.pow(10, 8))
          .toNumber()
        return {
          id: index + 1,
          blockLink: `${SHERPAX_EVM_BLOCK_SCAN}/address/${address}`,
          name: `${name}`,
          symbol,
          price: formatPrice(priceUsd),
          priceChangePercent,
          dailyVolumeUsd: formatPrice(dailyVolumeUsd),
          liquidityUsd: formatPrice(liquidityUsd),
          priceNum: priceUsd,
          dailyVolumeUsdNum: dailyVolumeUsd,
          tvlNum: liquidityUsd,
          priceSats: priceSats,
          address
        }
      })
    },
    {
      revalidateOnFocus: refresh,
      refreshWhenHidden: refresh,
      refreshWhenOffline: refresh
    }
  )
  return { data, error, refetch: mutate, loading: isLoading || isValidating }
}

export const useIsChina = (): { data: { isChina: boolean }; error: any } => {
  const { data, error } = useSWR(`https://common-pre.coming.chat/getIpRegion`, async (url: string) => {
    const { data } = await axios
      .get(url)
      .then(res => (data.isChina = res?.data?.isChina))
      .catch(error => (data.isChina = true))
    return data
  })
  return { data, error }
}

export type PairItem = {
  pairAddress: string
  token0Address: string
  token0Name: string
  token0Symbol: string
  token1Address: string
  token1Name: string
  token1Symbol: string
  feePercent: number
  liquidityUsd: number
  dailyVolumeUsd: number
  sevenDayVolumeUsd: number
}

export type PoolsVolumeData = {
  id: number
  poolName: string
  feePercent: string
  liquidityUsd: string
  dailyVolumeUsd: string
  sevenDayVolumeUsd: string
  liquidityUsdNum: number
  dailyVolumeUsdNum: number
  sevenDayVolumeUsdNum: number
}

export const usePoolsVolumeData = (): { data: PoolsVolumeData[]; error: Error } => {
  const { data, error } = useSWR(`${CHAINX_EVM_INFO_API}/pairs`, async (url: string) => {
    const { data } = await axios.get(url)

    return data.pairList
      .filter(pair => pair.token0Symbol && pair.token1Symbol)
      .map((pair: PairItem, index) => {
        const { token0Symbol, token1Symbol, feePercent, liquidityUsd, dailyVolumeUsd, sevenDayVolumeUsd } = pair
        return {
          id: index + 1,
          poolName: `${token0Symbol}/${token1Symbol}`,
          feePercent: feePercent.toFixed(2),
          liquidityUsd: formatPrice(liquidityUsd),
          dailyVolumeUsd: formatPrice(dailyVolumeUsd),
          sevenDayVolumeUsd: formatPrice(sevenDayVolumeUsd),
          liquidityUsdNum: liquidityUsd,
          dailyVolumeUsdNum: dailyVolumeUsd,
          sevenDayVolumeUsdNum: sevenDayVolumeUsd
        }
      })
  })

  return { data, error }
}

export type transactionItem = {
  actionType: string
  actionName: string
  totalValueUsd: number
  token0Amount: number
  token1Amount: number
  token0Symbol: string
  token1Symbol: string
  account: string
  blockTime: number
  txHash: string
}

export type TransactionItemData = {
  id: number
  actionType: string
  actionName: string
  txBlockLink: string
  totalValue: string
  token0Amount: string
  token1Amount: string
  account: string
  accountBlockLink: string
  time: string
  totalValueNum: number
  token0AmountNum: number
  token1AmountNum: number
  timeStamp: number
}

export const useTransactionsData = (): { data: TransactionItemData[]; error: Error } => {
  const { data, error } = useSWR(`${CHAINX_EVM_INFO_API}/latestPairTx`, async (url: string) => {
    const { data } = await axios.get(url)

    return data.txList.map((item: transactionItem, index: number) => {
      const {
        actionType,
        actionName,
        totalValueUsd,
        token0Amount,
        token0Symbol,
        token1Amount,
        token1Symbol,
        account,
        txHash,
        blockTime
      } = item

      return {
        id: index + 1,
        actionType,
        actionName: `${actionName}↗`,
        txBlockLink: `${SHERPAX_EVM_BLOCK_SCAN}/tx/${txHash}`,
        totalValue: formatPrice(totalValueUsd),
        token0Amount: `${formatPrice(token0Amount)} ${token0Symbol}`,
        token1Amount: `${formatPrice(token1Amount)} ${token1Symbol}`,
        account: `${account}↗`,
        accountBlockLink: `${SHERPAX_EVM_BLOCK_SCAN}/address/${account}`,
        time: dayjs.unix(blockTime).fromNow(),
        totalValueNum: totalValueUsd,
        token0AmountNum: token0Amount,
        token1AmountNum: token1Amount,
        timeStamp: blockTime
      }
    })
  })
  return { data, error }
}

export type LiquidityItemKeys = 'value' | 'time'

export type LiquidityItem = {
  [key in LiquidityItemKeys]: number
}

export const useLiquidityListData = (
  cardName: string,
  startTimestamp: number,
  endTimestamp: number
): { data: LiquidityItem[]; error: Error } => {
  const { data, error } = useSWR(
    `${CHAINX_EVM_INFO_API}/daily/${cardName}?startTimestamp=${startTimestamp}&endTimestamp=${endTimestamp}`,
    async (url: string) => {
      const { data } = await axios.get(url)
      return data?.data.map((item: LiquidityItem, index: number) => {
        const { time, value } = item
        return {
          time: time * 1000,
          value: value
        }
      })
    }
  )
  return { data, error }
}
