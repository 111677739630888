import React, { Suspense, useContext } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import Header from '../components/Header'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectPoolDetails,
  RedirectToAddLiquidity
} from './AddLiquidity/redirects'
import MigrateV1 from './MigrateV1'
import MigrateV1Exchange from './MigrateV1/MigrateV1Exchange'
import RemoveV1Exchange from './MigrateV1/RemoveV1Exchange'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
// import Bridge from './Bridge'
import { PopUpMessageContext } from '../hooks/PopUpMessageProvider'
import TxPopMessage from '../components/TxPopMessage'
import SwapInner from './SwapInner'
import Info from './Info'
import Footer from '../components/Footer'
import PoolsInner from './PoolsInner'
import Swap from './Swap'
import Pool from './Pool'
import PoolDetails from './PoolDetails'
import Envelope from './Envelope'
import RedEnvelope from './RedEnvelope'
import Tool from './Tool';

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
  padding-bottom: 48px;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 58px;
  align-items: center;
  flex: 1;
  overflow-y: hidden;
  overflow-x: hidden;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      padding: 2.13vw
  `};

  z-index: 1;
`

export default function App(): React.ReactElement {
  const { isShowPop, messageContent, txResult, type, hash } = useContext(PopUpMessageContext)

  return (
    <Suspense fallback={null}>
      <HashRouter>
        <Route component={GoogleAnalyticsReporter} />
        <Route component={DarkModeQueryParamReader} />
        <Popups />
        {isShowPop && <TxPopMessage type={type} hash={hash} operatorResult={txResult} messages={messageContent} />}
        <Web3ReactManager>
          <AppWrapper>
            <Header />
            <BodyWrapper>
              <Switch>
                <Route path="/swap" component={Swap} />
                <Route path="/tool" component={Tool} />
                <Route path="/pool" component={Pool} />
                {/*<Route exact strict path="/bridge" component={Bridge} />*/}
                <Route exact strict path="/redEnvelope" component={RedEnvelope} />
                <Route path="/pools" component={PoolsInner} />
                <Route path="/envelope" component={Envelope} />
                {/*<Route exact path="/poolDetails" component={PoolDetails} />*/}
                <Route exact path="/poolDetails/:currencyIdA/:currencyIdB" component={RedirectPoolDetails} />
                <Route path="/info" component={Info} />
                <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
                <Route exact strict path="/find" component={PoolFinder} />
                <Route exact strict path="/create" component={RedirectToAddLiquidity} />
                <Route exact path="/add" component={AddLiquidity} />
                <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                <Route exact strict path="/remove/v1/:address" component={RemoveV1Exchange} />
                <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
                <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
                <Route exact strict path="/migrate/v1" component={MigrateV1} />
                <Route exact strict path="/migrate/v1/:address" component={MigrateV1Exchange} />
                <Route component={RedirectPathToSwapOnly} />
              </Switch>
            </BodyWrapper>
            <Footer />
          </AppWrapper>
        </Web3ReactManager>
      </HashRouter>
    </Suspense>
  )
}
