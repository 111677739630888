import React from 'react'
import WrapperBox from '../../components/WrapperBox'
import SwapPrice from './SwapPrice'
import { DetailsContainer } from '../../components/swap/styleds'
import SwapSingleDetails from '../../components/swap/SwapSingleDetails'
import QuestionHelper from '../../components/QuestionHelper'
import SwapRoute from '../../components/swap/SwapRoute'
import { Trade } from 'so-swap-sdk'
import SwapDetails from '../../components/swap/SwapDetails'
import styled from 'styled-components'

interface SwapPop {
  trade: Trade
  allowedSlippage: number
  showInverted: boolean
  showDetails: boolean
  setShowDetails: React.Dispatch<boolean>
  showRoute: boolean
}

const ShowDetailsWrapper = styled.div<{ showSlippage?: boolean }>`
  .details-close {
    transition: all 0.2s;
    height: 0;
    position: relative;
    overflow: hidden;
  }
  .details-open {
    margin-top: 16px;
    transition: all 0.2s;
    height: ${({ showSlippage }) => (showSlippage ? '184px' : '234px;')};
    position: relative;
    overflow: hidden;
  }
`

export default function SwapTxDetails({
  trade,
  allowedSlippage,
  showInverted,
  showDetails,
  setShowDetails,
  showRoute
}: SwapPop) {
  return (
    <>
      {trade && (
        <ShowDetailsWrapper showSlippage={allowedSlippage === 50}>
          <WrapperBox
            content={
              <SwapPrice
                trade={trade}
                allowedSlippage={allowedSlippage}
                showInverted={showInverted}
                showDetails={showDetails}
                setShowDetails={setShowDetails}
              />
            }
          />
          <div className={`${showDetails ? 'details-open' : 'details-close'}`}>
            <DetailsContainer>
              <SwapDetails trade={trade} allowedSlippage={allowedSlippage} showSlippage={true} />
            </DetailsContainer>
            <SwapSingleDetails
              title={'Route'}
              titleChild={
                <QuestionHelper text="Routing through these tokens resulted in the best price for your trade." />
              }
              showCom={showRoute}
              content={<SwapRoute trade={trade} />}
            />
          </div>
        </ShowDetailsWrapper>
      )}
    </>
  )
}
