import React from 'react'
import { Text } from 'rebass'
import styled from 'styled-components'
import { WhiteCard } from './index'
import { ReactComponent as IconWarning } from '../../assets/icon_warning.svg'
import { ButtonPrimary } from '../Button'
import { useHistory } from 'react-router-dom'

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 8px;
  .description {
    background: #f1f5fb;
    border-radius: 6px;
    padding: 20px 26px;
    font-size: 16px;
    font-weight: 400;
    color: #988eb7;
    line-height: 20px;
    margin-bottom: 24px;
  }
`

export const RemoveNoticeCard: React.FC = () => {
  const { push } = useHistory()

  return (
    <WhiteCard style={{ maxWidth: '424px', marginBottom: '58px' }}>
      <Container>
        <IconWarning style={{ width: '88px' }} />
        <Text fontSize={18} fontWeight={500} lineHeight={'21px'} color={'#3B199F'} padding={'20px 0 16px 0'}>
          Important Notice
        </Text>
        <div className={'description'}>
          The new contract is being upgraded and the old contract is no longer maintained. Please check if you have the
          following links for old liquidity and remove them.
        </div>
        <ButtonPrimary onClick={() => push('/pools/myPosition')}>Remove Liquidity</ButtonPrimary>
      </Container>
    </WhiteCard>
  )
}
