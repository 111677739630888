import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  > div:nth-child(1) {
    margin-bottom: 2px;
    z-index: 2;
    position: relative;
  }
`
const Switch = styled.div<{ position?: string; isExpect?: boolean }>`
  ${({ position }) => (position ? position : 'justify-content:center;')}
  display: flex;
  flex-direction: row;
  position: ${({ isExpect }) => (isExpect ? 'relative' : 'absolute;')}
  z-index: 2;
  width: 100%;
  align-items: center;
  ${({ isExpect }) => isExpect && 'padding:12px 0;'}
  ${({ isExpect }) => !isExpect && 'transform: translateY(-50%);'}
`

const Content = styled.div`
  border-radius: 20px;
  border: 2px solid white;
  background-color: white;
  :focus,
  :active,
  :hover {
    border: 2px solid rgba(109, 78, 201, 0.57);
  }
`

const SwitchBox = styled.div<{ canChangeSwitchIcon?: boolean }>`
  background: #ffffff;
  border-radius: 12px;
  border: 2px solid #e3e7f9;
  width: fit-content;
  display: flex;
  justify-content: center;
  padding: 8px;
  :hover {
    border: 2px solid #e3e7f9;
    ${({ canChangeSwitchIcon }) => canChangeSwitchIcon && 'background: #b5adff; cursor: pointer;'}
  }
  > svg {
    width: 17px;
  }
`

interface Props {
  topBox: React.ReactNode
  bottomBox: React.ReactNode
  switchIcon?: React.ReactNode
  switchContent?: React.ReactNode
  switchIconPosition?: string
  onMouseOver?: () => void
  onMouseLeave?: () => void
  canChangeSwitchIcon?: boolean
  onClick?: () => void
}

export default function PanelContainer({
  topBox,
  bottomBox,
  switchIcon,
  switchIconPosition,
  switchContent,
  onMouseLeave,
  onMouseOver,
  canChangeSwitchIcon,
  onClick
}: Props) {
  return (
    <Container>
      <Content>
        {topBox}
        {!switchContent && switchIcon && (
          <Switch position={switchIconPosition}>
            <SwitchBox
              onClick={onClick}
              canChangeSwitchIcon={canChangeSwitchIcon}
              onMouseLeave={onMouseLeave}
              onMouseOver={onMouseOver}
            >
              {switchIcon}
            </SwitchBox>
          </Switch>
        )}
      </Content>
      {switchIcon && switchContent && (
        <Switch position={switchIconPosition} isExpect={true}>
          <SwitchBox
            onClick={onClick}
            canChangeSwitchIcon={canChangeSwitchIcon}
            onMouseLeave={onMouseLeave}
            onMouseOver={onMouseOver}
          >
            {switchIcon}
          </SwitchBox>
          {switchContent}
        </Switch>
      )}
      <Content>{bottomBox}</Content>
    </Container>
  )
}
