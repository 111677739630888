import axios, { AxiosInstance, AxiosRequestConfig, AxiosError, Canceler } from 'axios'

const httpRequest: AxiosInstance = axios.create()

// 创建一个请求队列
const requestQueue: Map<string, Canceler> = new Map()

// 生成请求的唯一标识
const generateRequestKey = (url: string, method: string, params: object): string => {
  return `${url}-${method}-${JSON.stringify(params)}`
}

// 取消重复请求
const cancelPreviousRequest = (key: string): void => {
  if (requestQueue.has(key)) {
    const cancel = requestQueue.get(key)
    cancel && cancel('Request canceled due to duplicate request.')
    requestQueue.delete(key)
  }
}

// 添加请求拦截器
httpRequest.interceptors.request.use((config: AxiosRequestConfig) => {
  // 生成唯一的请求标识
  const requestKey = generateRequestKey(config.url!, config.method, config?.data?.params || {})

  // 取消之前的重复请求
  cancelPreviousRequest(requestKey)

  // 添加取消令牌到请求配置
  config.cancelToken = new axios.CancelToken(cancel => {
    requestQueue.set(requestKey, cancel)
  })

  return config
})

// 移除请求拦截器时清空请求队列
const removeInterceptor = httpRequest.interceptors.request.use((config: AxiosRequestConfig) => config)
httpRequest.interceptors.request.eject(removeInterceptor)

export default httpRequest
