import styled, { css, keyframes } from 'styled-components'
import { ReactComponent as LoadingIcon } from '../../assets/Assets/loading.svg'
import React from 'react'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
const circleLoadingRule = css`
  animation: ${rotate} linear infinite;
`
const LoadingWrapper = styled(LoadingIcon)<{ size: number; time: string; marginRight: number; color: string }>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  ${circleLoadingRule};
  animation-duration: ${({ time }) => time};
  margin-right: ${({ marginRight }) => `${marginRight}px`};
  path {
    fill: ${({ color }) => color};
  }
`

interface Props {
  size: number
  time?: string
  marginRight?: number
  color?: string
}

export const Loading: React.FC<Props> = ({ size, color, time = '0.75s', marginRight = 6 }: Props) => {
  return <LoadingWrapper size={size} time={time} marginRight={marginRight} color={color} />
}
