import React, { useCallback, useState } from 'react'
import { Props, slide as Menu } from 'react-burger-menu'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import LogoMobile from '../../assets/Mobile/logo_swap.svg'
import menuIcon from '../../assets/Mobile/icon_arrow.svg'
import menuIconSelected from '../../assets/Mobile/icon_arrow_selected.svg'
import more from '../../assets/Mobile/icon_more.svg'
import close from '../../assets/Mobile/icon_close.svg'
import { headerData } from './routeData'

const LogoDiv = styled.div`
  display: block;
  padding: 5.3vw;
  margin-bottom: 8.5vw;
`

const MenuItem = styled.div<{ hasChild?: boolean }>`
  background-color: white !important;
  outline: none !important;
  #container {
    float: left;
    width: 100%;
    outline: none;
  }
`

const ItemName = styled.div<{ isCurrent?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  font-size: 4.8vw;
  font-weight: 400;
  color: ${({ isCurrent }) => (isCurrent ? '#6D4EC9' : '#131415')};
  outline: none;
`

const MenuIcon = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #e4e8f2;
  color: #6d4ec9;
  border-radius: 16px;
  border: 2px solid white;
  padding: 10px;
  left: 12px !important;
  position: relative;
  outline: none;
  > img {
    width: 24px;
  }
`
const MenuContent = (
  props: JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<Menu> &
    Readonly<Props> &
    Readonly<{ children?: React.ReactNode }>
) => {
  const isMenuOpen = function(state: { isOpen: any }) {
    setOpenMenu(state.isOpen)
  }

  const closeBmMenu = () => {
    setOpenMenu(false)
  }

  const swapTab = useCallback(key => {
    setOpenMenu(false)
    window.location.hash = key
  }, [])

  const [openMenu, setOpenMenu] = useState(false)

  return (
    <Menu
      right
      {...props}
      isOpen={openMenu}
      customBurgerIcon={
        <MenuIcon>
          <img src={more} />
        </MenuIcon>
      }
      customCrossIcon={<img src={close} />}
      onStateChange={isMenuOpen}
    >
      <LogoDiv onClick={closeBmMenu}>
        <Link to={'/'}>
          <img src={LogoMobile} alt="" style={{ width: '7.4648vw' }} />
        </Link>
      </LogoDiv>

      {headerData?.map(item => {
        if (item.title === 'Doc') {
          return (
            <MenuItem key={item.title} hasChild={true}>
              <a href={item.route} target="_self" rel="noreferrer">
                <ItemName>
                  <span> {item.title}</span>
                  <img src={menuIcon} alt="" style={{ width: '5vw' }} />
                </ItemName>
              </a>
            </MenuItem>
          )
        } else {
          return (
            <MenuItem key={item.title} hasChild={true}>
              <ItemName onClick={() => swapTab(item.route)} isCurrent={window.location.hash.includes(item.route)}>
                <span> {item.title}</span>
                <img
                  src={window.location.hash.includes(item.route) ? menuIconSelected : menuIcon}
                  alt=""
                  style={{ width: '5vw' }}
                />
              </ItemName>
            </MenuItem>
          )
        }
      })}
    </Menu>
  )
}

export default MenuContent
