import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { RowBetween } from '../Row'
import QuestionHelper from '../QuestionHelper'
import { ActiveText, LineTabsWrapper, MoveDiv, StyledNavLink, TabBox, TabContainer, Tabs } from './styles'
import { ReactComponent as IconArrowLeft } from '../../assets/images/icon_arrow_left.svg'

const labelMap = {
  'Add Liquidity': {
    to: '/pools',
    tipText:
      'When you add liquidity, you are given pool tokens representing your position. These tokens automatically earn fees proportional to your share of the pool, and can be redeemed at any time.'
  },
  'Remove Liquidity': {
    to: '/pools/myPosition',
    tipText:
      'Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive.'
  },
  'Import Pool': {
    to: '/pools',
    tipText: "Use this Tool to find pairs that don't automatically appear in the interface."
  }
}

type RouteItem = {
  title: string
  route: string
}

const SwapPoolTabRouteList: RouteItem[] = [
  { title: 'Swap', route: 'swap' },
  { title: 'Pool', route: 'swap/pool' }
]

const AssetsTabRouteList: RouteItem[] = [
  { title: 'Trading(EVM)', route: 'asset' },
  { title: 'Funding(WASM)', route: 'asset/funding' }
]

const EarnTabRouteList: RouteItem[] = [
  { title: 'Farms', route: 'earn' },
  { title: 'Pools', route: 'earn/pools' }
]

const GrabTabRouteList: RouteItem[] = [
  { title: 'Newest', route: 'grab' },
  { title: 'Hottest', route: 'grab/hottest' },
  { title: 'Fastest', route: 'grab/fastest' }
]

const PoolsRouteList: RouteItem[] = [
  { title: 'Pools', route: 'pools' },
  { title: 'MyPosition', route: 'pools/myPosition' }
]

const routeMap = {
  Swap: SwapPoolTabRouteList,
  Pool: SwapPoolTabRouteList,
  'Trading(EVM)': AssetsTabRouteList,
  'Funding(WASM)': AssetsTabRouteList,
  Newest: GrabTabRouteList,
  Hottest: GrabTabRouteList,
  Fastest: GrabTabRouteList,
  MyPosition: PoolsRouteList,
  Pools: PoolsRouteList
}

interface LineTabsProps {
  label: 'Add Liquidity' | 'Remove Liquidity' | 'Import Pool'
}

export const LineTabs: React.FC<LineTabsProps> = ({ label }: LineTabsProps) => {
  return (
    <LineTabsWrapper>
      <RowBetween style={{ padding: '24px' }}>
        <Link style={{ display: 'flex', alignItems: 'center' }} to={labelMap[label].to}>
          <IconArrowLeft />
        </Link>
        <ActiveText>{label}</ActiveText>
        <QuestionHelper text={labelMap[label].tipText} />
      </RowBetween>
    </LineTabsWrapper>
  )
}

interface NavigationTabProps {
  width: number
  mobileWidth?: number
  mobileMoveWidth?: number
  padding?: string
  active: string
  itemNum?: number
}

export function NavigationTab({
  active,
  padding,
  width,
  mobileWidth,
  mobileMoveWidth,
  itemNum
}: NavigationTabProps): React.ReactElement {
  const { pathname } = useLocation()

  const [num, setNum] = useState(0)
  const [, setCurrentName] = useState(active)

  useEffect(() => {
    const route = pathname.slice(1)
    if (route === 'swap' || route === 'asset' || route === 'earn' || route === 'pools') {
      setNum(0)
    } else {
      setNum(1)
    }
  }, [pathname])

  const handleClick = (index, name) => {
    setNum(index)
    setCurrentName(name)
  }

  return (
    <TabContainer padding={padding}>
      <Tabs>
        <TabBox itemNum={itemNum ? itemNum : 2} width={width} mobileWidth={mobileWidth} />
        {routeMap[active]?.map((item, index) => {
          return (
            <StyledNavLink
              key={item.title}
              to={`/${item.route}`}
              isActive={() => active === item.title}
              onClick={() => handleClick(index, item.title)}
              width={width}
              index={index}
            >
              {item.title}
            </StyledNavLink>
          )
        })}
        <MoveDiv translateNum={num} width={width} mobileWidth={mobileMoveWidth} />
      </Tabs>
    </TabContainer>
  )
}
