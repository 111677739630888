import React from 'react'
import ErrorIcon from '../../assets/images/img_errors.svg'
import styled from 'styled-components'
interface Props {
  errorMessage?: string
}

const ErrorWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 600px;
  .text {
    font-size: 14px;
    font-weight: 500;
    color: #988eb7;
    line-height: 17px;
  }
  .button {
    height: 48px;
    background: #48e8cc;
    box-shadow: 0px 2px 4px 0px rgba(78, 91, 201, 0.12), inset 0px 0px 5px 0px rgba(255, 255, 255, 0.5);
    border-radius: 16px;
    padding: 14px 94px;
    margin: 20px 0;
    .text {
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      line-height: 20px;
    }
  }
`

const ErrorPage: React.FC<Props> = ({ errorMessage }: Props) => {
  return (
    <ErrorWrapper>
      <img src={ErrorIcon} alt="" />
      <div className="text">{errorMessage}</div>
      <div className="button" onClick={() => window.location.reload()}>
        <div className="text">Refresh</div>
      </div>
    </ErrorWrapper>
  )
}

export default ErrorPage
