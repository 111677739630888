import axios from 'axios'
import { create } from 'zustand'
import { COMING_ZERO_API, SUPPORTED_TOKENS } from '../constants/redEnvelope'
import { calculateCRC64 } from '../helper/generateCID'
import { formatRedPacketTimeStamp } from '../helper/formatRedPacketTimeStamp'

export enum RedEnvelopeType {
  random = 'Random',
  fixed = 'Fixed'
}

type CreateParams = {
  type: RedEnvelopeType
  count: string
  tokenAmount: string
  name: string
  tokenInfo: {
    address: string
    symbol: typeof SUPPORTED_TOKENS.SATS.symbol
    decimals: number
  }
}

interface RedEnvelopeStore {
  createParams: CreateParams
  setCreateParams: (params: Partial<CreateParams>) => void
  clearCreateParams: () => void
  createdId: string
  setCreatedId: (id: string) => void
  txHash: string
  setTxHash: (hash: string) => void
  createRedEnvelop: (params: CreateParams & { account: string; txHash: string; txFee: string }) => Promise<string>
}

const initCreateParams: CreateParams = {
  type: RedEnvelopeType.random,
  count: '',
  tokenAmount: '',
  name: '',
  tokenInfo: {
    address: SUPPORTED_TOKENS.SATS.address,
    symbol: SUPPORTED_TOKENS.SATS.symbol,
    decimals: SUPPORTED_TOKENS.SATS.decimals
  }
}

const useRedEnvelopeStore = create<RedEnvelopeStore>((set, get) => ({
  createParams: initCreateParams,
  setCreateParams: params => {
    set(() => ({ createParams: { ...get().createParams, ...params } }))
  },
  clearCreateParams: () => {
    set(() => ({ createParams: initCreateParams, createdId: '', txHash: '' }))
  },
  createdId: '',
  setCreatedId: id => set(() => ({ createdId: id })),
  txHash: '',
  setTxHash: hash => set(() => ({ txHash: hash })),
  createRedEnvelop: async ({ type, name, count, tokenAmount, tokenInfo, account, txHash, txFee }) => {
    const postData = {
      chainName: 'bevm',
      createCid: calculateCRC64(account),
      createAddress: account,
      txHash,
      fee: txFee,
      tokenAddress: tokenInfo.address,
      coinType: tokenInfo.symbol.toUpperCase(),
      coinName: tokenInfo.symbol.toUpperCase(),
      decimals: tokenInfo.decimals,
      amount: tokenAmount,
      number: Number(count),
      allocation: type === RedEnvelopeType.random ? 2 : 1, //分配方式 1:群红包、均分 2:群红包、随机 3:单人红包
      words: name,
      timestamp: 0
    }
    const { data } = await axios.post(`${COMING_ZERO_API}/redenvelop/V2/create`, {
      ...postData,
      timestamp: formatRedPacketTimeStamp(postData)
    })
    set(() => ({ createdId: data?.redEnvelopId }))
    return data?.redEnvelopId
  }
}))

export default useRedEnvelopeStore
