import React, { useEffect, useState } from 'react';
import { Text } from 'rebass';
import { ButtonPrimary } from '../Button';
import { useActiveWeb3React } from '../../hooks';
import { getClaimBTCContract, getMaxFeeGas, getTokenContract } from '../../utils';
import { useCheckEligibility } from '../../hooks/useCheckEligibility';
import BalanceMax from '../BalanceMax';
import NumericalInput from '../NumericalInput';
import { TradeContainer } from '../../pages/Tool/styles';
import { toUpperCaseInitial } from '../../helper/toUpperCaseInitial';
import { useTransactionAdder } from '../../state/transactions/hooks';
import { BigNumber } from 'bignumber.js';
import { BTC_ADDRESS } from '../../constants/claimBTC';
import arrowIcon from '../../assets/Swap/redeem_arrow.svg'
import { ReactComponent as WBTCIcon } from '../../assets/Swap/icon_WBTC.svg'
import { ReactComponent as BTCIcon } from '../../assets/Swap/icon_btc.svg'
import { ReactComponent as IconTip } from '../../assets/Swap/icon_tip.svg'
import useRedeemStore from '../../store/redeem';

export const RedeemWithdraw: React.FC = () => {
  const { account, library } = useActiveWeb3React()
  const { setShowRedeemSwap, setCurrentRedeemStep } = useRedeemStore()
  const [pendingApproveTx, setPendingApproveTx] = useState(false)
  const [pendingClaimTx, setPendingClaimTx] = useState(false)
  // const [withdrawAmount, setWithdrawAmount] = useState('');
  const [receiveAmount, setReceiveAmount] = useState('');
  const addTransaction = useTransactionAdder()
  const { ordiMaxWBTC, currentWBTCBalance } = useCheckEligibility()
  const [approveSuccess, setApproveSuccess] = useState(false)

  const approveWBTC = async() => {
    setPendingApproveTx(true)
    const withdrawAmountFormat = new BigNumber(currentWBTCBalance).multipliedBy(Math.pow(10,18)).toString()
    const tokenContract = getTokenContract('0x09Ff8E49D0EA411A3422ed95E8f5497D4241F532', library, account)
    try {
      const tx = await tokenContract.approve(BTC_ADDRESS, withdrawAmountFormat, { ...getMaxFeeGas() })
      console.log('tx', tx)
      tx &&
      addTransaction(tx, {
        summary: toUpperCaseInitial('approve') + ' ' + currentWBTCBalance
      })
      await tx.wait()
      setApproveSuccess(true)
      setPendingApproveTx(false)
    }catch (e) {
      setPendingApproveTx(false)
      console.error(e)
    }
  }

  const claimWBTC = async() => {
    setPendingClaimTx(true)
    try {
      const claimBTCContract = getClaimBTCContract(library, account)
      const result = await claimBTCContract?.claim_btc()
      console.log('result', result)
      result &&
      addTransaction(result, {
        summary: toUpperCaseInitial('redeem') + ' ' + currentWBTCBalance
      })
      await result.wait()
      setShowRedeemSwap(false)
      setCurrentRedeemStep(0)
      setPendingClaimTx(false)
    }catch (e) {
      setPendingClaimTx(false)
      console.error(e)
    }
  }


  return (
    <TradeContainer style={{gap: '4px'}}>
      <div className="wrapper">
        <div className="card-top">
          <span>You Send</span>
          <div className="item-row">
            Balance: {currentWBTCBalance}
          </div>
        </div>
        <div className="card-down">
          <NumericalInput
            className="amount"
            disabled={true}
            value={currentWBTCBalance}
            onUserInput={() => {}}
            align={'left'}
          />
          <div className="item-row">
            <WBTCIcon width={'24px'} height={'24px'}/>
            <span className="token-name">WBTC</span>
          </div>
        </div>
      </div>
      <div className="arrow-bg"><img src={arrowIcon} alt=""/></div>
      <div className="wrapper">
        <div className="card-top">
          <span>You Receive</span>
        </div>
        <div className="card-down">
          <NumericalInput
            disabled={true}
            className="amount"
            value={Math.min(Number(ordiMaxWBTC), Number(currentWBTCBalance)).toString()}
            onUserInput={()=>{}}
            align={'left'}
          />
          <div className="item-row">
            <BTCIcon width={'24px'} height={'24px'}/>
            <span className="token-name">BTC</span>
          </div>
        </div>
      </div>
      <div style={{marginTop:'20px'}}>
        {approveSuccess ? <ButtonPrimary disabled={pendingClaimTx}
                                         onClick={claimWBTC}>
          {pendingClaimTx ? 'pending' : 'Redeem'}
        </ButtonPrimary> : <ButtonPrimary disabled={Number(currentWBTCBalance) === 0 || pendingApproveTx}
                                          onClick={approveWBTC}>
          {pendingApproveTx ? 'pending' : 'Approve'}
        </ButtonPrimary>}
      </div>
      <div style={{display:'flex', flexDirection:'row', gap:'8px', cursor:'pointer', alignItems: 'center'}}>
        <IconTip width={'25px'} height={'25px'}/>
        <Text padding={'6px 0'} fontSize={16} fontWeight={500} color={'#988EB7'} lineHeight={'17px'}>You can redeem up to {ordiMaxWBTC} BTC
          One address can only redeem once</Text>
      </div>
    </TradeContainer>
  );
};
