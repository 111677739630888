/** @format */

import React from 'react'

interface IconProps {
  iconSrc: string
  altName?: string
  width?: number
  height?: number
  className?: string
  onClick?: () => void
}

const Icon: React.FC<IconProps> = ({
  iconSrc,
  altName,
  width,
  height,
  className,
  onClick,
}: IconProps) => {
  return (
    <img
      onClick={onClick}
      className={className}
      src={iconSrc}
      alt={altName}
      width={width}
      height={height}
    />
  )
}

export default Icon
