import styled from 'styled-components'
import { ExternalLink } from '../../theme'
import { isMobile } from 'react-device-detect'
export const UpperSection = styled.div`
  position: relative;
  background-color: transparent;

  .dashBorder {
    position: absolute;
    width: 52px;
    height: 48px;
    border-left: 1px dashed #aba5bd;
    top: 90px;
    left: 29px;
  }

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

export const InfoCard = styled.div`
  padding: 1rem;
  position: relative;
  display: grid;
  grid-row-gap: 12px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(109, 78, 201, 0.06);
  border-radius: 20px;
  border: 1px solid #e8eaf1;
`

export const AccountGroupingRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};

  div {
    ${({ theme }) => theme.flexRowNoWrap}
    align-items: center;
  }
`

export const AccountSection = styled.div`
  background-color: transparent;
  padding: ${isMobile ? '5.33vw' : '24px'};
`

export const YourAccount = styled.div`
  h5 {
    margin: 0 0 1rem 0;
    font-weight: 400;
  }

  h4 {
    margin: 0;
    font-weight: 500;
  }
`

export const LowerSection = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  padding: 0 24px 24px;
  flex-grow: 1;
  overflow: auto;
  background-color: transparent;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 20px;

  h5 {
    margin: 0;
    font-weight: 400;
  }
`

export const AccountControl = styled.div`
  display: flex;
  justify-content: start;
  min-width: 0;
  width: 100%;
  font-weight: 500;
  font-size: 1.25rem;
  a:hover {
    text-decoration: underline;
  }

  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 20px;
    color: #3b199f;
    line-height: 24px;
  }
`

export const AddressLink = styled(ExternalLink)<{ hasENS: boolean; isENS: boolean }>`
  margin-left: 1rem;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  color: #aba5bd;
  line-height: 16px;
  :hover {
    color: ${({ theme }) => theme.text2};
  }
`

export const WalletName = styled.div`
  width: initial;
  font-size: 0.825rem;
  font-weight: 500;
  color: #aba5bd;
`

export const TransactionListWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
  ${isMobile && 'max-height:200px; overflow: scroll'}
`

export const WalletAction = styled.div`
  width: fit-content;
  font-weight: 400;
  margin-left: 8px;
  font-size: 13px;
  padding: 4px 6px;
  background: #f0e2fe;
  border-radius: 8px;
  color: #6d4ec9;
  :hover {
    background: #ccc9e9;
    cursor: pointer;
    text-decoration: underline;
  }
`
