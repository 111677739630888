import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

export const TabContainer = styled.div<{ hasBorder?: boolean; padding?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ padding }) => (padding ? padding : isMobile ? '2.1vw 3.2vw' : '16px 24px')};
  border-bottom: ${({ hasBorder }) => hasBorder && '1px solid white'};
  position: relative;
  width: 100%;
  a {
    color: white !important;
  }
`

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  width: ${isMobile ? 'fit-content' : '100%'};
  height: ${isMobile ? '11vw' : '45px'};
  position: relative;
`

export const TabBox = styled.div<{ width: number; mobileWidth: number; itemNum: number }>`
  background: rgba(109, 78, 201, 0.11);
  width: ${({ mobileWidth, width, itemNum }) => `${isMobile ? mobileWidth : width * itemNum}${isMobile ? 'vw' : 'px'}`};
  position: relative;
  z-index: -2;
  border-radius: 16px;
  ${isMobile && 'left: 0'};
`

export const LineTabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ffffff;
`

export const activeClassName = 'ACTIVE'

export const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})<{ index?: number; width?: number }>`
  position: absolute;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  padding: ${isMobile ? '2vw 9vw' : '10px 33px'};
  color: #fff !important;
  transition: color linear;
  opacity: 1;
  left: ${({ index, width }) => index >= 1 && `${isMobile ? '50%' : `${index * width}px`}`};
  width: ${({ width }) => width && `${width}px`};
  text-align: center;
  &.${activeClassName} {
    opacity: 1;
    transition: color 0s;
    color: #281071 !important;
    font-size: 16px;
    font-weight: 600;
    width: ${({ width }) => width && `${width}px`};
    display: flex;
    transform: translateY(10px);
    padding: 0;
  }
`

export const ActiveText = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #281071;
  line-height: 20px;
`

export const MoveDiv = styled.div<{ translateNum?: number; width: number; mobileWidth?: number }>`
  color: #281071 !important;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgb(109 78 201 / 6%);
  border-radius: 16px;
  font-size: 16px;
  font-weight: 600;
  width: ${({ mobileWidth, width }) => `${isMobile ? mobileWidth : width}${isMobile ? 'vw' : 'px'}`};
  height: ${isMobile ? '11vw' : '45px'};
  line-height: 45px;
  text-align: center;
  position: absolute;
  transform: ${({ translateNum, width, mobileWidth }) =>
    `translateX(${translateNum * (isMobile ? mobileWidth : width)}${isMobile ? 'vw' : 'px'})`};
  transition: all 0.2s;
  z-index: -1;
`
