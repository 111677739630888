import React from 'react'
import { NavigationTab } from '../../components/NavigationTabs'
import useRoute from '../../hooks/useRoute'
import MyPosition from '../MyPosition'
import Pools from '../Pools'
import { PoolsCard } from '../../components/Card'
import { usePoolsData } from '../../hooks/usePoolsData'
import { ButtonLightGreenLink, ButtonRounded } from '../../components/Button'
import { ReactComponent as IconRefresh } from '../../assets/Bridge/icon_refresh.svg'
import { ReactComponent as IconLoading } from '../../assets/icon_loading.svg'

const PoolsInner: React.FC = () => {
  const { route } = useRoute('pools', ['Pools', 'MyPosition'])
  const { data, refetch, isLoading } = usePoolsData()

  return (
    <PoolsCard>
      <div className={'card-top'}>
        <NavigationTab
          active={route !== 'myPosition' ? 'Pools' : 'MyPosition'}
          width={204}
          mobileWidth={60}
          mobileMoveWidth={30}
          padding={'0px'}
        />
        <div className={'btn-group'}>
          <ButtonLightGreenLink to={'/add/BTC'}>Create Pool</ButtonLightGreenLink>
          <ButtonLightGreenLink to={'/add/BTC'}>+ Add Liquidity</ButtonLightGreenLink>
          <ButtonRounded
            onClick={() => {
              if (isLoading) return
              refetch()
            }}
          >
            {isLoading ? <IconLoading className={'refreshing'} /> : <IconRefresh />}
          </ButtonRounded>
        </div>
      </div>

      {route !== 'myPosition' ? <Pools data={data} /> : <MyPosition allPools={data} />}
    </PoolsCard>
  )
}

export default PoolsInner
