import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'
import Icon from '../Icon'
import IconMetamask from '../../assets/images/icon_metamask.svg'
import IconSuccess from '../../assets/images/icon_success.svg'

const Wrapper = styled.div`
  padding: 11px 12px;
  background: #e4e8f2;
  border-radius: 16px;
  border: 2px solid #ffffff;
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    margin-left: 8px;
  }
`

interface Props {
  address: string
  symbol: string
  decimals: number
  image?: string
}

const AddTokenInMetamask: React.FC<Props> = ({ address, symbol, decimals, image }: Props) => {
  const [tip, setTip] = useState<{ text: string; icon: string }>({
    text: `Add ${symbol} token to Metamask`,
    icon: IconMetamask
  })
  const addToken = useCallback(async () => {
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await (window.ethereum as any).request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address, // The address that the token is at.
            symbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals, // The number of decimals in the token
            image // A string url of the token logo
          }
        }
      })

      if (wasAdded) {
        setTip({
          text: `Added ${symbol} token`,
          icon: IconSuccess
        })
        console.log('Thanks for your interest!')
      } else {
        console.log('Your loss!')
      }
    } catch (error) {
      console.log(error)
    }
  }, [address, decimals, image, symbol])

  return (
    <Wrapper onClick={addToken}>
      <Text fontSize={14} fontWeight={600} color={'#6D4EC9'} lineHeight={'17px'}>
        {tip.text}
      </Text>
      <Icon iconSrc={tip.icon} height={24} width={24} />
    </Wrapper>
  )
}

export default React.memo(AddTokenInMetamask)
