import React, { useEffect, useMemo, useState } from 'react'
import InfoTable, { DEFAULT_SORT_OPTIONS } from './index'
import { useTransactionsData } from '../../state/info/hook'
import LinkText from './LinkText'
import { shortenPolkaAddress } from '../../helper/shorterAddress'
import { RadioWrapper, TableTitle } from './styles'
import Radio from '../Radio'
import { isMobile } from 'react-device-detect'

const columns = [
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    className: 'transaction-name'
  },
  {
    title: 'TOTAL VALUE',
    dataIndex: 'totalValue',
    key: 'totalValue',
    align: 'left',
    sortDirections: DEFAULT_SORT_OPTIONS,
    showSorterTooltip: false,
    sorter: (item0, item1) => {
      return item0.totalValueNum - item1.totalValueNum
    }
  },
  {
    title: 'TOKEN AMOUNT',
    dataIndex: 'token0Amount',
    key: 'token0Amount',
    align: 'left',
    sortDirections: DEFAULT_SORT_OPTIONS,
    showSorterTooltip: false,
    sorter: (item0, item1) => {
      return item0.token0AmountNum - item1.token0AmountNum
    }
  },
  {
    title: 'TOKEN AMOUNT',
    dataIndex: 'token1Amount',
    key: 'token1Amount',
    align: 'left',
    sortDirections: DEFAULT_SORT_OPTIONS,
    showSorterTooltip: false,
    sorter: (item0, item1) => {
      return item0.token1AmountNum - item1.token1AmountNum
    }
  },
  {
    title: 'Account',
    dataIndex: 'account',
    key: 'account',
    align: 'left'
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    align: 'left',
    sortDirections: DEFAULT_SORT_OPTIONS,
    defaultSortOrder: 'descend',
    showSorterTooltip: false,
    sorter: (item0, item1) => {
      return item0.timeStamp - item1.timeStamp
    }
  }
]

const mobileColumns = [
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    className: 'transaction-name'
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    align: 'left',
    sortDirections: DEFAULT_SORT_OPTIONS,
    defaultSortOrder: 'descend',
    showSorterTooltip: false,
    sorter: (item0, item1) => {
      return item0.timeStamp - item1.timeStamp
    }
  }
]

type transactionItem = {
  key: number
  actionType: string
  action: React.ReactNode
  totalValue: string
  token0Amount: string
  token1Amount: string
  account: React.ReactNode
  time: string
}

const radioValueMap = {
  SWAPS: 'swap',
  ADDS: 'add',
  REMOVES: 'remove'
}

const TransactionsTable: React.FC = () => {
  const { data } = useTransactionsData()
  const [transactionData, setTransactionData] = useState([])
  const [radioValue, setRadioValue] = useState<string>('ALL')

  const dataSource: transactionItem[] = useMemo(() => {
    return data?.map(item => ({
      key: item.id,
      actionType: item.actionType,
      action: <LinkText text={item.actionName} link={item.txBlockLink} />,
      totalValue: item.totalValue,
      token0Amount: item.token0Amount,
      token1Amount: item.token1Amount,
      account: <LinkText text={shortenPolkaAddress(item.account)} link={item.accountBlockLink} />,
      time: item.time,
      totalValueNum: item.totalValueNum,
      token0AmountNum: item.token0AmountNum,
      token1AmountNum: item.token1AmountNum,
      timeStamp: item.timeStamp
    }))
  }, [data])

  useEffect(() => {
    setTransactionData(dataSource?.filter(i => (radioValue === 'ALL' ? i : i.actionType === radioValueMap[radioValue])))
  }, [dataSource, radioValue])

  return (
    <>
      <TableTitle>Transactions</TableTitle>
      <RadioWrapper>
        <Radio
          onChange={e => setRadioValue(e.target.value)}
          options={['ALL', 'SWAPS', 'ADDS', 'REMOVES']}
          defaultValue={radioValue}
        />
      </RadioWrapper>
      <InfoTable
        columns={isMobile ? mobileColumns : columns}
        transactionData={transactionData}
        paginationClassName={'pagination-transactions'}
      />
    </>
  )
}

export default React.memo(TransactionsTable)
