import { useEffect, useState } from 'react'

// 适配小屏幕
export default function useDeviceSize() {
  const [isIOS, setIsIOS] = useState<boolean>(false)
  const [isAndroid, setIsAndroid] = useState<boolean>(false)
  const [, setIsSafari] = useState<boolean>(false)
  const [, setIsBrowser] = useState<boolean>(true)
  const [ua, setUa] = useState<string>('')
  const [, setIsWindows] = useState(false)
  const [miniScreen, setMiniScreen] = useState(false)
  useEffect(() => {
    ua?.match(/windows/) && setIsWindows(true)
  }, [ua])
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setUa(window.navigator.userAgent.toLowerCase())
    }
  })
  useEffect(() => {
    ua?.match(/(iphone|ipad|ipod)/) && setIsIOS(true)
    ua?.match(/android[\s\/]([\d\.]+)/) && setIsAndroid(true)
    ua?.match(/version\/([\d.]+).*safari/) && setIsSafari(true)
    ua?.match(/micromessenger/i) && setIsBrowser(false)
  }, [ua])

  function watchChangeSize() {
    const offsetWid = document.documentElement.clientWidth
    if (offsetWid <= 750) {
      setMiniScreen(true)
    } else {
      setMiniScreen(false)
    }
  }
  window.onresize = function() {
    watchChangeSize()
  }

  useEffect(() => {
    watchChangeSize()
  }, [])

  return {
    isSmallScreen: isIOS || isAndroid
  }
}
