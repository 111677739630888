import React, { useLayoutEffect, useMemo, useState } from 'react'
import { DEFAULT_PAGE_SIZE } from '../components/InfoTable'
import { ReactComponent as IconPrevious } from '../assets/images/icon_previous.svg'
import { ReactComponent as IconNext } from '../assets/images/icon_next.svg'

type PaginationOptions = {
  position: string[]
  itemRender: (_, type: 'page' | 'prev' | 'next', originalElement) => React.ReactNode
  current: number
  simple: boolean
  showTotal: (total: number, range: [number, number]) => React.ReactNode
  total: number
  onChange: (page: number, pageSize: number) => void
  className: string
}

const usePagination = (
  dataLength: number,
  className: string,
  onPageChange?: (page: number, pageSize: number) => void,
  pageSize = DEFAULT_PAGE_SIZE
): PaginationOptions => {
  const [pageNum, setPageNum] = useState(1)

  const antPaginationSimplePager = document.getElementsByClassName(className) as HTMLCollectionOf<HTMLElement>
  const totalPage = useMemo(() => {
    return Math.ceil(dataLength / pageSize) || 0
  }, [dataLength, pageSize])

  useLayoutEffect(() => {
    if (antPaginationSimplePager.length > 0) {
      for (let i = 0; i < antPaginationSimplePager[0].childNodes.length; i++) {
        if ((antPaginationSimplePager[0].childNodes[i] as any).className === 'ant-pagination-simple-pager') {
          //@ts-ignore
          antPaginationSimplePager[0].childNodes[i].innerText = `Page ${pageNum} of ${totalPage}`
        }
      }
    }
  }, [pageNum, totalPage, antPaginationSimplePager, dataLength])

  return {
    position: ['bottomCenter'],
    itemRender: (_, type: 'page' | 'prev' | 'next', originalElement) => {
      if (type === 'prev') {
        return <IconPrevious />
      }
      if (type === 'next') {
        return <IconNext />
      }
      return originalElement
    },
    current: pageNum,
    simple: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    // current: 1,
    total: dataLength,
    onChange: (page: number, pageSize: number) => {
      setPageNum(page)
      onPageChange && onPageChange(page, pageSize)
      // onPageChange(page)
    },
    className
  }
}

export default usePagination
