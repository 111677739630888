import { Currency, ETHER, Token } from 'so-swap-sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components'

// import EthereumLogo from '../../assets/images/ethereum-logo.png'
import SherpaxLogo from '../../assets/images/icon.xbtc.svg'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'
import { useBridgeIcon } from '../../hooks/useBridgeIcon'

const showDefaultIconSymbolList = ['WBTC']
const getTokenLogoURL = (address: string, symbol?: string) => {
  if (showDefaultIconSymbolList.includes(symbol)) {
    symbol = null
  }
  return `https://raw.githubusercontent.com/chainx-org/token-list/main/assets/${symbol ? symbol : 'Default'}.svg`
}

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  // box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)
  const getIcon = useBridgeIcon()

  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return []

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.address)]
      }

      return [getTokenLogoURL(currency.address, currency.symbol)]
    }
    return []
  }, [currency, uriLocations])

  if (currency === ETHER) {
    return <StyledEthereumLogo src={SherpaxLogo} size={size} style={style} />
  }

  if (currency instanceof WrappedTokenInfo && currency.symbol !== 'PCX' && !currency.tokenInfo.logoURI) {
    return getIcon(currency.symbol) as any
  }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
