import styled from 'styled-components'

export const RemovePriceInfoPanelTop = styled.div`
  background: #c9c8e4;
  border-radius: 24px 24px 0 0;
  padding: 16px;
`

export const RemovePriceInfoPanelBottom = styled.div`
  background: white;
  border-radius: 0 0 24px 24px;
  box-shadow: 0 2px 4px 0 rgba(109, 78, 201, 0.06);
  border: 1px solid #c9c8e7;
  padding: 16px;
`
