import { Text } from 'rebass'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
`

export const ClickableText = styled(Text)`
  :hover {
    cursor: pointer;
  }
  color: ${({ theme }) => theme.primary1};
`
export const MaxButton = styled.button<{ width: string; margin?: string }>`
  background: #E2FFFA;
  border-radius: 8px;
  font-size: 13px;
  line-height: 16px;
  padding: 6px 16px;
  border: none;
  outline: none;
  font-weight: 500;
  color: #38DCBF;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0.25rem 0.5rem;
  `};
  cursor: pointer;
  margin: ${({ margin }) => (margin ? margin : '0.25rem')};
  overflow: hidden;
  :hover {
    //border: 1px solid ${({ theme }) => theme.primary1};
  }
  :focus {
    //border: 1px solid ${({ theme }) => theme.primary1};
    background: #48e8cc;
    font-weight: 600;
    color: #ffffff;
  }
`

export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`

export const CardHeader = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  padding: 16px 24px;
  justify-content: space-between;
  > div:nth-child(1) {
    width: fit-content;
    border-radius: 16px;
    color: #281071;
    font-size: 20px;
    font-weight: 600;
    cursor: default;
    line-height: 24px;
  }
  > div:nth-child(2) {
    width: fit-content;
    z-index: 101;
  }
`
