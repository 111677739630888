import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import Web3Status from '../Web3Status'
import PolKaAddress from '../../components/PolkaStatus'
import Question from '../Question'
import HeaderRouter from './HeaderRouter'
import useDeviceSize from '../../hooks/useDeviceSize'
import LogoMobile from '../../assets/Mobile/logo_swap.svg'
import MobileHeaderRouter from '../MobileHeaderRouter'
import { Link, useLocation } from 'react-router-dom'
import useBridgeStore from '../../store/bridge'
import { useAccounts } from '../../hooks/AccountsProvider'
// import { ReactComponent as Logo } from '../../assets/Header/logo.svg'
import Logo from '../../assets/Header/logo.png'
import EnvelopeHeader from './EnvelopeHeader'

export const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  z-index: 2;
  padding: 8px 24px;
  overflow-x: scroll;
`

export const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled(Link)`
  display: flex;
  align-items: center;
  pointer-events: auto;

  :hover {
    cursor: pointer;
  }
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 16px;
  white-space: nowrap;
  margin-right: 12px;
  width: 100%;

  :focus {
    border: 1px solid blue;
  }
`

const PolkaAccountElement = styled.div<{ active: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 16px;
  white-space: nowrap;
  justify-content: flex-end;
  :focus {
    border: 1px solid blue;
  }
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const HeaderWarning = styled.div`
  background: #7569e5;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  img {
    margin: 0 12px;
  }
`

export default function Header(): React.ReactElement {
  const { account } = useActiveWeb3React()
  const { isSmallScreen } = useDeviceSize()
  const { btcHistoryTxList, updateHistoryStatus } = useBridgeStore()
  const { currentAccount } = useAccounts()
  const { pathname } = useLocation()
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null)

  // useEffect(() => {
  //   const historyList = btcHistoryTxList[currentAccount?.btcAddress]
  //
  //   if (historyList && historyList.length > 0) {
  //     const unCompletedList = historyList.filter(i => !i.status)
  //
  //     const unCompletedHashes = unCompletedList.map(i => i.hash).filter(i => Boolean(i))
  //     if (unCompletedList?.length <= 0) return
  //     if (intervalIdRef.current) {
  //       clearInterval(intervalIdRef.current) // 清除旧的定时器
  //     }
  //
  //     // updateHistoryTxItem(unCompletedHashes, currentAccount.btcAddress)
  //
  //     intervalIdRef.current = setInterval(() => updateHistoryStatus(unCompletedHashes, currentAccount.btcAddress), 5000)
  //   }
  //
  //   return () => clearInterval(intervalIdRef.current)
  // }, [currentAccount.btcAddress, btcHistoryTxList])

  const HeaderMap = {
    '/envelope': <EnvelopeHeader />
  }

  return (
    <>
      {HeaderMap[pathname] ?? (
        <HeaderFrame>
          <HeaderElement>
            <Title to={'/swap'}>
              {isSmallScreen ? (
                <img src={LogoMobile} alt="logo" style={{ marginRight: '20px', width: '7.4648vw' }} />
              ) : (
                <img height={42} width={'auto'} src={Logo} alt="logo" />
              )}
            </Title>
            {!isSmallScreen && <HeaderRouter />}
          </HeaderElement>
          <HeaderControls>
            <HeaderElement>
              <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
                <Web3Status />
              </AccountElement>
              {/*<PolkaAccountElement active={true} style={{ pointerEvents: 'auto' }}>*/}
              {/*  <PolKaAddress />*/}
              {/*</PolkaAccountElement>*/}
            </HeaderElement>
            {isSmallScreen ? <MobileHeaderRouter /> : <Question />}
          </HeaderControls>
        </HeaderFrame>
      )}

      {/*<HeaderWarning>*/}
      {/*  <Icon iconSrc={IconWarning} width={24} height={24} />*/}
      {/*  <Text fontWeight={500} fontSize={14} lineHeight={'20px'} color={'#FFFFFF'}>*/}
      {/*    SherpaX network will halt operations as of July 1. We urge all users to arrange for the management of their*/}
      {/*    on-chain assets independently. In the meantime, we are excited to announce that SoSwap is preparing to deploy*/}
      {/*    on ChainX 5.0, marking the beginning of a new era*/}
      {/*  </Text>*/}
      {/*</HeaderWarning>*/}
    </>
  )
}
