import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { MoveDiv, StyledNavLink, TabBox, TabContainer, Tabs } from '../../components/NavigationTabs/styles'
import AppBody from '../AppBody'
import HistoryTable from '../../components/Table/HistoryTable'
import { useReceivedRedEnvelopData, useSendRedEnvelopData } from '../../hooks/useRedEnvelopData'
import styled from 'styled-components'
import { Typography } from 'antd'
import { promotionWritingsEnUs, promotionWritingsZhCn, SUPPORTED_TOKENS } from '../../constants/redEnvelope'
import { useBridgeIcon } from '../../hooks/useBridgeIcon'
import IconMetamask from '../../assets/images/icon_metamask.svg'
import { useAddToMetamask } from '../../hooks/useAddToMetamask'
import { useSelectedTokenList } from '../../state/lists/hooks'
import { SupportedChainId } from '../../constants/chains'

const AddToken = styled.div`
  cursor: pointer;
  width: fit-content;
  margin: 0 auto;
  img {
    width: 24px;
    height: 24px;
  }
`

const { Paragraph } = Typography

const listItems = [
  { title: 'Send', route: 'send-list' },
  { title: 'Received', route: 'received-list' }
]

const sendColumns = [
  {
    title: 'Envelope Greeting',
    dataIndex: 'words',
    key: 'words',
    align: 'left',
    width: 180,
    ellipsis: true
  },
  {
    title: 'Red Envelope Amount',
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
    width: 320
  },
  {
    title: 'Number of Recipients',
    dataIndex: 'recipients',
    key: 'recipients',
    align: 'center',
    width: 150
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    align: 'center'
  },
  {
    title: 'Share',
    dataIndex: 'share',
    key: 'share',
    align: 'center',
    width: 80
  }
]

const receivedColumns = [
  {
    title: 'Envelope Greeting',
    dataIndex: 'words',
    key: 'words',
    align: 'left',
    ellipsis: true,
    width: 180
  },
  {
    title: 'Red Envelope Amount',
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
    width: 320
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    width: 100
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    align: 'center'
  },
  {
    title: 'Add to Metamask',
    dataIndex: 'add',
    key: 'add',
    align: 'center'
  }
]
const WordsWrapper = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
`

export const HistoryList: React.FC = () => {
  const { pathname, search } = useLocation()
  const { push } = useHistory()
  const [num, setNum] = useState(0)
  const { data: receivedData, loading: loadingReceived, refetch: refetchReceivedData } = useReceivedRedEnvelopData()
  const { data: sendData, loading: loadingSend, refetch: refetchSendData } = useSendRedEnvelopData()
  const getIcon = useBridgeIcon()
  const addToMetamask = useAddToMetamask()
  const allTokens = useSelectedTokenList()

  useEffect(() => {
    if (search) {
      const isReceive = search.includes('received-list')
      setNum(isReceive ? 1 : 0)
    }
  }, [])

  return (
    <AppBody maxWidth={'980px'} style={{ marginTop: '16px' }}>
      <TabContainer>
        <Tabs>
          <TabBox itemNum={2} width={151} mobileWidth={60} />
          {listItems?.map((item, index) => {
            return (
              <StyledNavLink
                key={item.title}
                to={`${pathname}?${item.route}`}
                isActive={() => (search.slice(1) !== 'received-list' ? 'send-list' : 'received-list') === item.route}
                onClick={() => {
                  setNum(index)
                  if (search.slice(1) !== 'received-list') {
                    refetchReceivedData()
                  } else {
                    refetchSendData()
                  }
                }}
                width={151}
                index={index}
              >
                {item.title}
              </StyledNavLink>
            )
          })}
          <MoveDiv translateNum={num} width={151} mobileWidth={60} />
        </Tabs>
      </TabContainer>

      {search.slice(1) === 'received-list' ? (
        <HistoryTable
          loading={loadingReceived}
          data={receivedData.map(i => ({
            ...i,
            words: (
              <WordsWrapper>
                {getIcon(i.symbol)}
                {i.words}
              </WordsWrapper>
            ),
            add: (
              <AddToken
                onClick={() => {
                  const tokenInfo = Object.values(allTokens[SupportedChainId.CHAINX] || {}).find(
                    token => i.symbol === token.symbol
                  )
                  if (!tokenInfo) return
                  addToMetamask(tokenInfo.address, tokenInfo.symbol, tokenInfo.decimals, tokenInfo.tokenInfo.logoURI)
                }}
              >
                <img src={IconMetamask} alt='' />
              </AddToken>
            )
          }))}
          columns={receivedColumns}
        />
      ) : (
        <HistoryTable
          loading={loadingSend}
          data={sendData.map(i => ({
            ...i,
            words: (
              <WordsWrapper>
                {getIcon(i.symbol)}
                {i.words}
              </WordsWrapper>
            ),
            share: (
              <Paragraph
                style={{ margin: '0' }}
                copyable={{
                  text: navigator.language.includes('zh')
                    ? `${promotionWritingsZhCn}${i.share}`
                    : `${promotionWritingsEnUs}${i.share}`,
                  tooltips: ['Copy Url', 'Copied']
                }}
              />
            )
          }))}
          columns={sendColumns}
        />
      )}
    </AppBody>
  )
}
