import React, { useCallback } from 'react'
import { AddressLinkWrapper } from './styles'
import { isMobile } from 'react-device-detect'

interface Props {
  text: string
  link: string
}

const LinkText: React.FC<Props> = ({ text, link }: Props) => {
  const _onClick = useCallback(() => {
    window.open(link, isMobile ? '_self' : '_blank')
  }, [link])

  return <AddressLinkWrapper onClick={_onClick}>{text}</AddressLinkWrapper>
}

export default React.memo(LinkText)
