import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import { abi as IUniswapV2Router02ABI } from '@uniswap/v2-periphery/build/IUniswapV2Router02.json'
import {
  GRAB_ADDRESS,
  MINI_PCX_STAKING_ADDRESS,
  PCX_SO_STAKING_ADDRESS,
  ROUTER_ADDRESS,
  SBTC_PCX_STAKING_ADDRESS,
  SOSWAP_STAKING_ADDRESS
} from '../constants'
import { ChainId, JSBI, Percent, Token, CurrencyAmount, Currency, ETHER } from 'so-swap-sdk'
import { TokenAddressMap } from '../state/lists/hooks'
import STAKING_ABI from '../constants/abis/staking.json'
import SOSWAP_STAKING_ABI from '../constants/abis/soswap_staking.json'
import GRAB_ABI from '../constants/abis/grab.json'
import { MempoolExplorer, OrdiscanExplorer } from '../constants/explorer'
import { chainxBridgeAbi, CHAINX_BRIDGE_ADDRESS } from '../constants/btc'
import { ERC20_ABI } from '../constants/abis/erc20'
import { bevmBridgeChainxAbi, BEVM_ADDRESS } from '../constants/bevmToChainx';
import { BTC_ADDRESS, claimBTCAbi } from '../constants/claimBTC';

export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

const ETHERSCAN_PREFIXES: { [chainId in ChainId]: string } = {
  1: 'etherscan.io',
  3: 'ropsten.etherscan.io',
  4: 'rinkeby.etherscan.io',
  5: 'goerli.etherscan.io',
  42: 'kovan.etherscan.io',
  80001: 'explorer-mumbai.maticvigil.com',
  // 1501: 'evm.chainx.org',
  // 1506: 'evm.sherpax.io',
  1502: 'scan-canary-testnet.bevm.io',
  56: 'www.bscscan.com',
  97: 'www.testnet.bscscan.com',
  [ChainId.CHAINX]: 'scan-canary.bevm.io'
}

export function getEtherscanLink(
  chainId: ChainId,
  data: string,
  type: 'transaction' | 'token' | 'address' | 'bridge'
): string {
  const prefix = `https://${ETHERSCAN_PREFIXES[chainId]}`

  switch (type) {
    case 'bridge': {
      return `${prefix}/stats`
    }
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'address': {
      return `${prefix}/address/${data}`
    }
    default: {
      return `${prefix}/account/${data}`
    }
  }
}

export const getMempoolExplorerLink = (data: string, type: 'transaction' | 'address'): string => {
  switch (type) {
    case 'transaction':
      return `${MempoolExplorer}/tx/${data}`
    case 'address':
      return `${MempoolExplorer}/address/${data}`
    default:
      return `${MempoolExplorer}/address/${data}`
  }
}

export const getOrdiscanLink = (data: string, type: 'transaction' | 'address'): string => {
  switch (type) {
    case 'transaction':
      return `${OrdiscanExplorer}/tx/${data}`
    case 'address':
      return `${OrdiscanExplorer}/address/${data}`
    default:
      return `${OrdiscanExplorer}/address/${data}`
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!address) return ''
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(40000))).div(BigNumber.from(10000))
}

export function calculateOriginalGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

export const getMaxFeeGas = (): { maxFeePerGas: BigNumber; maxPriorityFeePerGas: BigNumber } => {
  return {
    maxFeePerGas: BigNumber.from(5).mul(Math.pow(10, 7)),
    maxPriorityFeePerGas: BigNumber.from(0)
  }
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000))
  ]
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

// account is optional
export function getRouterContract(_: number, library: Web3Provider, account?: string): Contract {
  return getContract(ROUTER_ADDRESS, IUniswapV2Router02ABI, library, account)
}

export function getMiniPcxStakingContract(_: number, library: Web3Provider, account?: string): Contract {
  return getContract(MINI_PCX_STAKING_ADDRESS, STAKING_ABI, library, account)
}

export function getSbtcPcxStakingContract(_: number, library: Web3Provider, account?: string): Contract {
  return getContract(SBTC_PCX_STAKING_ADDRESS, STAKING_ABI, library, account)
}

export function getPcxSoStakingContract(_: number, library: Web3Provider, account?: string): Contract {
  return getContract(PCX_SO_STAKING_ADDRESS, STAKING_ABI, library, account)
}

export function getSoSwapStakingContract(_: number, library: Web3Provider, account?: string): Contract {
  return getContract(SOSWAP_STAKING_ADDRESS, SOSWAP_STAKING_ABI, library, account)
}

export function getGrabContract(_: number, library: Web3Provider, account?: string): Contract {
  if (!GRAB_ABI || !library || !account) return
  return getContract(GRAB_ADDRESS, GRAB_ABI, library, account)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency === ETHER) return true
  return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address])
}

export const getChainxBridgeContract = (library: Web3Provider, account: string): Contract => {
  return getContract(CHAINX_BRIDGE_ADDRESS, chainxBridgeAbi, library, account)
}

export const getTokenContract = (tokenAddress: string, library: Web3Provider, account?: string): Contract => {
  return getContract(tokenAddress, ERC20_ABI, library, account)
}

export const getBevmToChainxBridgeContract = (library: Web3Provider, account: string): Contract => {
  return getContract(BEVM_ADDRESS, bevmBridgeChainxAbi, library, account)
}

export const getClaimBTCContract = (library: Web3Provider, account: string): Contract => {
  return getContract(BTC_ADDRESS, claimBTCAbi, library, account)
}
