import { Currency, Pair } from 'so-swap-sdk'
import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import NumericalInput from '../NumericalInput'
import { PanenlTitle } from './panel'
import { RowBetween } from '../Row'
import ShowSelectedBtn from '../swap/showSelectedBtn'
import CurrencySearchDialog from '../Dialog/CurrencySearchDialog'
import BalanceMax from '../BalanceMax'
import { isMobile } from 'react-device-detect'
import { useTokensListData } from '../../state/info/hook'
import BigNumber from 'bignumber.js'

const InputRow = styled.div<{ selected: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.75rem 1rem 0.75rem 1rem;
  ${isMobile && 'padding:2.6vw 3.1vw 4.8vw 3.1vw'};
`

export const CurrencySelect = styled.button<{ purpleSelect: string }>`
  align-items: center;
  height: 2.2rem;
  font-size: 20px;
  font-weight: 500;
  background-color: #f5f6f9;
  border-radius: 16px;
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  padding: 0 0.5rem;

  :focus,
  :hover {
    background-color: ${({ purpleSelect }) => (purpleSelect ? purpleSelect : '#EAEAF3')};
  }
`

const LabelRow = styled.div`
  align-items: center;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0 1rem;
  ${isMobile && 'padding:2.6vw 3.1vw 0 3.1vw'};
  span:hover {
    cursor: pointer;
  }
`

const InputPanel = styled.div<{ hideInput?: boolean }>``

const Container = styled.div<{ hideInput: boolean }>`
  border-radius: 20px;
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
}

export default function PanelCard({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label = 'Input',
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null,
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases
}: CurrencyInputPanelProps) {
  const [modalOpen, setModalOpen] = useState(false)
  const { account } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const { data: tokensData } = useTokensListData(false)
  const currencyPriceUsd = (tokensData || [])?.find(token => token.symbol === currency?.symbol)?.priceNum
  const currencyValueUsd =
    currencyPriceUsd && value ? new BigNumber(currencyPriceUsd).multipliedBy(value || 0).toFixed(4) : '0'

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  return (
    <InputPanel id={id}>
      <Container hideInput={hideInput}>
        {!hideInput && (
          <LabelRow>
            <RowBetween>
              {account && (
                <BalanceMax
                  balanceValue={
                    !hideBalance && !!currency && selectedCurrencyBalance && selectedCurrencyBalance?.toSignificant(6)
                  }
                  balanceOnMax={onMax}
                  transferAll={showMaxButton}
                />
              )}
              {/*<PanenlTitle>{label}</PanenlTitle>*/}
              <PanenlTitle>$ {currencyValueUsd}</PanenlTitle>
            </RowBetween>
          </LabelRow>
        )}
        <InputRow selected={disableCurrencySelect}>
          <ShowSelectedBtn
            showOptionsList={true}
            disableCurrencySelect={disableCurrencySelect}
            onClick={() => {
              if (!disableCurrencySelect) {
                setModalOpen(true)
              }
            }}
            pair={pair}
            currency={currency}
            backgroundPurple={!(currency && currency.symbol)}
            currencySymbol={currency && currency.symbol}
          />
          {!hideInput && (
            <>
              <NumericalInput
                className="token-amount-input"
                value={value}
                align={'right'}
                onUserInput={val => {
                  onUserInput(val)
                }}
                style={{ maxWidth: '270px' }}
              />
            </>
          )}
        </InputRow>
      </Container>
      {!disableCurrencySelect && onCurrencySelect && (
        <CurrencySearchDialog
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
        />
      )}
    </InputPanel>
  )
}
