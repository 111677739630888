import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { PoolsCard } from '../../components/Card'
import Earning from './Earning'
import { useCurrency } from '../../hooks/Tokens'
import { useDerivedBurnInfo } from '../../state/burn/hooks'
import { PoolItem, usePoolsData } from '../../hooks/usePoolsData'
import { ReactComponent as IconBack } from '../../assets/images/icon_arrow_left.svg'
import { ButtonLightGreenLink, ButtonRounded } from '../../components/Button'
import { ReactComponent as IconRefresh } from '../../assets/Bridge/icon_refresh.svg'
import { ReactComponent as IconLoading } from '../../assets/icon_loading.svg'
import PoolStats from './PoolStats'
import PositionChange from './PositionChange'
import { Container, Header } from './styles'
import { Spin } from 'antd'
import useSWR from 'swr'
import { CHAINX_EVM_INFO_API, TokenListData, useTokensListData } from '../../state/info/hook'
import { useActiveWeb3React } from '../../hooks'
import httpRequest from '../../utils/httpRequest'
import BigNumber from 'bignumber.js'

const poolChangedFetcher = async ([url, tokensData, currentPool, [token0Decimal, token1Decimal]]: [
  string,
  TokenListData[],
  PoolItem,
  number[]
]) => {
  const { data } = await httpRequest.get(url)

  const formatData = (data?.data || []).map(i => {
    const token0Info = tokensData.find(
      token =>
        currentPool.tokenA.toLowerCase() === token.symbol.toLowerCase() ||
        currentPool.tokenA.toLowerCase() === token.address.toLowerCase()
    )
    const token1Info = tokensData.find(
      token =>
        currentPool.tokenB.toLowerCase() === token.symbol.toLowerCase() ||
        currentPool.tokenB.toLowerCase() === token.address.toLowerCase()
    )

    const token0Asset = new BigNumber(parseInt(i.currentAmount0)).dividedBy(Math.pow(10, token0Decimal)).toNumber()
    const token1Asset = new BigNumber(parseInt(i.currentAmount1)).dividedBy(Math.pow(10, token1Decimal)).toNumber()

    let token0Changed = 0
    let token1Changed = 0
    if (i.blockTime === 0) {
      const lastDataCurrentAmount0 = parseInt(data?.data?.[1]?.currentAmount0) || 0
      const lastDataCurrentAmount1 = parseInt(data?.data?.[1]?.currentAmount1) || 0
      token0Changed = new BigNumber(parseInt(i.currentAmount0))
        .minus(lastDataCurrentAmount0)
        .dividedBy(Math.pow(10, token0Decimal))
        .toNumber()
      token1Changed = new BigNumber(parseInt(i.currentAmount1))
        .minus(lastDataCurrentAmount1)
        .dividedBy(Math.pow(10, token1Decimal))
        .toNumber()
    } else {
      token0Changed = new BigNumber(parseInt(i.changeAmount0)).dividedBy(Math.pow(10, token0Decimal)).toNumber()
      token1Changed = new BigNumber(parseInt(i.changeAmount1)).dividedBy(Math.pow(10, token1Decimal)).toNumber()
    }

    const token0ValueUsd = new BigNumber(token0Asset).multipliedBy(token0Info?.priceNum).toNumber()
    const token1ValueUsd = new BigNumber(token1Asset).multipliedBy(token1Info?.priceNum).toNumber()

    const totalValueUsd = new BigNumber(token0ValueUsd).plus(token1ValueUsd).toNumber()

    const token0PriceUsd = new BigNumber(i.usdAmount)
      .dividedBy(2)
      .dividedBy(Math.pow(10, 18))
      .dividedBy(i.operatedToken0Amount)
      .multipliedBy(Math.pow(10, token0Decimal))
      .toNumber()
    const token1PriceUsd = new BigNumber(i.usdAmount)
      .dividedBy(2)
      .dividedBy(Math.pow(10, 18))
      .dividedBy(i.operatedToken1Amount)
      .multipliedBy(Math.pow(10, token1Decimal))
      .toNumber()
    return {
      timestamp: i.blockTime,
      token0Symbol: token0Info?.symbol,
      token1Symbol: token1Info?.symbol,
      token0PriceUsd: i.blockTime === 0 ? token0Info?.priceNum : token0PriceUsd,
      token1PriceUsd: i.blockTime === 0 ? token1Info?.priceNum : token1PriceUsd,
      token0Asset,
      token1Asset,
      token0Changed,
      token1Changed,
      totalValueUsd
    }
  })
  return { data: formatData, total: data?.total }
}

const PoolDetails = ({
  match: {
    params: { currencyIdA, currencyIdB }
  }
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>): React.ReactElement => {
  const { account } = useActiveWeb3React()
  const currencyA = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)
  const { pair } = useDerivedBurnInfo(currencyA ?? undefined, currencyB ?? undefined)
  const { data: poolsData, isLoading: loadingPoolsData, refetch: refetchPoolsData } = usePoolsData()
  const currentPool = (poolsData || []).find(i => {
    const poolName = i.poolName.toLowerCase()
    return poolName.includes(currencyA?.symbol?.toLowerCase()) && poolName.includes(currencyB?.symbol?.toLowerCase())
  })

  const { data: tokensData, refetch: refetchTokens, loading: loadingTokensData } = useTokensListData(false)
  const [pageIndex, setPageIndex] = useState(0)

  const {
    data: poolChangedData,
    isLoading: loadingPoolChangedData,
    isValidating: validatingPoolChangedData,
    mutate: refetchPoolChangedData
  } = useSWR(
    pair?.liquidityToken?.address && tokensData && currentPool && pair?.token0?.decimals && pair?.token1?.decimals
      ? [
          `${CHAINX_EVM_INFO_API}/history/liquidity?pairAddress=${pair?.liquidityToken?.address}&account=${account}&page=${pageIndex}&pageSize=3`,
          tokensData,
          currentPool,
          [pair.token0.decimals, pair.token1.decimals]
        ]
      : null,
    poolChangedFetcher,
    {
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      refreshWhenOffline: false
    }
  )

  return (
    <PoolsCard style={{ minWidth: '700px' }}>
      <Header>
        <ButtonLightGreenLink to={'/pools/myPosition'}>
          <IconBack style={{ marginRight: '8px' }} />
          Back
        </ButtonLightGreenLink>
        <div className={'action-group'}>
          <ButtonLightGreenLink to={`/remove/${currencyIdA}/${currencyIdB}`}>Remove</ButtonLightGreenLink>
          <ButtonLightGreenLink to={`/add/${currencyIdA}/${currencyIdB}`}>Increase</ButtonLightGreenLink>
          <ButtonRounded
            onClick={async () => {
              if (loadingTokensData || loadingPoolsData || loadingPoolChangedData || validatingPoolChangedData) return
              await refetchPoolsData()
              await refetchTokens()
              await refetchPoolChangedData()
            }}
          >
            {loadingTokensData || loadingPoolsData || loadingPoolChangedData || validatingPoolChangedData ? (
              <IconLoading className={'refreshing'} />
            ) : (
              <IconRefresh />
            )}
          </ButtonRounded>
        </div>
      </Header>
      <Container>
        <Spin style={{ padding: '24px' }} spinning={!pair}>
          {pair && <Earning pair={pair} liquidityUsdValue={currentPool?.liquidityUsd ?? 0} />}
          {pair && (
            <PositionChange
              pair={pair}
              data={poolChangedData?.data}
              fetchPage={(page: number) => setPageIndex(page)}
              total={poolChangedData?.total}
              currentPage={pageIndex}
              loading={validatingPoolChangedData || loadingPoolChangedData}
            />
          )}
          {pair && <PoolStats pair={pair} currentPool={currentPool} />}
        </Spin>
      </Container>
    </PoolsCard>
  )
}

export default React.memo(PoolDetails)
