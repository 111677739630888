import { create } from 'zustand'
import { persist } from 'zustand/middleware'

const LOCAL_NAME = 'BEVM_RED_ENVELOPE_IDS_V1'

export type RedEnvelopeHistoryItem = {
  timestamp: number
  id: string
}

interface RedEnvelopeIdsStore {
  receivedIds: Record<string, RedEnvelopeHistoryItem[]>
  sendIds: Record<string, RedEnvelopeHistoryItem[]>
  setReceivedIds: (account: string, ids: RedEnvelopeHistoryItem[]) => void
  setSendIds: (account: string, ids: RedEnvelopeHistoryItem[]) => void
}

const useRedEnvelopeIdsStore = create<RedEnvelopeIdsStore>()(
  persist(
    (set, get) => ({
      receivedIds: {},
      sendIds: {},
      setReceivedIds: (account, ids) => {
        const cacheIds = get().receivedIds[account] || []
        set(() => ({
          receivedIds: {
            ...get().receivedIds,
            [account]: [...ids, ...cacheIds]
          }
        }))
      },
      setSendIds: (account, ids) => {
        const cacheIds = get().sendIds[account] || []
        set(() => ({
          sendIds: {
            ...get().sendIds,
            [account]: [...ids, ...cacheIds]
          }
        }))
      }
    }),
    { name: LOCAL_NAME, getStorage: () => localStorage }
  )
)

export default useRedEnvelopeIdsStore
