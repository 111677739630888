import { transformBigNumber } from './transformBigNumber'

export const formatPrice = (amount: string | number, decimals: number) => {
  const transformedAmount = transformBigNumber(amount, decimals, 0)

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  if (Number(transformedAmount) >= 1000) {
    return (Number(transformedAmount) / 1000).toFixed(0).replace(rx, '$1') + 'k'
  }
  return transformedAmount
}

export const formatInfoTablePrice = (num: number): string => {
  if (num < 0.01 && num > 0) return '< 0.01'
  if (!num.toString().includes('.')) {
    return num.toFixed(2)
  }
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' }
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }
  return (num / si[i].value).toFixed(2).replace(rx, '$1') + si[i].symbol
}
