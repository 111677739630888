import React from 'react'
import styled, { keyframes } from 'styled-components'
import { darken, lighten } from 'polished'

import { RowBetween } from '../Row'
import { ChevronDown } from 'react-feather'
import { Button as RebassButton, ButtonProps } from 'rebass/styled-components'
import { Link } from 'react-router-dom'
import IconArrow from '../../assets/images/icon_arrow_down.webp'
import Icon from '../Icon'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
const Base = styled(RebassButton)<{
  padding?: string
  width?: string
  borderRadius?: string
  altDisabledStyle?: boolean
}>`
  padding: ${({ padding }) => (padding ? padding : '16px')};
  width: ${({ width }) => (width ? width : '100%')};
  font-weight: 500;
  text-align: center;
  border-radius: 12px;
  border-radius: ${({ borderRadius }) => borderRadius && borderRadius};
  outline: none;
  border: 1px solid transparent;
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    cursor: auto;
  }

  > * {
    user-select: none;
  }
  > div {
    line-height: 24px;
  }
`

export const ButtonPrimary = styled(Base)<{ borderRadius?: string }>`
  background-color: #48e8cc;
  color: white;
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '24px')};
  box-shadow: 0 2px 4px 0 rgba(78, 91, 201, 0.12), inset 0 0 5px 0 rgba(255, 255, 255, 0.5);
  font-size: 20px;
  font-weight: 600;

  img {
    margin-right: 12px;
  }

  &.enable {
    img {
      width: 100%;
      height: 100%;
      margin-right: 0;
    }
    div {
      line-height: 20px;
    }
  }

  &:hover,
  &:active {
    color: white;
    background: #41d2b8;
    box-shadow: 0 2px 4px 0 rgba(78, 91, 201, 0.12), inset 0 0 5px 0 rgba(255, 255, 255, 0.5);
  }
  &:disabled {
    color: white;
    cursor: auto;
    border: 1px solid rgba(77, 43, 178, 0.08);
    outline: none;
    opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '0.7' : '1')};
    background: #d4d9f0;
    box-shadow: 0 2px 4px 0 rgba(78, 91, 201, 0.12), inset 0 0 5px 0 rgba(255, 255, 255, 0.5);
  }
  > span {
    margin: 0;
    min-width: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  svg {
    &.loading {
      width: 20px;
      height: 20px;
      animation: 1s ${rotate} linear infinite;
      margin-right: 6px;
    }
  }
`

export const ButtonLight = styled(Base)`
  background: #e2fffa;
  color: #298a9a;
  font-size: 20px;
  font-weight: 600;
  border-radius: 24px;
  border: 1px solid rgba(72, 232, 204, 0.57);
  box-shadow: 0px 2px 4px 0px rgba(78, 91, 201, 0.12), inset 0px 0px 5px 0px rgba(255, 255, 255, 0.5);
  &:active,
  &:hover,
  &:focus {
    background: #b5dcdc;
    color: #298a9a !important;
    border-radius: 24px;
    border: 1px solid #ffffff;
  }
  :disabled {
    opacity: 0.4;
    :hover {
      cursor: auto;
      background-color: ${({ theme }) => theme.primary5};
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`

export const ButtonGray = styled(Base)`
  background-color: ${({ theme }) => theme.bg3};
  color: ${({ theme }) => theme.text2};
  font-size: 16px;
  font-weight: 500;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg2)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg2)};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg2)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.1, theme.bg2)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.1, theme.bg2)};
  }
`

export const ButtonSecondary = styled(Base)`
  background-color: rgba(247, 67, 84, 0.15);
  color: ${({ theme }) => theme.primaryText1};
  font-size: 16px;
  border-radius: 8px;
  padding: ${({ padding }) => (padding ? padding : '10px')};

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.primary4};
    background-color: ${({ theme }) => theme.primary4};
  }
  &:hover {
    background-color: ${({ theme }) => theme.primary4};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.primary4};
    background-color: ${({ theme }) => theme.primary4};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.primary5};
    opacity: 50%;
    cursor: auto;
  }

  &.my-liquidity {
    background: #b5adff;
    border-radius: 16px;
    color: white;

    &:focus,
    &:hover,
    &:active {
      opacity: 0.7;
      box-shadow: none;
    }
  }
`

export const ButtonGreen = styled(Base)`
  background: #48e8cc;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  line-height: 20px;
  border-radius: 20px;
`

export const ButtonPink = styled(Base)`
  background-color: ${({ theme }) => theme.primary1};
  color: white;

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.primary1)};
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.primary1)};
    background-color: ${({ theme }) => darken(0.1, theme.primary1)};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.primary1};
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonOutlined = styled(Base)`
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(109, 78, 201, 0.06);
  border-radius: 24px;
  color: #281071;

  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonEmpty = styled(Base)`
  background-color: transparent;
  color: ${({ theme }) => theme.primary1};
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    background-color: ${({ theme }) => theme.advancedBG};
  }
  &:hover {
    background-color: ${({ theme }) => theme.advancedBG};
  }
  &:active {
    background-color: ${({ theme }) => theme.advancedBG};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonWhite = styled(Base)`
  border: 1px solid #edeef2;
  background-color: ${({ theme }) => theme.bg1};
  color: black;

  &:focus {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    box-shadow: 0 0 0 1pt ${darken(0.05, '#edeef2')};
  }
  &:hover {
    box-shadow: 0 0 0 1pt ${darken(0.1, '#edeef2')};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${darken(0.1, '#edeef2')};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonPurple = styled(Base)`
  padding: 6px;
  background-color: #f0e2fe;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 500;
  color: #6d4ec9;
  line-height: 16px;
`

export const ButtonLightGreen = styled(Base)<{ width?: string; borderRadius?: string }>`
  background: #e2fffa;
  box-shadow: 0 2px 4px 0 rgba(57, 213, 186, 0.12), inset 0 0 5px 0 rgba(255, 255, 255, 0.5);
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '16px')};
  border: 1px solid rgba(72, 232, 204, 0.57);
  line-height: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #38dcbf;
  width: ${({ width }) => width && width};

  &:hover,
  &:active {
    background: #c7e8e6;
    border-radius: 16px;
  }
`

const ButtonConfirmedStyle = styled(Base)`
  background-color: ${({ theme }) => lighten(0.5, theme.green1)};
  color: ${({ theme }) => theme.green1};
  border: 1px solid ${({ theme }) => theme.green1};
  border-radius: 24px;

  &:disabled {
    cursor: auto;
    background: #d4d9f0;
    box-shadow: 0 2px 4px 0 rgba(78, 91, 201, 0.12), inset 0 0 5px 0 rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(77, 43, 178, 0.08);
  }
`

export const ButtonLightGreenLink = styled(Link)`
  background: rgba(255, 255, 255, 0.23);
  box-shadow: 0 2px 4px 0 rgba(109, 78, 201, 0.06);
  border-radius: 8px;
  border: 2px solid #ffffff;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #3b199f;
  line-height: 17px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  &:hover {
    color: #3b199f;
  }
`

export const ButtonRounded = styled.div`
  width: 32px;
  height: 32px;
  background: rgba(255, 255, 255, 0.23);
  box-shadow: 0 2px 4px 0 rgba(109, 78, 201, 0.06);
  border-radius: 16px;
  border: 1px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .refreshing {
    animation: 0.75s ${rotate} linear infinite;
    width: 20px;
    height: 20px;
    path {
      fill: #7be5cd;
    }
  }
`

export const RemoveButtonLink = styled(Link)`
  background: #b5adff;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  line-height: 17px;
  &:hover {
    color: #ffffff;
  }
`

const ButtonErrorStyle = styled(Base)`
  background-color: ${({ theme }) => '#E93E5E'};
  border: 1px solid ${({ theme }) => '#E93E5E'};
  border-radius: 24px;

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, '#E93E5E')};
    background-color: ${({ theme }) => darken(0.05, '#E93E5E')};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, '#E93E5E')};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, '#E93E5E')};
    background-color: ${({ theme }) => darken(0.1, '#E93E5E')};
  }
  &:disabled {
    cursor: auto;
    background: #d4d9f0;
    box-shadow: 0 2px 4px 0 rgba(78, 91, 201, 0.12), inset 0 0 5px 0 rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(77, 43, 178, 0.08);
  }
`

export const ButtonNext = styled(RebassButton)`
  background: #48e8cc;
  box-shadow: 0 2px 4px 0 rgba(78, 91, 201, 0.12);
  border-radius: 20px;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 124px;
  cursor: pointer;
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f6f9;
  border-radius: 12px;
  padding: 11px 12px;
`

export function ButtonConfirmed({
  confirmed,
  altDisabledStyle,
  ...rest
}: { confirmed?: boolean; altDisabledStyle?: boolean } & ButtonProps) {
  if (confirmed) {
    return <ButtonConfirmedStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} altDisabledStyle={altDisabledStyle} />
  }
}

export function ButtonError({ error, ...rest }: { error?: boolean } & ButtonProps) {
  if (error) {
    return <ButtonErrorStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} />
  }
}

export function ButtonDropdown({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonPrimary {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonPrimary>
  )
}

export function ButtonDropdownLight({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonOutlined {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ArrowWrapper>
          <Icon iconSrc={IconArrow} width={16} height={16} />
        </ArrowWrapper>
      </RowBetween>
    </ButtonOutlined>
  )
}

export function ButtonRadio({ active, ...rest }: { active?: boolean } & ButtonProps) {
  if (!active) {
    return <ButtonWhite {...rest} />
  } else {
    return <ButtonPrimary {...rest} />
  }
}
