import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 24px 24px 24px;
  height: fit-content;
  width: 100%;
`

interface Props {
  topBox?: React.ReactNode
  bottomBox?: React.ReactNode
  operatorBox?: React.ReactNode
}

export default function SwapModalContainer({ topBox, bottomBox, operatorBox }: Props) {
  return (
    <Container>
      {topBox}
      {bottomBox}
      {operatorBox}
    </Container>
  )
}
