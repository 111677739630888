import React, { useContext } from 'react'
import { AccountControl, AccountGroupingRow, AddressLink } from '../PolkaAccountDetail/style'
import Copy from '../AccountDetails/Copy'
import { ExternalLink as LinkIcon } from 'react-feather'
import { AccountsContext } from '../../hooks/AccountsProvider'
import { getMempoolExplorerLink, getEtherscanLink, getOrdiscanLink } from '../../utils'
import { useActiveWeb3React } from '../../hooks'

interface Props {
  cardType: 'evm' | 'btc'
}

const AddressAction: React.FC<Props> = ({ cardType }: Props) => {
  const { chainId, account } = useActiveWeb3React()
  const accountInfoMap = {
    evm: {
      address: account,
      href: getEtherscanLink(chainId, account, 'address'),
      href2: ''
    }
  }

  return (
    <>
      {cardType === 'evm' && account && (
        <AccountGroupingRow>
          {accountInfoMap[cardType] && (
            <AccountControl style={{ justifyContent: 'left', position: 'relative' }}>
              {accountInfoMap[cardType].address && (
                <Copy toCopy={accountInfoMap[cardType]?.address}>
                  <span style={{ marginLeft: '4px' }}>Copy Address</span>
                </Copy>
              )}
              {accountInfoMap[cardType].href && (
                <AddressLink hasENS={false} isENS={false} href={accountInfoMap[cardType].href}>
                  <LinkIcon size={16} />
                  <span style={{ marginLeft: '4px' }}>{'View on scan'}</span>
                </AddressLink>
              )}
              {accountInfoMap[cardType]?.href2 && (
                <AddressLink hasENS={false} isENS={false} href={accountInfoMap[cardType].href2}>
                  <LinkIcon size={16} />
                  <span style={{ marginLeft: '4px' }}>Ordiscan</span>
                </AddressLink>
              )}
            </AccountControl>
          )}
        </AccountGroupingRow>
      )}
    </>
  )
}

export default AddressAction
