import axios from 'axios'
import { useEffect, useState } from 'react'
import { COMING_ZERO_API } from '../constants/redEnvelope'
import { useActiveWeb3React } from './index'
import useRedEnvelopeIdsStore, { RedEnvelopeHistoryItem } from '../store/redEnvelopeIds'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { calculateCRC64 } from '../helper/generateCID'
import { formatRedPacketTimeStamp } from '../helper/formatRedPacketTimeStamp'
import { toLocaleString } from '../utils/toLocaleString'

dayjs.extend(relativeTime)

type UseSendRedEnvelopData = {
  words: string
  amount: string
  recipients: string
  status: 'Success' | 'Pending'
  time: string
  share: string
  symbol: string
}[]

export const useSendRedEnvelopData = (): {
  data: UseSendRedEnvelopData
  loading: boolean
  refetch: () => Promise<void>
} => {
  const [data, setData] = useState([])
  const { sendIds } = useRedEnvelopeIdsStore()
  const { account } = useActiveWeb3React()
  const ownSendIds = sendIds[account] || []
  const [loading, setLoading] = useState(false)

  const getData = async (list: RedEnvelopeHistoryItem[]) => {
    setLoading(true)
    const res = await Promise.all(
      list.map(async i => {
        const postData = {
          redEnvelopId: i.id,
          cid: calculateCRC64(account),
          timestamp: 0
        }
        const { data } = await axios.post(`${COMING_ZERO_API}/redenvelop/info`, {
          ...postData,
          timestamp: formatRedPacketTimeStamp(postData)
        })

        return {
          key: i.timestamp,
          words: data?.words,
          amount: `${toLocaleString(data?.luckyAmount)} / ${toLocaleString(data?.amount)}`,
          recipients: `${data?.luckyNumber} / ${data?.number}`,
          status: data?.status === 4 ? 'Success' : 'Pending',
          time: dayjs.unix(i.timestamp).fromNow(),
          share: `${window.location.origin}/#/envelope?eid=${i.id}`,
          symbol: data?.coinName,
        }
      })
    )
    setData(res)
    setLoading(false)
  }
  useEffect(() => {
    ownSendIds.length > 0 && getData(ownSendIds)
  }, [account, ownSendIds])

  return { data, loading, refetch: () => getData(ownSendIds) }
}

type UseReceivedRedEnvelopData = {
  words: string
  amount: string
  status: 'Success' | 'Pending'
  time: string
  symbol: string
}[]

export const useReceivedRedEnvelopData = (): {
  data: UseReceivedRedEnvelopData
  loading: boolean
  refetch: () => Promise<void>
} => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const { receivedIds } = useRedEnvelopeIdsStore()
  const { account } = useActiveWeb3React()
  const ownReceivedIds = receivedIds[account] || []

  const getData = async (list: RedEnvelopeHistoryItem[]) => {
    setLoading(true)
    const res = await Promise.all(
      list.map(async i => {
        const postData = {
          redEnvelopId: i.id,
          cid: calculateCRC64(account),
          timestamp: 0
        }
        const { data } = await axios.post(`${COMING_ZERO_API}/redenvelop/info`, {
          ...postData,
          timestamp: formatRedPacketTimeStamp(postData)
        })

        return {
          key: i.timestamp,
          words: data?.words,
          amount: toLocaleString(data?.luckyAccountAmount), // 自己开的红包金额
          status: data?.status === 4 ? 'Success' : 'Pending',
          time: dayjs.unix(i.timestamp).fromNow(),
          symbol: data?.coinName,
        }
      })
    )
    setData(res)
    setLoading(false)
  }

  useEffect(() => {
    ownReceivedIds.length > 0 && getData(ownReceivedIds)
  }, [account, ownReceivedIds])

  return { data, loading, refetch: () => getData(ownReceivedIds) }
}
