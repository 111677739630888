import claimBTCAbi from './abis/claimbtcAbi.json'
const BEVM_RPC = 'https://mainnet2.chainx.org/rpc' //主网

const BTC_ADDRESS = '0xCCD8602895565cE926FA0572E2a37730a94378DF'

const WBTC =  '0x09Ff8E49D0EA411A3422ed95E8f5497D4241F532'
const TORDI = '0x52ae5168D75D47832B9bEE573C169cDcD0709A36'
const TPCX = '0x7207C535f90B57C4bb5E0CCeaA45560221C61814'
const TSATS = '0x500086783454ceC51fA6788f8b55D5971299FcFb'
const LP_WBTC_ORDI = '0xae120e9913c1041be3e1e576ab01ab9c599548d8'
const LP_WBTC_PCX= '0x1d0FE8740D4F42b6bf5927CBeDC4401534c07b40'
const LP_WBTC_Sats = '0x530a728b6318294Ef36473CF26E48A496de97991'

const UNISWAPV2 = {
  "WETH": "0x09Ff8E49D0EA411A3422ed95E8f5497D4241F532",
  "Factory": "0x9480C6Fa8768F18cc0cfe35694Ce8C1660A02B5A",
  "Factory Feeto": "0xcAF084133CBdBE27490d3afB0Da220a40C32E307",
  "RouterV02": "0xC7Dd38D6D161e2a440617508308639B2d701F633",
  "Multicall": "0x79eA96a9857b3fcf7B037E5EE98D4da63Ff1934A",
  "ENSRegistry": "0x21b782d2357797D78D48813048033F187AAa2F9C",
  "init_code_hash": "0x01429e880a7972ebfbba904a5bbe32a816e78273e4b38ffa6bdeaebce8adba7c"
}


export {
  claimBTCAbi,
  BEVM_RPC,
  BTC_ADDRESS,
  UNISWAPV2,
  WBTC,
  TORDI,
  TPCX,
  TSATS,
  LP_WBTC_ORDI,
  LP_WBTC_PCX,
  LP_WBTC_Sats
}
