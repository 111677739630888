import React from 'react'
import styled from 'styled-components'
import './animation.css'
import Packet1 from '../../assets/RedEnvelope/img_packet1.png'
import Packet2 from '../../assets/RedEnvelope/img_packet2.png'
import { toLocaleString } from '../../utils/toLocaleString'

const RedContainer = styled.div<{ shake: boolean }>`
  width: 265px;
  height: 442px;
  position: relative;
  margin-bottom: 26px;
`

const RedSpan = styled.div`
  width: 100%;
  top: 0;
  left: 0;
  img {
    width: 100%;
  }
`

const RedButton = styled.button`
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #fdc339;
  border: 2px solid #fefcb0;
  font-size: 28px;
  font-weight: 500;
  color: #FF6D17;
  line-height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    cursor: not-allowed;
  }
`

const SuccessText = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #fdf99d;
  line-height: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  .value {
    word-break: break-all;
    padding: 16px 0 12px 0;
    font-size: 28px;
    font-weight: 600;
    color: #ffffff;
    line-height: 32px;
  }
  svg {
    width: 44px;
  }
`

interface Props {
  onClick: () => Promise<void | string>
  isOpened: boolean
  isOpenedButtonClicked: boolean
  openedAmount: string
  disabled: boolean
  coinSymbol: string
}

export const EnvelopeAnimation: React.FC<Props> = ({
  onClick,
  isOpened,
  isOpenedButtonClicked,
  openedAmount,
  disabled,
  coinSymbol
}: Props) => {
  return (
    <RedContainer className={isOpenedButtonClicked ? 'shake-chunk' : ''} shake={isOpenedButtonClicked}>
      <RedSpan>{isOpened ? <img src={Packet2} alt="" /> : <img src={Packet1} alt="" />}</RedSpan>
      {!isOpenedButtonClicked && !isOpened && (
        <RedButton disabled={disabled} onClick={onClick}>
          OPEN
        </RedButton>
      )}
      {isOpened && openedAmount && (
        <SuccessText>
          <div>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            You've won
          </div>
          <div className={'value'}>{toLocaleString(openedAmount)}</div>
          <div>{coinSymbol}</div>
        </SuccessText>
      )}
    </RedContainer>
  )
}
