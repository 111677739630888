import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { PoolDetailsCard } from '../../components/Card/PoolDetailsCard'
import { Text } from 'rebass'
import { ReactComponent as Exchange } from '../../assets/Pools/icon_exchange.svg'
import { useBridgeIcon } from '../../hooks/useBridgeIcon'
import { JSBI, Pair, Percent } from 'so-swap-sdk'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { useTotalSupply } from '../../data/TotalSupply'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { useDerivedMintInfo } from '../../state/mint/hooks'
import { ONE_BIPS } from '../../constants'
import BigNumber from 'bignumber.js'
import { EarningContainer } from './styles'
import Lottie from 'react-lottie'
import lpPoolAnimation from '../../assets/Assets/lpPool.json'

interface Props {
  pair: Pair
  liquidityUsdValue: number
}

const Earning: React.FC<Props> = ({ pair, liquidityUsdValue }: Props) => {
  const getIcon = useBridgeIcon()
  const { account } = useActiveWeb3React()
  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)
  const [isInvert, setIsInvert] = useState(true)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)
  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
        ]
      : [undefined, undefined]
  const { price } = useDerivedMintInfo(currency0 ?? undefined, currency1 ?? undefined)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined
  const ownLiquidity = new BigNumber(poolTokenPercentage?.toSignificant(6) || 0)
    .dividedBy(100)
    .multipliedBy(liquidityUsdValue || 0)
    .toNumber()

  const tokens = useMemo(() => {
    return [
      {
        icon: getIcon(currency0?.symbol),
        symbol: currency0?.symbol,
        deposited: token0Deposited?.toSignificant(2)
      },
      {
        icon: getIcon(currency1?.symbol),
        symbol: currency1?.symbol,
        deposited: token1Deposited?.toSignificant(2)
      }
    ]
  }, [currency0?.symbol, currency1?.symbol, getIcon, token0Deposited, token1Deposited])

  const invertPrice = useMemo(() => {
    if (isInvert) {
      return (
        <div className={'per-price-text'}>
          {getIcon(currency1?.symbol, { width: '20px', height: '20px' })}1 {currency1?.symbol}
          {' = '}
          {getIcon(currency0?.symbol, { width: '20px', height: '20px' })} {price?.invert()?.toSignificant(6)} {currency0?.symbol}
        </div>
      )
    }
    return (
      <div className={'per-price-text'}>
        {getIcon(currency0?.symbol)}1 {currency0?.symbol}
        {' = '}
        {getIcon(currency1?.symbol)} {price?.toSignificant(6)} {currency1?.symbol}
      </div>
    )
  }, [isInvert, getIcon, currency0?.symbol, currency1?.symbol, price])

  return (
    <PoolDetailsCard title={'Earning'}>
      <EarningContainer>
        <div className="left">
          <Lottie options={{ loop: true, animationData: lpPoolAnimation }} segments={[150, 240]} direction={-1} />
          <div className={'pool-name'}>
            LP | {currency0?.symbol} - {currency1?.symbol}
          </div>
        </div>
        <div className={'right'}>
          <div className={'per-price'}>
            <Text marginRight={'14px'} fontSize={16} fontWeight={500} lineHeight={'20px'} color={'#281071'}>
              {invertPrice}
            </Text>
            <div onClick={() => setIsInvert(!isInvert)} className={'exchange'}>
              <Exchange />
            </div>
          </div>
          <div className={'liquidity'}>
            <Text fontSize={16} fontWeight={500} lineHeight={'20px'} color={'#988EB7'}>
              Liquidity
            </Text>
            <Text fontSize={16} fontWeight={500} lineHeight={'22px'} color={'#281071'}>
              ＄{ownLiquidity < 0.01 && ownLiquidity > 0 ? '< 0.01' : ownLiquidity.toFixed(2)}
            </Text>
          </div>

          <div className={'percent-card'}>
            {(tokens || []).map(i => (
              <div key={i.symbol} className={'token-item'}>
                <div className={'token'}>
                  {getIcon(i.symbol)}
                  <Text padding={'0 12px'} fontSize={18} fontWeight={600} color={'#281071'} lineHeight={'20px'}>
                    {i.deposited}
                  </Text>
                  <Text fontSize={18} fontWeight={600} color={'#988EB7'} lineHeight={'20px'}>
                    {i.symbol}
                  </Text>
                </div>
                <div className="abr">50%</div>
              </div>
            ))}
          </div>
        </div>
      </EarningContainer>
    </PoolDetailsCard>
  )
}

export default React.memo(Earning)
