import { useRedEnvelopeContract, useTokenContract } from './useContract'
import { BASE_PREPAID_FEE, RED_PACKET_CONTRACT_ADDRESS } from '../constants/redEnvelope'
import { calculateOriginalGasMargin, getMaxFeeGas } from '../utils'
import { ethers } from 'ethers'
import BigNumber from 'bignumber.js'
import { useActiveWeb3React } from './index'
import useRedEnvelopeStore from '../store/redEnvelope'
import useRedEnvelopeIdsStore from '../store/redEnvelopeIds'
import dayjs from 'dayjs'

export const useRedEnvelopeAction = (): (() => Promise<void>) => {
  const redPacketContract = useRedEnvelopeContract()
  const { account } = useActiveWeb3React()
  const { createRedEnvelop, createParams, setTxHash } = useRedEnvelopeStore()
  const tokenContract = useTokenContract(createParams.tokenInfo.address, true)

  const { setSendIds } = useRedEnvelopeIdsStore()

  return async () => {
    const { count, tokenAmount, tokenInfo } = createParams
    const countNum = parseInt(count)
    const parsedAmount = ethers.utils.parseUnits(tokenAmount, tokenInfo.decimals)

    const allowance = await tokenContract.allowance(account, RED_PACKET_CONTRACT_ADDRESS)
    const formatAllowance = ethers.utils.formatEther(allowance)

    if (Number(formatAllowance) < Number(tokenAmount)) {
      const tx = await tokenContract.approve(RED_PACKET_CONTRACT_ADDRESS, parsedAmount, { ...getMaxFeeGas() })
      const res = await tx.wait()
      console.log('res', res)
    }

    let prepaidFee = '0'
    if (countNum >= 0 && countNum <= 10) {
      prepaidFee = BASE_PREPAID_FEE
    } else if (countNum > 10 && countNum <= 100) {
      prepaidFee = new BigNumber(BASE_PREPAID_FEE).multipliedBy(10).toString()
    } else if (countNum > 100 && countNum <= 1000) {
      prepaidFee = new BigNumber(BASE_PREPAID_FEE).multipliedBy(60).toString()
    }
    const parsedFee = ethers.utils.parseEther(prepaidFee)

    const estimate = await redPacketContract.estimateGas.create(tokenInfo.address, count, parsedAmount, {
      value: parsedFee,
      from: account
    })
    const gasLimit = calculateOriginalGasMargin(estimate)
    const tx = await redPacketContract.create(tokenInfo.address, count, parsedAmount, {
      value: parsedFee,
      from: account,
      ...getMaxFeeGas(),
      gasLimit
    })
    const res = await tx.wait()
    console.log('res', res?.transactionHash)
    setTxHash(res?.transactionHash)
    const id = await createRedEnvelop({
      ...createParams,
      txHash: res?.transactionHash,
      account,
      txFee: prepaidFee
    })
    setSendIds(account, [{ timestamp: dayjs().unix(), id }])
  }
}
