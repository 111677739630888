import React, { CSSProperties } from 'react'
import styled from 'styled-components'

export const BodyWrapper = styled.div<{
  maxWidth?: string
  padding?: string
  background?: string
  borderRadius?: string
}>`
  position: relative;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '480px')};
  width: 100%;
  background: #f1f5fb;
  background: ${({ background }) => (background ? background : 'rgba(250, 250, 250, 0.5)')};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '24px')};
  border: 1px solid #ffffff;
  padding: ${({ padding }) => padding && padding};
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({
  children,
  maxWidth,
  padding,
  background,
  borderRadius,
  ...reset
}: {
  children: React.ReactNode
  maxWidth?: string
  padding?: string
  background?: string
  borderRadius?: string
  style?: CSSProperties
}) {
  return (
    <BodyWrapper maxWidth={maxWidth} padding={padding} borderRadius={borderRadius} background={background} {...reset}>
      {children}
    </BodyWrapper>
  )
}
