import React from 'react'
import { RowBetween } from '../Row'
import { PricePoolCard, WhiteCard } from '../Card'
import PoolPriceShare, { PoolPriceShareProps } from '../../pages/AddLiquidity/PoolPriceShare'

interface Props extends PoolPriceShareProps {
  header: React.ReactNode
}

const ExchangeInfoPanel: React.FC<Props> = ({ header, poolPriceInfoList, fontSizeOfPrice, fontSizeOfName }: Props) => {
  return (
    <PricePoolCard padding="0px" borderRadius={'20px'}>
      <RowBetween padding="1rem">{header}</RowBetween>{' '}
      <WhiteCard padding="1rem" borderRadius={'20px'}>
        <PoolPriceShare
          poolPriceInfoList={poolPriceInfoList}
          fontSizeOfPrice={fontSizeOfPrice}
          fontSizeOfName={fontSizeOfName}
        />
      </WhiteCard>
    </PricePoolCard>
  )
}

export default React.memo(ExchangeInfoPanel)
