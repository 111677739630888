const RED_PACKET_CONTRACT_ADDRESS = '0x3a9Fb3A929Ec88A2EA9d32ACF4a1DA2e19A90404'

// 0.00000500 BTC
const BASE_PREPAID_FEE = '0.000005'

const COMING_ZERO_API = 'https://coming-zero-wallet.coming.chat'

const promotionWritingsZhCn = '我刚发了个SATS超级大红包，赶紧来抢啊！手慢无！'
const promotionWritingsEnUs =
  'I just sent a super big SATS red envelope, hurry up and grab it! First come, first served!'

const promotionTwitterZhCn = '刚刚开到了一个大红包，大家快来试试运气吧！'
const promotionTwitterEnUs = 'I just picked up a big gift bag, come and try your luck!'

enum SupportedToken {
  SATS = 'SATS',
  ORDI = 'ORDI',
  MEME = 'MEME',
  // @ts-ignore
  '9527' = '9527'
  // BIIS = 'BIIS'
}

const SUPPORTED_TOKENS: { [key in SupportedToken]: { address: string; decimals: number; symbol: SupportedToken } } = {
  [SupportedToken.SATS]: {
    address: '0x6e128a3BCfC042D539cae6fe761AB3ef6d0e298c',
    decimals: 18,
    symbol: SupportedToken.SATS
  },
  [SupportedToken.ORDI]: {
    address: '0x1d1ce1d02ba582cffd0fc0093fd6bab678708b4e',
    decimals: 18,
    symbol: SupportedToken.ORDI
  },
  [SupportedToken.MEME]: {
    address: '0x848c8cb9f9f2f88c6679cd5616a9fa87e551670a',
    decimals: 18,
    symbol: SupportedToken.MEME
  },
  [SupportedToken['9527']]: {
    symbol: SupportedToken['9527'],
    address: '0xea93047018fd60C7d8EE05d9c253D3c3Ded4D589',
    decimals: 18
  }
  // [SupportedToken.BIIS]: {
  //   address: '0x311EEF0189bDaf63e0fCDC02BD3bdb4Be5752466',
  //   decimals: 18,
  //   symbol: SupportedToken.BIIS
  // }
}
export {
  RED_PACKET_CONTRACT_ADDRESS,
  BASE_PREPAID_FEE,
  COMING_ZERO_API,
  promotionWritingsZhCn,
  promotionWritingsEnUs,
  promotionTwitterZhCn,
  promotionTwitterEnUs,
  SUPPORTED_TOKENS
}
