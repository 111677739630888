import React, { useMemo } from 'react'
import { Pair } from 'so-swap-sdk'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { useBridgeIcon } from '../../hooks/useBridgeIcon'
import { Text } from 'rebass'
import { PoolDetailsCard } from '../../components/Card/PoolDetailsCard'
import { PoolItem } from '../../hooks/usePoolsData'
import { PoolStatsWrapper } from './styles'
import { BigNumber } from 'bignumber.js'

interface Props {
  pair: Pair
  currentPool: PoolItem
}

const PoolStats: React.FC<Props> = ({ pair, currentPool }: Props) => {
  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)
  const getIcon = useBridgeIcon()

  const tokens = useMemo(() => {
    return [
      {
        symbol: currency0.symbol,
        icon: getIcon(currency0.symbol),
        totalAmount: pair.reserve0.toSignificant(6)
      },
      {
        symbol: currency1.symbol,
        icon: getIcon(currency1.symbol),
        totalAmount: pair.reserve1.toSignificant(6)
      }
    ]
  }, [currency0.symbol, currency1.symbol, getIcon, pair.reserve1, pair.reserve0])

  const infoList = useMemo(() => {
    return [
      {
        name: 'APR',
        value: `${currentPool?.aprDaily}%`
      },
      {
        name: 'Liquidity',
        value: `$ ${currentPool?.liquidityUsd.toFixed(2)}`
      },
      {
        name: 'Volume(24H)',
        value: `$ ${currentPool?.dailyVolumeUsd.toFixed(2)}`
      },
      {
        name: 'Fees (24H)',
        value: `$ ${new BigNumber(currentPool?.dailyVolumeUsd).multipliedBy(0.0025).toFixed(2)}`
      }
    ]
  }, [currentPool?.aprDaily, currentPool?.dailyVolumeUsd, currentPool?.liquidityUsd])

  return (
    <PoolDetailsCard title={'Pool Stats'}>
      <PoolStatsWrapper>
        <div className={'token-liquidity'}>
          {tokens.map(t => (
            <div className={'item'} key={t.symbol}>
              {t.icon}
              <Text padding={'0 12px'} fontSize={18} fontWeight={600} color={'#281071'} lineHeight={'22px'}>
                {t.totalAmount}
              </Text>
              <Text fontSize={18} fontWeight={600} color={'#988EB7'} lineHeight={'22px'}>
                {t.symbol}
              </Text>
            </div>
          ))}
        </div>
        <div className={'info-group'}>
          {infoList.map(i => (
            <div className={'info-item'} key={i.name}>
              <Text marginBottom={'8px'} fontSize={14} fontWeight={500} color={'#988EB7'} lineHeight={'18px'}>
                {i.name}
              </Text>
              <Text fontSize={16} fontWeight={500} color={'#281071'} lineHeight={'20px'}>
                {i.value}
              </Text>
            </div>
          ))}
        </div>
      </PoolStatsWrapper>
    </PoolDetailsCard>
  )
}

export default React.memo(PoolStats)
