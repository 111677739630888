import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { AutoRow } from '../Row'
import IconSuccess from '../../assets/icon_success.svg'
import IconFail from '../../assets/icon_failure.svg'
import { ExternalLink, TYPE } from '../../theme'
import { getMempoolExplorerLink, getEtherscanLink } from '../../utils'
import { AutoColumn } from '../Column'
import { SELECT_CHAIN_SCAN } from '../../data/AssetsTokenList';
import { selectDefaultChain } from '../../helper/supportAssetsChain';
import { BridgeChain } from '../../constants/bridge'

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`

const PopTitle = styled.div`
  width: 260px;
  font-size: 16px;
  font-weight: 600;
  color: #281071;
  line-height: 20px;
  word-wrap: break-word;
`

export default function TransactionPopup({
  type,
  hash,
  success,
  summary
}: {
  type?: string
  hash: string
  success?: boolean
  summary?: string
}) {
  const { chainId } = useActiveWeb3React()

  const getScanLink = (type: string, summary: string, hash: string) => {
    if (!type || !summary) return
    if (type === BridgeChain.bitcoin) {
      return getMempoolExplorerLink(hash, 'transaction')
    }
    return getEtherscanLink(chainId, hash, 'transaction')
  }
  return (
    <RowNoFlex>
      <div style={{ paddingRight: 16 }}>
        {success ? <img src={IconSuccess} alt="" /> : <img src={IconFail} alt="" />}
      </div>
      <AutoColumn gap="8px">
        <PopTitle>{summary ?? 'Hash: ' + hash.slice(0, 8) + '...' + hash.slice(58, 65)}</PopTitle>
        {hash && (
          <ExternalLink
            href={!type ? getEtherscanLink(chainId, hash, 'transaction') : getScanLink(type, summary, hash)}
          >
            View on scan
          </ExternalLink>
        )}
      </AutoColumn>
    </RowNoFlex>
  )
}
