import React from 'react'
import './style.css'

export default function Connecting() {
  return (
    <div className="grid-row" style={{ position: 'relative', width: '11px' }}>
      <div className="col">
        <ul className="loading reversed">
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
  )
}
