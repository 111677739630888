import React, { useMemo } from 'react'
import { RowBetween } from '../Row'
import { Text } from 'rebass'
import { ItemContent, StyledBalanceMaxMini } from '../swap/styleds'
import { Repeat } from 'react-feather'

interface Props {
  isInverted: boolean
  toggleInverted: () => void
  price: string
  priceInvert: string
  currencySymbolA: string
  currencySymbolB: string
}

const PriceConvertFormula: React.FC<Props> = ({
  isInverted,
  toggleInverted,
  price,
  priceInvert,
  currencySymbolA,
  currencySymbolB
}: Props) => {
  const convertFormula = useMemo(() => {
    return isInverted
      ? `${price} ${currencySymbolB} = 1 ${currencySymbolA}`
      : `${priceInvert} ${currencySymbolA} = 1 ${currencySymbolB}`
  }, [isInverted, priceInvert, price, currencySymbolA, currencySymbolB])

  return (
    <ItemContent style={{ width: '100%' }}>
      <RowBetween padding={'0'}>
        <Text style={{ display: 'flex', alignItems: 'center' }}>
          Price
          <StyledBalanceMaxMini onClick={toggleInverted}>
            <Repeat size={14} />
          </StyledBalanceMaxMini>
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>{convertFormula}</Text>
      </RowBetween>
    </ItemContent>
  )
}

export default PriceConvertFormula
