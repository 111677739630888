import bevmBridgeChainxAbi from './abis/bevmBridgeChainxAbi.json'
const BEVM_RPC = 'https://mainnet2.chainx.org/rpc' //主网

const BEVM_ADDRESS = '0xd1543A52d770D32854fc5f5F076a74033D49D41f'

const PCX = {
  "chainId": 1501,
  "address": "0xf3607524cAB05762cB5F0cAb17e4cA3A0F0b4E87",
}
const DEFAULT_PCX = {
  "decimals": 8,
  "symbol": "PCX",
  "name": "ChainX",
  "chainId": 1501,
  "address": "0xf3607524cAB05762cB5F0cAb17e4cA3A0F0b4E87",
  "tokenInfo": {
    "chainId": 1501,
    "address": "0xf3607524cAB05762cB5F0cAb17e4cA3A0F0b4E87",
    "assetId": 1,
    "name": "ChainX",
    "symbol": "PCX",
    "decimals": 8,
    "logoURI": "https://raw.githubusercontent.com/chainx-org/token-list/bswap/assets/PCX.svg"
  },
  "tags": []
}

export {
  PCX,
  bevmBridgeChainxAbi,
  BEVM_RPC,
  BEVM_ADDRESS,
  DEFAULT_PCX
}
