import React from 'react'
import Icon from '../Icon'
import IconRise from '../../assets/images/icon_rise.svg'
import IconReduce from '../../assets/images/icon_reduce.svg'
import { formatInfoTablePrice } from '../../helper/formatPrice'
import { PriceChangerWrapper } from './styles'

interface Props {
  priceChangePercent: number
}

const PriceChanger: React.FC<Props> = ({ priceChangePercent }: Props) => {
  return (
    <PriceChangerWrapper isRise={priceChangePercent >= 0}>
      {priceChangePercent !== 0 && (
        <Icon iconSrc={priceChangePercent > 0 ? IconRise : IconReduce} width={16} height={16} />
      )}
      {formatInfoTablePrice(priceChangePercent)}%
    </PriceChangerWrapper>
  )
}

export default React.memo(PriceChanger)