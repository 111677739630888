import React, { useCallback, useMemo } from 'react'
import { Collapse as AntdCollapse, CollapseProps, CollapsePanelProps } from 'antd'
import { ReactComponent as Arrow } from '../../assets/Pools/icon_arrow_right.svg'
import styled from 'styled-components'

const { Panel } = AntdCollapse

const CollapseWrapper = styled(AntdCollapse)`
  width: 100%;
  border-radius: 16px !important;
  &.ant-collapse {
    .ant-collapse-item {
      border-radius: 16px !important;
      overflow: hidden;
      .ant-collapse-header {
        background: #f5f6f9;
        border-top-right-radius: 16px !important;
        border-top-left-radius: 16px !important;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        color: #988eb7;
        line-height: 20px;

        .ant-collapse-extra {
          font-size: 16px;
          font-weight: 500;
          color: #281071;
          line-height: 22px;
        }
      }
    }
    .ant-collapse-content {
      &.ant-collapse-content-active {
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
      }
    }
  }
`

const ExpandIconWrapper = styled.div`
  background: #fbfbfd;
  border-radius: 50%;
  border: 1px solid #f0f2f5;
  width: 32px;
  height: 32px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0;
  svg {
    width: 12px;
    height: 12px;
    transition: transform 300ms ease-in-out;
  }
  &.active {
    svg {
      transform: rotate(90deg);
    }
  }
`

interface Props extends CollapsePanelProps {
  children: React.ReactNode
  defaultOpen?: boolean
  style?: React.CSSProperties
  panelKey: string | number
}

const Collapse: React.FC<Props> = ({ panelKey, header, children, style, defaultOpen = false, ...rest }: Props) => {
  const expandIcon = useCallback((isActive: boolean) => {
    return (
      <ExpandIconWrapper className={isActive ? 'active' : ''}>
        <Arrow />
      </ExpandIconWrapper>
    )
  }, [])
  return (
    <CollapseWrapper
      style={style}
      expandIcon={({ isActive }) => expandIcon(isActive)}
      defaultActiveKey={defaultOpen ? panelKey : ''}
    >
      <Panel header={header} key={panelKey} {...rest}>
        {children}
      </Panel>
    </CollapseWrapper>
  )
}

export default React.memo(Collapse)
