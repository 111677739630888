import styled from 'styled-components'
import { AutoColumn } from '../Column'
import { RowBetween, RowFixed } from '../Row'
import { isMobile } from 'react-device-detect'

export const ModalInfo = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  padding: 1rem 1rem;
  margin: 0.25rem 0.5rem;
  justify-content: center;
  flex: 1;
  user-select: none;
`

export const FadedSpan = styled(RowFixed)`
  color: ${({ theme }) => theme.primary1};
  font-size: 14px;
`

export const PaddedColumn = styled(AutoColumn)`
  padding: 20px;
  padding-bottom: 12px;

  &.currency-search {
    padding: 16px 24px 24px 24px;
    border-bottom: 1px solid #e8eaf1;
    ${isMobile && 'padding:5.3vw 5.3vw 4.2vw'};
  }
`

export const MenuItem = styled(RowBetween)`
  padding: 4px 24px;
  height: 56px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) auto minmax(0, 72px);
  grid-gap: 12px;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  :hover {
    background-color: white;
  }
  opacity: ${({ disabled, selected }) => (disabled || selected ? 0.5 : 1)};
  ${isMobile && 'padding: 4.2vw 5.3vw'};

`

export const SearchInput = styled.input`
  position: relative;
  display: flex;
  padding: 16px;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  border-radius: 20px;
  color: ${({ theme }) => theme.text1};
  border: none;
  outline: 1px solid ${({ theme }) => theme.bg3};
  -webkit-appearance: none;
  font-size: 18px;
  transition: outline-color 0.1s ease-out, background-color 0.7s ease-out;
  box-shadow: 0 2px 4px 0 rgba(109, 78, 201, 0.06);

  ::placeholder {
    color: ${({ theme }) => theme.text3};
  }
  :focus {
    outline: 2px solid #6d4ec9;
  }
`
export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.bg2};
`

export const SeparatorDark = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.bg3};
`
