/** @format */
import React, { useState } from 'react'

interface Props {
  children: React.ReactNode
}

export interface ApiProps {
  messageContent: string
  setMessageContent: React.Dispatch<string>
  txResult: boolean
  setTxResult: React.Dispatch<boolean>
  isShowPop: boolean
  setIsShowPop: React.Dispatch<boolean>
  showFn: (type: string, hash: string, result: boolean, resultContent: string) => void
  type: string
  hash: string
  setType: React.Dispatch<string>
  setHash: React.Dispatch<string>
}

export const PopUpMessageContext = React.createContext<ApiProps>({} as ApiProps)

function PopUpMessageProvider({ children }: Props): React.ReactElement<Props> | null {
  const [messageContent, setMessageContent] = useState('')
  const [txResult, setTxResult] = useState(false)
  const [isShowPop, setIsShowPop] = useState(false)
  const [type, setType] = useState('')
  const [hash, setHash] = useState('')

  const showFn = (type: string, hash: string, result: boolean, resultContent: string) => {
    setType(type)
    setHash(hash)
    setTxResult(result)
    setMessageContent(resultContent)
    setIsShowPop(true)
  }
  return (
    <PopUpMessageContext.Provider
      value={{
        messageContent,
        setMessageContent,
        txResult,
        setTxResult,
        isShowPop,
        setIsShowPop,
        showFn,
        type,
        hash,
        setType,
        setHash
      }}
    >
      {children}
    </PopUpMessageContext.Provider>
  )
}

export default React.memo(PopUpMessageProvider)
