import React from 'react'
import styled from 'styled-components'
import MenuIcon from '../../assets/Header/icon_Q&A.svg'

const StyledMenuButton = styled.div`
  min-width: 28px;
  min-height: 38px;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(109, 78, 201, 0.06);
  border-radius: 12px;
  border: none;
  padding: 12px;
  margin-left: 0.5rem;
  cursor: pointer;
`

export default function Question() {
  return (
    <a id="link" href="https://bswap.gitbook.io/bswap-1" target="_blank" rel="noreferrer">
      <StyledMenuButton>
        <img src={MenuIcon} alt="" width={16} />
      </StyledMenuButton>
    </a>
  )
}
