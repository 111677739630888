export const useAddToMetamask = (): ((
  address: string,
  symbol: string,
  decimals: number,
  logoURI: string
) => Promise<void>) => {
  return async (address, symbol, decimals, logoURI) => {
    try {
      const wasAdded = await (window?.ethereum as any)?.request?.({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address,
            symbol,
            decimals,
            image: logoURI
          }
        }
      })

      if (!wasAdded) {
        console.log('Your loss token add!')
      }
    } catch (error) {
      console.log(error)
    }
  }
}
