import React from 'react'
import { Text } from 'rebass'
import styled from 'styled-components'
import { ReactComponent as Github } from '../../assets/Assets/github.svg'
import { ReactComponent as Twitter } from '../../assets/Assets/twitter.svg'
import { ReactComponent as Discord } from '../../assets/Assets/discord.svg'
import { useLocation } from 'react-router-dom'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 36px;
  .contact-us {
    width: 100%;
    max-width: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
`

const contacts = [
  {
    icon: props => <Twitter onClick={() => window.open(props.link)} />,
    link: 'https://twitter.com/BSwap_BEVM'
  },
  {
    icon: props => <Discord onClick={() => window.open(props.link)} />,
    link: 'https://discord.gg/FJMEWCjY8R'
  },
  {
    icon: props => <Github onClick={() => window.open(props.link)} />,
    link: 'https://github.com/chainx-org/ChainX'
  }
]

const Footer: React.FC = () => {
  const { pathname } = useLocation()
  const FooterMap = {
    '/envelope': <></>
  }
  return (
    <>
      {FooterMap[pathname] ?? (
        <Container>
          <Text fontSize={12} fontWeight={500} color={'#988EB7'} lineHeight={'15px'} paddingBottom={'16px'}>
            Powered by BSwap
          </Text>
          <div className="contact-us">
            {contacts.map(i => (
              <React.Fragment key={i.link}>{i.icon({ link: i.link })}</React.Fragment>
            ))}
          </div>
        </Container>
      )}
    </>
  )
}

export default Footer
