import React from 'react'
import styled from 'styled-components'
import iconBEVM from '../../assets/RedEnvelope/header_bevm.svg'
import iconTwitter from '../../assets/RedEnvelope/header_twitter.svg'
import { Link } from 'react-router-dom'

export const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  z-index: 2;
  padding: 8px 24px;
  overflow-x: scroll;
`

export default function EnvelopeHeader(): React.ReactElement {
  return (
    <HeaderFrame>
      <Link to={'/swap'}>
        <img src={iconBEVM} alt="" />
      </Link>
      <div onClick={() => window.open('https://twitter.com/btclayer2?s=11&t=jx1SOPKY7qJQuj1uIwpi0w')}>
        <img src={iconTwitter} alt="" />
      </div>
    </HeaderFrame>
  )
}
