import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import AppBody from '../AppBody';
import { PaddingWrapper } from '../../theme';
import { CardHeader } from '../Pool/styleds';
import { TradeContainer } from './styles';
import { Text } from 'rebass';
import { ButtonError } from '../../components/Button';
import { useActiveWeb3React } from '../../hooks';
import { getBevmToChainxBridgeContract, getMaxFeeGas } from '../../utils';
import NumericalInput from '../../components/NumericalInput';
import BalanceMax from '../../components/BalanceMax';
import { ReactComponent as PCXIcon } from '../../assets/Tool/pcx_logo.svg'
import { ReactComponent as BEVMIcon } from '../../assets/Tool/bevm_logo.svg'
import { ReactComponent as SwitchIcon } from '../../assets/Tool/icon_trande_exchange.svg'
import { useTokenBalance } from '../../state/wallet/hooks';
import axios from 'axios';
import { BigNumber } from 'bignumber.js';
import { PCX } from '../../constants';
import { useAddPopup } from '../../state/application/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks';
import { Field } from '../../state/mint/actions';

const Tool: React.FC = () => {
  const addPopup = useAddPopup()
  const { account, library } = useActiveWeb3React()
  const bevmToChainxBridgeContract = getBevmToChainxBridgeContract(library, account)
  const [sendAmount, setSendAmount] = useState('')
  const [receiveAddress, setReceiveAddress] = useState('')
  const [receivePublicKey, setReceivePublicKey] = useState('')
  const [warning, setWarning] = useState(null)
  const addTransaction = useTransactionAdder()
  const selectedCurrencyBalance = useTokenBalance(account, PCX)
  const [pcxBalance, setPcxBalance] = useState(selectedCurrencyBalance)

  const getPublicKey = async (address: string) => {
    try {
      const res = await axios.get(`https://multiscan-api.coming.chat/transition?address=${address}`)
      if(res?.data?.address?.length === 66){
        setReceivePublicKey(res?.data?.address)
        setWarning(null)
        return res?.data?.address ?? ''
      }else {
        setWarning('Invalid ChainX Address')
      }
    }catch (e) {
      setWarning('Invalid ChainX Address')
      console.error(e)
    }
  }

  const addTrans = () => {

  }

  const handleTransaction = async (value, address) => {
    if(!address || !value) return
    const formatValue =  new BigNumber(value).multipliedBy(Math.pow(10, 8)).toFixed(0)
    const publicKey = getPublicKey(address)
    const summary = `Transfer ${value} PCX from BEVM to ChainX`
    if(publicKey){
      try {
        const response = await bevmToChainxBridgeContract['withdrawPCX']( ...[formatValue, publicKey],{
          from: account,
          ...getMaxFeeGas(),
          gasLimit: 150000
        })
        const txId = response?.hash
        addPopup({ txn: { type: 'BEVM', hash: txId, success: true, summary } }, txId)
        addTransaction(response, {
          summary
        })
      } catch (e) {
        console.error(e)
      } finally {

      }

    }

  }

  return (
    <AppBody background="rgba(250,250,250,0.1)">
      <CardHeader>
        <div>Transfer</div>
      </CardHeader>
      <PaddingWrapper showTopBorder={true}>
        <TradeContainer>
          <div>
            <div className="title">
              <span>From</span>
              <span>To</span>
            </div>
            <div className="chain">
              <div className="chain-item"><BEVMIcon/>BEVM</div>
              <SwitchIcon className="switch-icon"/>
              <div className="chain-item"><PCXIcon/>ChainX</div>
            </div>
          </div>

          <div className="wrapper">
            <div className="card-top">
              <span>Amount </span>
              <div className="item-row">
                Balance: {selectedCurrencyBalance?.toSignificant(6)}
                <BalanceMax balanceValue={''} balanceOnMax={()=>{setSendAmount(selectedCurrencyBalance?.toSignificant(6))}} transferAll={true} />
              </div>
            </div>
            <div className="card-down">
              <div className="item-row">
                <PCXIcon/>
                <span className="token-name">PCX</span>
              </div>
              <NumericalInput
                className="amount"
                value={sendAmount}
                onUserInput={val => {
                  setSendAmount(val)
                  setWarning(val > selectedCurrencyBalance?.toSignificant(6) ? 'Insufficient Balance' : null)
                }}
                align={'right'}
              />
            </div>
          </div>
          <div className="receive-address">
            <span className="title">Receive Address</span>
            <input
              height={24}
              className="receive-address-input"
              placeholder={'Enter ChainX Address'}
              value={receiveAddress}
              type="text"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              onChange={e => {
                setReceiveAddress(e.target.value)
                getPublicKey(e.target.value)
              }}
            />
          </div>
          <ButtonError
            onClick={()=>handleTransaction(sendAmount,receiveAddress)}
            disabled={!account || !receiveAddress || !sendAmount || !receivePublicKey || warning}
            style={{ display: 'flex', alignItems: 'center', marginTop:'4px' }}
          >
            <Text fontSize={20} fontWeight={500}>
              { warning ? warning : 'Confirm Transfer'}
            </Text>
          </ButtonError>
        </TradeContainer>
      </PaddingWrapper>
    </AppBody>
  )
}

export default Tool
