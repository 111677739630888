import React from 'react'
import IconArrowUp from '../../assets/images/icon_arrow_up.webp'
import styled from 'styled-components'
import Icon from '../../components/Icon'

const ArrowWrapper = styled.div`
  width: fit-content !important;
  border-radius: 16px;

  img {
    transition: all 0.2s;

    &.arrow-down {
      transform: rotate(-180deg);
    }
  }
`

interface Props {
  isUp: boolean
}

const Arrow: React.FC<Props> = ({ isUp }: Props) => {
  return (
    <ArrowWrapper>
      <Icon className={isUp ? 'arrow-up' : 'arrow-down'} iconSrc={IconArrowUp} width={24} height={24} />
    </ArrowWrapper>
  )
}

export default Arrow
