import React from 'react'
import { MenuButtonWrapper } from '../MenuButton/styles'
import MenuContent from './MenuContent'

function HeaderMobile(): React.ReactElement {
  return (
    <MenuButtonWrapper>
      <MenuContent />
    </MenuButtonWrapper>
  )
}

export default HeaderMobile
