export function truncateFixed(originValue: string, decimals: number = 8): string {
  try {
    let value = ''
    let sign = ''
    if (Number(originValue) >= 0) {
      value = originValue
      sign = ''
    } else {
      value = Math.abs(Number(originValue)).toString()
      sign = '-'
    }
    const formatValue = Number.isInteger(Number(value)) ? Number(value).toFixed(1) : value
    const match = formatValue.match(/^(\d+)\.(\d*)$/)
    if (match) {
      const integerPart = match[1]
      let decimalPart = match[2]
      if (decimalPart.length < decimals) {
        decimalPart = decimalPart.padEnd(decimals, '0')
      }
      return `${sign}${integerPart}.${decimalPart.slice(0, decimals)}`
    }
    return sign + value
  } catch (error) {
    console.error('An error occurred:', error)
    return originValue // 返回原始值或其他默认值
  }
}
