import React from 'react'
import styled from 'styled-components'
import { CheckCircle, Triangle } from 'react-feather'

import { useActiveWeb3React } from '../../hooks'
import { getEtherscanLink } from '../../utils'
import { ExternalLink } from '../../theme'
import { useAllTransactions } from '../../state/transactions/hooks'
import Row, { RowBetween, RowFixed } from '../Row'
import Loader from '../Loader'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import { TransactionConfirmations } from '../PolkaAccountDetail/Transaction'
import { Popover } from 'antd'
import { BridgeTxType } from '../../constants/bridge'

export const TransactionStatusText = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #aba5bd;
  line-height: 15px;
`

export const TransactionTypeText = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #281071;
  line-height: 17px;
  white-space: nowrap;
`

export const TransactionLinkArrow = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #21d2b3;
  line-height: 16px;
  :hover {
    text-decoration: underline;
  }
`

const TransactionState = styled(ExternalLink)<{ pending: boolean; success?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  border-radius: 0.5rem;
  font-size: 13px;
  font-weight: 600;
  color: #876cd7;
  line-height: 16px;
  padding: 0.125rem 8px;
  &:hover {
    background: #e9eef6;
  }
`

const IconWrapper = styled.div<{ pending: boolean; success?: boolean }>`
  color: ${({ pending, success, theme }) => (pending ? theme.primary1 : success ? theme.green1 : theme.red1)};
`

export default function Transaction({ hash }: { hash: string }) {
  const { chainId } = useActiveWeb3React()
  const allTransactions = useAllTransactions()

  const tx = allTransactions?.[hash]
  const summary = tx?.summary
  const pending = !tx?.receipt
  const success = !pending && tx && (tx.receipt?.status === 1 || typeof tx.receipt?.status === 'undefined')
  const txType = tx?.txType ?? '-'
  const timestamp = tx?.addedTime

  if (!chainId) return null

  return (
    <TransactionState
      href={getEtherscanLink(chainId, hash, txType === BridgeTxType.Bridge ? 'bridge' : 'transaction')}
      pending={pending}
      success={success}
    >
      <IconWrapper pending={pending} success={success}>
        {pending ? (
          <Loader stroke={'#3B199F'} />
        ) : success ? (
          <CheckCircle size="16" color={'#38DCBF'} />
        ) : (
          <Triangle size="16" color={'#E93E5E'} />
        )}
      </IconWrapper>
      <div
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: '1', marginLeft: '10px' }}
      >
        <RowBetween>
          <TransactionTypeText>{txType} </TransactionTypeText>
          <Row justifyContent={'flex-end'}>
            {txType === BridgeTxType.Bridge && (
              <Popover
                trigger={'hover'}
                content={`To ensure asset security, there will be a delay in BTC withdrawals, and it will take approximately ${
                  summary?.includes('BTC') ? '24' : '1'
                } hours to arrive.​`}
              >
                <TransactionConfirmations>{summary?.includes('BTC') ? '~24 H' : '~1H'}</TransactionConfirmations>
              </Popover>
            )}
            <TransactionLinkArrow style={{ marginLeft: '6px' }}>↗</TransactionLinkArrow>
          </Row>
        </RowBetween>
        <RowBetween>
          <TransactionStatusText>{summary ?? hash}</TransactionStatusText>
          <TransactionStatusText>
            {dayjs(timestamp)
              .locale('en')
              .format('MMM-DD HH:mm')}
          </TransactionStatusText>
        </RowBetween>
      </div>
    </TransactionState>
  )
}
