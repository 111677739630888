import styled from 'styled-components';

export const TradeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .title, .chain{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
    color: #281071;
    line-height: 18px;
    align-items: center;
  }
  .title{
    margin-bottom: 8px;
  }
  .chain-item{
    width: 180px;
    display: flex;
    background: #DCDCF4;
    box-shadow: 0px 2px 4px 0px rgba(109,78,201,0.06);
    border-radius: 12px;
    border: 1px solid #FFFFFF;
    align-items: center;
    padding: 12px 20px;
    font-size: 18px;
    font-weight: 500;
    color: #281071;
    line-height: 23px;
    &>svg{
      margin-right: 7px;
    }
  }
  .switch-icon{
    background: #DCDCF4;
    box-shadow: 0px 2px 4px 0px rgba(109,78,201,0.06);
    border-radius: 8px;
    border: 1px solid #FFFFFF;
    padding: 8px;
    width: 32px;
    height: 32px;
  }
  
  .wrapper {
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(109, 78, 201, 0.06);
    border-radius: 24px;
    border: 2px solid #f7f8fa;
    padding: 18px 16px;
    :hover {
      border: 2px solid rgba(109, 78, 201, 0.57);
    }
  }
  .card-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
    font-size: 14px;
    font-weight: 500;
    color: #3b199f;
    line-height: 17px;
    min-height: 29px;
    & > div {
      font-size: 14px;
      font-weight: 500;
      color: #988eb7;
      line-height: 17px;
    }
    .max {
      cursor: pointer;
    }
  }
  .item-row{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .card-down {
    display: flex;
    .amount-loading {
      display: flex;
      align-items: center;
      min-height: 38px;
    }
    .amount {
      flex: 1;
      background-color: transparent;
    }
    .token-name{
      margin-left:7px;
    }
    .amount-left{
      display: flex;
    }
    &>img{
      width: 24px;
      height: 24px;
      margin-right:8px;
    }
  }
  span{
    font-size: 18px;
    font-weight: 500;
    color: #281071;
    line-height: 23px;
  }
  .receive-address{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap:9px;
    .title{
      font-size: 14px;
      font-weight: 500;
      color: #3B199F;
      line-height: 18px;
    }
    .receive-address-input{
      width:100%;
      box-shadow: 0px 2px 4px 0px rgba(109,78,201,0.06);
      border-radius: 16px;
      border: 2px solid #FFFFFF;
      padding: 16px;
      background: transparent;
      font-size: 14px;
      font-weight: 400;
      color: #281071;
      line-height: 18px;
      outline: none;
      :hover{
        border: 2px solid rgba(109,78,201,0.57);
      }
      ::placeholder {
        font-size: 14px;
        font-weight: 400;
        color: #ABA5BD;
        line-height: 18px;
      }
    }
  }

  .arrow-bg{
    position: absolute;
    z-index: 100;
    background: #FFFFFF;
    border-radius: 12px;
    border: 2px solid #E3E7F9;
    padding: 4px 6px;
    transform: translate(200px, 104px);
  }
`