import { Trade } from 'so-swap-sdk'
import React, { Fragment, memo } from 'react'
import ChevronRight from '../../assets/Swap/icon_route_arrow.svg'
import { ItemContent, RouteItem } from './styleds'

export default memo(function SwapRoute({ trade }: { trade: Trade }) {
  return (
    <RouteItem>
      {trade.route.path.map((token, i, path) => {
        const isLastItem: boolean = i === path.length - 1
        return (
          <Fragment key={i}>
            <ItemContent>{token.symbol}</ItemContent>
            {isLastItem ? null : <img src={ChevronRight} alt="" style={{ margin: '0px 4px' }} />}
          </Fragment>
        )
      })}
    </RouteItem>
  )
})
