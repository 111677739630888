import axios from 'axios'
import BigNumber from 'bignumber.js'
import useSWR from 'swr'
import { PairItem, CHAINX_EVM_INFO_API } from '../state/info/hook'

const formatTokenAddress = (tokenSymbol: string, tokenAddress: string) => {
  if (tokenSymbol === 'WBTC' || tokenSymbol === 'BTC') return 'BTC'
  return tokenAddress
}

export type PoolItem = {
  id: number
  poolName: string
  liquidityUsd: number
  dailyVolumeUsd: number
  aprDaily: string
  tokenA: string
  tokenB: string
}

export const usePoolsData = (): { data: PoolItem[]; refetch: () => Promise<void>; isLoading: boolean } => {
  const { data, mutate, isLoading, isValidating } = useSWR(
    [`${CHAINX_EVM_INFO_API}/pairs`],
    async ([url]: string[]) => {
      const { data } = await axios.get(url)

      return data.pairList
        .filter(pair => pair?.token0Symbol && pair?.token1Symbol)
        .map((pair: PairItem, index: number) => {
          const { token0Symbol, token0Address, token1Address, token1Symbol, liquidityUsd, dailyVolumeUsd } = pair
          const aprDaily = new BigNumber(dailyVolumeUsd)
            .multipliedBy(0.0025)
            .dividedBy(pair.liquidityUsd || 1)
            .multipliedBy(365)
            .multipliedBy(100)
            .toFixed(2)
          return {
            id: index,
            poolName: `${token0Symbol}-${token1Symbol}`,
            liquidityUsd,
            dailyVolumeUsd,
            aprDaily,
            tokenA: formatTokenAddress(token0Symbol, token0Address),
            tokenB: formatTokenAddress(token1Symbol, token1Address)
          }
        })
        .sort((a, b) => b.liquidityUsd - a.liquidityUsd)
    },
    {
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      refreshWhenOffline: false
    }
  )

  return { data, refetch: mutate, isLoading: isValidating }
}
