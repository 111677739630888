import React from 'react'
import styled from 'styled-components'
import Connecting from '../Connecting';

const Wrapper = styled.div<{ margin: boolean; sizeraw: number }>`
  position: relative;
  display: flex;
  flex-direction: row;
  min-width: 40px;
  .first {
    display: flex;
    position: relative;
    z-index: 1;
    margin: 0 6px;
  }
  .second {
    display: flex;
    position: relative;
    z-index: 1;
    margin: 0 6px;
  }
`

interface DoubleCurrencyLogoProps {
  leftIcon: React.ReactNode
  rightIcon?: React.ReactNode
  margin?: boolean
  size?: number
}

export default function DoubleIcon({ leftIcon, rightIcon, size = 16, margin = false }: DoubleCurrencyLogoProps) {
  return (
    <Wrapper sizeraw={size} margin={margin}>
      {leftIcon && (
        <>
          <div className="first"> {leftIcon} </div>
          <Connecting />
        </>
      )}
      <div className="second"> {rightIcon}</div>
    </Wrapper>
  )
}
