import React from 'react'
import styled from 'styled-components'

const ToggleElement = styled.span<{ isActive?: boolean; isOnSwitch?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
  border-radius: 14px;
  background: ${({ isActive, isOnSwitch }) => (isActive ? (isOnSwitch ? '#48E8CC' : '#E1E0EA') : 'none')};
  color: ${() => '#FFFFFF'};
  opacity: 0.75;
  font-size: 0.825rem;
  font-weight: 400;
  > span {
    padding: 0 2px;
  }
`

const StyledToggle = styled.button<{ isActive?: boolean; activeElement?: boolean }>`
  border-radius: 16px;
  border: 0px;
  display: flex;
  width: fit-content;
  cursor: pointer;
  outline: none;
  padding: 0;
  background-color: transparent;
`

const Circle = styled.div`
  background: #ffffff;
  height: 18px;
  width: 18px;
  border-radius: 18px;
`

export interface ToggleProps {
  id?: string
  isActive: boolean
  toggle: () => void
}

export default function Toggle({ id, isActive, toggle }: ToggleProps) {
  return (
    <StyledToggle id={id} isActive={isActive} onClick={toggle}>
      {isActive && (
        <ToggleElement isActive={isActive} isOnSwitch={true}>
          <span>On</span>
          <Circle />
        </ToggleElement>
      )}
      {!isActive && (
        <ToggleElement isActive={!isActive} isOnSwitch={false}>
          <Circle />
          <span>Off</span>
        </ToggleElement>
      )}
    </StyledToggle>
  )
}
