// import ethereumLogoUrl from 'assets/images/ethereum-logo.png'
// import arbitrumLogoUrl from 'assets/svg/arbitrum_logo.svg'
// import optimismLogoUrl from 'assets/svg/optimistic_ethereum.svg'
// import polygonMaticLogo from 'assets/svg/polygon-matic-logo.svg'
// import ms from 'ms.macro'

// import { ARBITRUM_LIST, OPTIMISM_LIST } from './lists'

/**
 * List of all the networks supported by the Uniswap Interface
 */

export enum SupportedChainId {
  // SHERPAX = 1506,
  // SHERPAXTEST = 1507,
  // BSC = 56,
  // BSCTEST = 97,
  CHAINX = 1501,
  CHAINXTEST = 1502
}

/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(SupportedChainId).filter(
  id => typeof id === 'number'
) as SupportedChainId[]

/**
 * All the chain IDs that are running the Ethereum protocol.
 */
export const L1_CHAIN_IDS = [SupportedChainId.CHAINX] as const

export type SupportedL1ChainId = typeof L1_CHAIN_IDS[number]

/**
 * This is used to call the add network RPC
 */
interface AddNetworkInfo {
  readonly rpcUrl: string
  readonly nativeCurrency: {
    name: string // e.g. 'Goerli ETH',
    symbol: string // e.g. 'gorETH',
    decimals: number // e.g. 18,
  }
}

export enum NetworkType {
  L1,
  L2
}

interface BaseChainInfo {
  readonly networkType: NetworkType
  readonly blockWaitMsBeforeWarning?: number
  //   readonly docs: string
  readonly bridge?: string
  readonly explorer: string
  //   readonly infoLink: string
  readonly logoUrl: string
  readonly label: string
  readonly helpCenterUrl?: string
  readonly addNetworkInfo: AddNetworkInfo
}

export interface L1ChainInfo extends BaseChainInfo {
  readonly networkType: NetworkType.L1
}

export type ChainInfoMap = { readonly [chainId: number]: L1ChainInfo } & {
  readonly [chainId in SupportedL1ChainId]: L1ChainInfo
}

export const CHAIN_INFO: ChainInfoMap = {
  // [SupportedChainId.SHERPAX]: {
  //   networkType: NetworkType.L1,
  //   bridge: 'https://wallet.sherpax.io/',
  //   explorer: 'https://evm.sherpax.io/',
  //   label: 'SHERPAX',
  //   logoUrl: 'SherpaxLogo',
  //   addNetworkInfo: {
  //     rpcUrl: 'https://mainnet.sherpax.io/rpc',
  //     nativeCurrency: { name: 'SHERPAX', symbol: 'BTC', decimals: 18 }
  //   }
  // },
  // [SupportedChainId.SHERPAXTEST]: {
  //   networkType: NetworkType.L1,
  //   bridge: 'https://wallet.sherpax.io/',
  //   explorer: 'https:// evm-pre.sherpax.io/',
  //   label: 'SHERPAXTEST',
  //   logoUrl: 'SherpaxLogo',
  //   addNetworkInfo: {
  //     rpcUrl: 'https://sherpax-testnet.chainx.org/rpc',
  //     nativeCurrency: { name: 'SHERPAX', symbol: 'BTC', decimals: 18 }
  //   }
  // },
  // [SupportedChainId.BSC]: {
  //   networkType: NetworkType.L1,
  //   // blockWaitMsBeforeWarning: ms`10m`,
  //   bridge: 'https://wallet.sherpax.io/',
  //   // docs: 'https://polygon.io/',
  //   explorer: 'https://bscscan.com',
  //   // infoLink: 'https://info.uniswap.org/#/polygon/',
  //   label: 'BSC',
  //   logoUrl: 'SherpaxLogo',
  //   addNetworkInfo: {
  //     rpcUrl: 'https://bsc-dataseed.binance.org/',
  //     nativeCurrency: { name: 'BSC', symbol: 'BNB', decimals: 18 }
  //   }
  // },
  // [SupportedChainId.BSCTEST]: {
  //   networkType: NetworkType.L1,
  //   // blockWaitMsBeforeWarning: ms`10m`,
  //   bridge: 'https://wallet.sherpax.io/',
  //   // docs: 'https://polygon.io/',
  //   explorer: 'https://testnet.bscscan.com/',
  //   // infoLink: 'https://info.uniswap.org/#/polygon/',
  //   label: 'BSCTEST',
  //   logoUrl: 'SherpaxLogo',
  //   addNetworkInfo: {
  //     rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545',
  //     nativeCurrency: { name: 'TBNB', symbol: 'tBNB', decimals: 18 }
  //   }
  // },
  [SupportedChainId.CHAINX]: {
    networkType: NetworkType.L1,
    // blockWaitMsBeforeWarning: ms`10m`,
    bridge: 'https://rpc-canary-1.bevm.io',
    // docs: 'https://polygon.io/',
    explorer: 'https://scan-canary.bevm.io/',
    // infoLink: 'https://info.uniswap.org/#/polygon/',
    label: 'BEVM Canary',
    logoUrl: 'ChainxLogo',
    addNetworkInfo: {
      rpcUrl: 'https://rpc-canary-1.bevm.io',
      nativeCurrency: { name: 'BEVM', symbol: 'BTC', decimals: 18 }
    }
  }
  // [SupportedChainId.CHAINX]: {
  //   networkType: NetworkType.L1,
  //   // blockWaitMsBeforeWarning: ms`10m`,
  //   bridge: 'https://mainnet.chainx.org/rpc',
  //   // docs: 'https://polygon.io/',
  //   explorer: 'https://evm.chainx.org/',
  //   // infoLink: 'https://info.uniswap.org/#/polygon/',
  //   label: 'CHAINX',
  //   logoUrl: 'ChainxLogo',
  //   addNetworkInfo: {
  //     rpcUrl: 'https://testnet3.chainx.org/rpc',
  //     nativeCurrency: { name: 'ChainX', symbol: 'BTC', decimals: 18 }
  //   }
  // }
}
