import React, { useMemo } from 'react'
import styled from 'styled-components'
import { CheckCircle, Triangle } from 'react-feather'

import { ExternalLink } from '../../theme'
import Row, { RowBetween } from '../Row'
import Loader from '../Loader'
import { useActiveWeb3React } from '../../hooks'
import { HistoryItem } from '../../store/bridge'
import dayjs from 'dayjs'
import { TransactionLinkArrow, TransactionStatusText, TransactionTypeText } from '../AccountDetails/Transaction'
import { getMempoolExplorerLink } from '../../utils'
import { Popover } from 'antd'
import { BridgeTxType } from '../../constants/bridge'

const TransactionState = styled(ExternalLink)<{ pending: boolean; success?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  border-radius: 0.5rem;
  font-size: 13px;
  font-weight: 600;
  color: #876cd7;
  line-height: 16px;
  padding: 0.125rem 8px;
  &:hover {
    background: #e9eef6;
  }
`

const IconWrapper = styled.div<{ pending: boolean; success?: boolean }>`
  color: ${({ pending, success, theme }) => (pending ? theme.primary1 : success ? theme.green1 : theme.red1)};
`

export const TransactionConfirmations = styled.div`
  background: #f9fbfd;
  border-radius: 6px;
  border: 1px solid #e8eaf1;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: #876cd7;

  &.success {
    color: #39dcbf;
  }
`

type Props = {
  transaction: HistoryItem
}

export default function Transaction({ transaction }: Props) {
  const { chainId } = useActiveWeb3React()
  const { hash, status, txType, summary, timestamp, confirmations } = transaction
  // const allTransactions =
  //   status === 0
  //     ? JSON.parse(localStorage.getItem('AccountPendingList'))
  //     : JSON.parse(localStorage.getItem('AccountTxList'))
  // const tx = allTransactions?.[hash]
  // const summary = tx?.summary
  // const pending = tx?.pending
  const pending = status === 0
  const success = status === 1

  const textTip = useMemo(() => {
    if (txType === BridgeTxType.Bridge) {
      return 'The top-up operation needs to wait for confirmation from 3 blocks of the Bitcoin network'
    } else {
      return 'Inscribing transfer inscriptions requires 1 confirmation on the Bitcoin network.'
    }
  }, [txType])

  const confirmationTip = useMemo(() => {
    if (txType === BridgeTxType.InscribeTransfer) {
      return success ? '1+ confirmations' : 'Unconfirmed'
    } else {
      return confirmations === 0
        ? 'Unconfirmed'
        : confirmations > 3
        ? '3+ confirmations'
        : `${confirmations} confirmations`
    }
  }, [txType, success, confirmations])

  return (
    <TransactionState href={getMempoolExplorerLink(hash, 'transaction')} pending={pending} success={success}>
      {/*<RowFixed>*/}
      {/*  <TransactionStatusText>{summary ?? hash} ↗</TransactionStatusText>*/}
      {/*</RowFixed>*/}
      <IconWrapper pending={pending} success={success}>
        {pending ? (
          <Loader stroke={'#3B199F'} />
        ) : success ? (
          <CheckCircle size="16" color={'#38DCBF'} />
        ) : (
          <Triangle size="16" color={'#E93E5E'} />
        )}
      </IconWrapper>
      <div
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: '1', marginLeft: '10px' }}
      >
        <RowBetween>
          <TransactionTypeText>{txType} </TransactionTypeText>
          <Row justifyContent={'flex-end'}>
            <Popover trigger={'hover'} content={textTip}>
              <TransactionConfirmations className={success ? 'success' : ''}>
                {confirmationTip}
              </TransactionConfirmations>
            </Popover>
            <TransactionLinkArrow style={{ marginLeft: '6px' }}>↗</TransactionLinkArrow>
          </Row>
        </RowBetween>
        <RowBetween>
          <TransactionStatusText>{summary ?? hash}</TransactionStatusText>
          <TransactionStatusText>
            {dayjs
              .unix(timestamp)
              .locale('en')
              .format('MMM-DD HH:mm')}
          </TransactionStatusText>
        </RowBetween>
      </div>
    </TransactionState>
  )
}
