import React from 'react'
import { TableWrapper } from './styles'
import usePagination from '../../hooks/usePagination'
import { TablePaginationConfig, TableProps } from 'antd'

interface Props extends TableProps<any> {
  columns: any[]
  transactionData: any[]
  paginationClassName?: string
}

export const DEFAULT_PAGE_SIZE = 10

export const DEFAULT_SORT_OPTIONS = ['ascend', 'descend', 'ascend']

const InfoTable: React.FC<Props> = ({ columns, transactionData, paginationClassName, ...rest }: Props) => {
  const paginationOptions = usePagination(transactionData?.length || 0, paginationClassName)

  return (
    <TableWrapper
      loading={!transactionData}
      columns={columns}
      dataSource={transactionData}
      pagination={paginationClassName ? (paginationOptions as TablePaginationConfig) : false}
      {...rest}
    />
  )
}

export default React.memo(InfoTable)
