export const formatRedPacketTimeStamp = inputMap => {
  // 将原始 "timestamp" 字段的值设置为 0
  inputMap['timestamp'] = 0

  // 将当前的映射转换为 JSON 字符串
  const jsonString = JSON.stringify(inputMap)

  // 使用正则表达式计算 JSON 字符串中包含 [ad13579] 这些字符的数量
  const matchCount = jsonString.match(/[ad13579]/g)?.length || 0

  // 获取当前系统时间的时间戳
  const timeStamp = Date.now()

  // 如果 JSON 字符串中包含 [ad13579] 这些字符，则重新计算时间戳，使其符合匹配字符的数量
  const realTimeStamp = matchCount > 0 ? timeStamp - (timeStamp % matchCount) + matchCount : timeStamp

  // 将修改后的映射再次转换为 JSON 字符串并返回
  return realTimeStamp
}
