import React, { useState } from 'react'
import { StepItem, Steps } from '../../components/Steps'
import AppBody from '../AppBody'
import { Step1 } from './Step1'
import { Step2 } from './Step2'
import { Step3 } from './Step3'
import { AddToken } from './AddToken'
import { HistoryList } from './HistoryList'

const RedEnvelope: React.FC = () => {
  const [current, setCurrent] = useState(0)

  const stepItems: StepItem[] = [
    {
      key: '1',
      title: 'Create Red Envelope',
      content: <Step1 nextStep={() => setCurrent(() => 1)} />
    },
    {
      key: '2',
      title: 'Set Red Envelope',
      content: <Step2 backStep={() => setCurrent(() => 0)} nextStep={() => setCurrent(() => 2)} />
    },
    { key: '3', title: 'Send Red Envelope', content: <Step3 backToStart={() => setCurrent(() => 0)} /> }
  ]

  return (
    <>
      {/*<AddToken />*/}
      <AppBody maxWidth={'980px'} padding={'44px 70px 32px 70px'}>
        <Steps current={current} onChange={setCurrent} stepItems={stepItems} />
        {/*<button onClick={() => createRedPacket('2', '10')}>create</button>*/}
        {/*<QRCodeGenerator id={'2112wqeqweqwe'} />*/}
      </AppBody>
      <HistoryList />
    </>
  )
}

export default RedEnvelope
