import React, { useRef, useContext, useState } from 'react'
import { X } from 'react-feather'
import styled from 'styled-components'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { useUserSlippageTolerance, useExpertModeManager, useUserDeadline } from '../../state/user/hooks'
import TransactionSettings from '../TransactionSettings'
import { RowFixed, RowBetween } from '../Row'
import { TYPE } from '../../theme'
import QuestionHelper from '../QuestionHelper'
import Toggle from '../Toggle'
import { ThemeContext } from 'styled-components'
import { AutoColumn } from '../Column'
import { ButtonError } from '../Button'
import { useSettingsMenuOpen, useToggleSettingsMenu } from '../../state/application/hooks'
import { Text } from 'rebass'
import Modal from '../Modal'
import Dialog from '../Dialog'
import SwapModalContainer from '../swap/SwapModalContainer'
import settingIcon from '../../assets/Swap/icon_setting.svg'
import IconTipDark from '../../assets/images/icon_tip_dark.svg'
import WramingIcon from '../../assets/images/icon_warming.svg'

const EmojiWrapper = styled.div`
  position: absolute;
  bottom: -6px;
  right: 0px;
  font-size: 14px;
`
const StyledMenuButton = styled.button`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  background-color: #ffffff;
  padding: 8px;
  border-radius: 12px;
  :hover,
  :active,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }
  > img {
    width: 20px;
  }
`

const StyledMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span`
  min-width: 20.125rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 3rem;
  right: 0rem;
  z-index: 100;
  background: #f1f5fb;
  box-shadow: 0px 4px 10px 0px rgba(0, 1, 1, 0.17);
  border-radius: 24px;
  border: 1px solid #ffffff;
`
export const ModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 20px;
  width: 100%;
`

export const StyledCloseIcon = styled(X)`
  height: 20px;
  width: 20px;
  :hover {
    cursor: pointer;
  }

  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

export const Break = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.bg3};
`

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  .warningWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 0;
  }
`

export default function SettingsTab() {
  const node = useRef<HTMLDivElement>()
  const open = useSettingsMenuOpen()
  const toggle = useToggleSettingsMenu()

  const theme = useContext(ThemeContext)
  const [userSlippageTolerance, setUserslippageTolerance] = useUserSlippageTolerance()

  const [deadline, setDeadline] = useUserDeadline()

  const [expertMode, toggleExpertMode] = useExpertModeManager()

  // const [darkMode, toggleDarkMode] = useDarkModeManager()
  // show confirmation view before turning on
  const [showConfirmation, setShowConfirmation] = useState(false)

  useOnClickOutside(node, open ? toggle : undefined)

  const openExpertMessage = (
    <MessageWrapper>
      <Text fontWeight={500} fontSize={16} color={'#222222'}>
        Expert mode turns off the confirm transaction prompt and allows high slippage trades that often result in bad
        rates and lost funds.
      </Text>
      <div className={'warningWrapper'}>
        <img src={WramingIcon} alt="" style={{ marginRight: '8px', width: '24px' }} />
        <Text fontWeight={600} fontSize={18} color={'#3B199F'}>
          ONLY USE THIS MODE IF YOU KNOW WHAT YOU ARE DOING.
        </Text>
      </div>
    </MessageWrapper>
  )
  return (
    <StyledMenu ref={node as any}>
      <Modal isOpen={showConfirmation} onDismiss={() => setShowConfirmation(false)} maxHeight={100}>
        <ModalContentWrapper>
          <Dialog
            className="currency-search-dialog"
            bodyStyle={{
              padding: '0'
            }}
            visible={true}
            title={
              <Text fontWeight={600} fontSize={16} color="#281071" lineHeight="20px">
                Are you sure?
              </Text>
            }
            onCancel={() => setShowConfirmation(false)}
            footer={null}
          >
            <SwapModalContainer
              topBox={openExpertMessage}
              bottomBox={
                <ButtonError
                  error={true}
                  padding={'16px'}
                  onClick={() => {
                    if (window.prompt(`Please type the word "confirm" to enable expert mode.`) === 'confirm') {
                      toggleExpertMode()
                      setShowConfirmation(false)
                    }
                  }}
                >
                  <Text fontSize={20} fontWeight={500} id="confirm-expert-mode">
                    Turn On Expert Mode
                  </Text>
                </ButtonError>
              }
            />
          </Dialog>
        </ModalContentWrapper>
      </Modal>
      <StyledMenuButton onClick={toggle} id="open-settings-dialog-button">
        <img src={settingIcon} alt="" />
        {expertMode && (
          <EmojiWrapper>
            <span role="img" aria-label="wizard-icon">
              🧙
            </span>
          </EmojiWrapper>
        )}
      </StyledMenuButton>
      {open && (
        <MenuFlyout>
          <AutoColumn gap="md" style={{ padding: '1rem' }}>
            <Text fontWeight={600} fontSize={14}>
              Transaction Settings
            </Text>
            <TransactionSettings
              rawSlippage={userSlippageTolerance}
              setRawSlippage={setUserslippageTolerance}
              deadline={deadline}
              setDeadline={setDeadline}
            />
            <Text fontWeight={600} fontSize={14}>
              Interface Settings
            </Text>
            <RowBetween>
              <RowFixed>
                <TYPE.black fontWeight={400} fontSize={14} color={theme.text2}>
                  Toggle Expert Mode
                </TYPE.black>
                <QuestionHelper
                  icon={IconTipDark}
                  text="Bypasses confirmation modals and allows high slippage trades. Use at your own risk."
                />
              </RowFixed>
              <Toggle
                id="toggle-expert-mode-button"
                isActive={expertMode}
                toggle={
                  expertMode
                    ? () => {
                        toggleExpertMode()
                        setShowConfirmation(false)
                      }
                    : () => {
                        toggle()
                        setShowConfirmation(true)
                      }
                }
              />
            </RowBetween>
          </AutoColumn>
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
