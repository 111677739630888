/** @format */

import React, { createContext, useContext, useEffect, useState } from 'react'
import { useActiveWeb3React } from './index'

const UNISAT_WALLET = 'UnisatWallet'

export interface AccountItem {
  evmAddress: string
  btcAddress: string
}

export interface TransactionDetails {
  hash: string
  summary?: string
  from: string
  receipt?: string
  addedTime: number
  confirmedTime?: number
  pending: boolean
  result: boolean
  type: string
  address: string
}

export enum BTCNetwork {
  testnet = 'testnet',
  livenet = 'livenet'
}

export enum BtcAddressType {
  'Native Segwit' = 'Native Segwit',
  'Nested Segwit' = 'Nested Segwit',
  Taproot = 'Taproot',
  Legacy = 'Legacy'
}

export interface AccountsProviderData {
  currentAccount: AccountItem
  setCurrentAccount: (account: AccountItem) => void
  unisatTxHistory: any[]
  setUnisatTxHistory: (tx: string) => void
  connectUnisatWallet: () => Promise<unknown>
  disconnectUnisatWallet: () => Promise<void>
  currentBtcNetwork: BTCNetwork
}

export const AccountsContext = createContext<AccountsProviderData>({} as AccountsProviderData)

export const AccountsProvider: React.FC = ({ children }) => {
  const { account } = useActiveWeb3React()
  const [currentAccount, setCurrentAccount] = useState<AccountItem>(null)
  const [unisatTxHistory, setUnisatTxHistory] = useState<any>(JSON.parse(localStorage.getItem('AccountTxList') || '[]'))
  const [currentBtcNetwork, setCurrentBtcNetwork] = useState<BTCNetwork>(BTCNetwork.livenet)

  const connectUnisat = async (): Promise<unknown> => {
    console.log('connect----')
    if (typeof window?.unisat === 'undefined') {
      window.open('https://chrome.google.com/webstore/detail/unisat-wallet/ppbibelpcjmhbdihakflkdcoccbgbkpo')
      return
    }
    try {
      const [account] = await window?.unisat?.requestAccounts()
      setCurrentAccount(currentAccount => ({
        ...currentAccount,
        btcAddress: account
      }))
      window.localStorage.setItem(UNISAT_WALLET, 'connected')
    } catch (e) {
      console.error('Connect failed', e)
    }
  }

  const disconnectUnisat = async (): Promise<void> => {
    console.log('clear------')
    setCurrentAccount(currentAccount => ({
      ...currentAccount,
      btcAddress: ''
    }))
    window.localStorage.removeItem(UNISAT_WALLET)
  }

  useEffect(() => {
    const localWallet = window.localStorage.getItem(UNISAT_WALLET)
    if (localWallet && localWallet === 'connected') {
      connectUnisat()
    }

    const accountChangeHandler = (accounts: string[]) => {
      setCurrentAccount(currentAccount => ({
        ...currentAccount,
        btcAddress: accounts?.[0] ?? ''
      }))
    }
    window?.unisat?.on('accountsChanged', accountChangeHandler)

    window?.unisat?.getNetwork().then(network => setCurrentBtcNetwork(network))
    const networkChangeHandler = (network: BTCNetwork) => {
      setCurrentBtcNetwork(network)
    }
    window?.unisat?.on('networkChanged', networkChangeHandler)

    return () => {
      window?.unisat?.removeListener('accountsChanged', accountChangeHandler)
      window?.unisat?.removeListener('networkChanged', networkChangeHandler)
    }
  }, [])

  useEffect(() => {
    setCurrentAccount(currentAccount => ({
      ...currentAccount,
      evmAddress: account
    }))
  }, [account])


  return (
    <AccountsContext.Provider
      value={{
        currentAccount,
        setCurrentAccount,
        unisatTxHistory,
        setUnisatTxHistory,
        connectUnisatWallet: connectUnisat,
        disconnectUnisatWallet: disconnectUnisat,
        currentBtcNetwork
      }}
    >
      {children}
    </AccountsContext.Provider>
  )
}

export const useAccounts = (): AccountsProviderData => {
  return useContext(AccountsContext)
}
