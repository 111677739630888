import React from 'react'
import { TYPE } from '../../theme'

interface LiquiditySwapDialogTipsProps {
  allowedSlippagePercent: string
}

export const LiquidityDialogTips: React.FC<LiquiditySwapDialogTipsProps> = ({
  allowedSlippagePercent
}: LiquiditySwapDialogTipsProps) => {
  return (
    <TYPE.italic fontSize={12} textAlign="left" padding={'16px 16px 0 16px'}>
      {`Output is estimated. If the price changes by more than ${allowedSlippagePercent} your transaction will revert.`}
    </TYPE.italic>
  )
}
