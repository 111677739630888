import React, { useMemo } from 'react'
import { usePoolsVolumeData } from '../../state/info/hook'
import InfoTable, { DEFAULT_SORT_OPTIONS } from './index'
import TokenName from './TokenName'
import { TableTitle } from './styles'
import { isMobile } from 'react-device-detect'

const columns = [
  {
    title: '#',
    dataIndex: '#',
    key: '#',
    align: 'center',
    className: 'prefix-id',
    render: (text, record, index) => `${index + 1}`
  },
  {
    title: 'POOL',
    dataIndex: 'pool',
    key: 'pool',
    align: 'left'
  },
  {
    title: 'TVL',
    dataIndex: 'tvl',
    key: 'tvl',
    align: 'left',
    sortDirections: DEFAULT_SORT_OPTIONS,
    defaultSortOrder: 'descend',
    showSorterTooltip: false,
    sorter: (item0, item1) => {
      return item0.tvlNum - item1.tvlNum
    }
  },
  {
    title: 'VOLUME 24H',
    dataIndex: 'volumeDaily',
    key: 'volumeDaily',
    align: 'left',
    sortDirections: DEFAULT_SORT_OPTIONS,
    showSorterTooltip: false,
    sorter: (item0, item1) => {
      return item0.volumeDailyNum - item1.volumeDailyNum
    }
  },
  {
    title: 'VOLUME 7D',
    dataIndex: 'volumeSevenDay',
    key: 'volumeSevenDay',
    align: 'left',
    sortDirections: DEFAULT_SORT_OPTIONS,
    showSorterTooltip: false,
    sorter: (item0, item1) => {
      return item0.volumeSevenDayNum - item1.volumeSevenDayNum
    }
  }
]

const mobileColumns = [
  {
    title: 'POOL',
    dataIndex: 'pool',
    key: 'pool',
    align: 'left'
  },
  {
    title: 'VOLUME 24H',
    dataIndex: 'volumeDaily',
    key: 'volumeDaily',
    align: 'left',
    sortDirections: DEFAULT_SORT_OPTIONS,
    showSorterTooltip: false,
    sorter: (item0, item1) => {
      console.log(item0.volumeDailyNum)
      return item0.volumeDailyNum - item1.volumeDailyNum
    }
  }
]

const TopPoolsTable: React.FC = () => {
  const { data } = usePoolsVolumeData()

  const dataSource = useMemo(() => {
    return data?.map(pool => ({
      key: `${pool.poolName}-${pool.id}`,
      pool: <TokenName name={pool.poolName} subName={null} />,
      tvl: pool.liquidityUsd,
      volumeDaily: pool.dailyVolumeUsd,
      volumeSevenDay: pool.sevenDayVolumeUsd,
      tvlNum: pool.liquidityUsdNum,
      volumeDailyNum: pool.dailyVolumeUsdNum,
      volumeSevenDayNum: pool.sevenDayVolumeUsdNum
    }))
  }, [data])

  return (
    <>
      <TableTitle>Top Pools</TableTitle>
      <InfoTable
        columns={isMobile ? mobileColumns : columns}
        transactionData={dataSource}
        paginationClassName={'pagination-top-pools'}
      />
    </>
  )
}

export default React.memo(TopPoolsTable)
