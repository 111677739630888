import React from 'react'
import { AutoColumn } from '../../components/Column'
import { AutoRow } from '../../components/Row'
import { TYPE } from '../../theme'
import { Text } from 'rebass'

export interface PoolPriceShareProps {
  poolPriceInfoList: { price: string; name: string }[]
  fontSizeOfPrice: number
  fontSizeOfName: number
}

const PoolPriceShare: React.FC<PoolPriceShareProps> = ({
  poolPriceInfoList,
  fontSizeOfPrice,
  fontSizeOfName
}: PoolPriceShareProps) => {
  return (
    <AutoColumn gap="md">
      <AutoRow justify="space-around" gap="4px">
        {poolPriceInfoList.map(info => (
          <AutoColumn key={info.name} style={{ width: 'auto' }} justify="center">
            <TYPE.black fontSize={fontSizeOfPrice} fontWeight={600} color="#281071">
              {info.price}
            </TYPE.black>
            <Text fontWeight={500} fontSize={fontSizeOfName} color="#988EB7" pt={1}>
              {info.name}
            </Text>
          </AutoColumn>
        ))}
      </AutoRow>
    </AutoColumn>
  )
}

export default React.memo(PoolPriceShare)
