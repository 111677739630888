import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { ethers } from 'ethers'
import axios from 'axios'
import dayjs from 'dayjs'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import warningIcon from '../../assets/RedEnvelope/icon_warning.svg'
import { calculateCRC64 } from '../../helper/generateCID'
import { formatRedPacketTimeStamp } from '../../helper/formatRedPacketTimeStamp'
import useRedEnvelopeIdsStore from '../../store/redEnvelopeIds'
import { Loading } from '../../components/Loading'
import { COMING_ZERO_API, promotionTwitterEnUs, promotionTwitterZhCn } from '../../constants/redEnvelope'
import { EnvelopeAnimation } from './EnvelopeAnimation'
import { RedEnvelopeType } from '../../store/redEnvelope'
import IconPacket from '../../assets/RedEnvelope/icon_packet.svg'
import { ButtonLightGreen, ButtonNext } from '../../components/Button'
import { ReactComponent as IconX } from '../../assets/RedEnvelope/icon_x.svg'
import { Text } from 'rebass'
import { toLocaleString } from '../../utils/toLocaleString'

const EnvelopeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  padding-bottom: 62px;
  margin-top: ${isMobile ? '0' : '-28px'};
  .title {
    font-size: 14px;
    font-weight: 400;
    color: #988eb7;
    line-height: 17px;
    margin-top: ${isMobile ? '11.6vw' : '0'};
    margin-bottom: ${isMobile ? '4.26vw' : '20px'};
  }
  .content {
    height: ${isMobile ? '6vw' : '22px'};
    font-size: 16px;
    font-weight: 500;
    color: #281071;
    line-height: 20px;
    margin-bottom: ${isMobile ? '4.03vw' : '15px'};
  }
  .envelope-icon-closed {
    width: ${isMobile ? '71.7vw' : '337px'};
    height: ${isMobile ? '42.4vw' : '199px'};
    margin-bottom: ${isMobile ? '3.2vw' : '3px'};
  }
  .envelope-icon-opened {
    width: ${isMobile ? '46.53vw' : '229px'};
    height: ${isMobile ? '40vw' : '206px'};
    margin-bottom: ${isMobile ? '11.7vw' : '24px'};
  }
  .open-envelope {
    border-radius: ${isMobile ? '3.2vw' : '12px'};
    background-color: #d3d6f3;
    width: ${isMobile ? '91.47vw' : '440px'};
    height: ${isMobile ? '14.4vw' : '54px'};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid white;
  }
  .envelope-amount {
    font-size: 24px;
    font-weight: 600;
    color: #281071;
    line-height: 29px;
    margin-bottom: ${isMobile ? '5.87vw' : '24px'};
    margin-top: ${isMobile ? '-1vw' : '-13px'};
  }
  .open-btn {
    width: ${isMobile ? '22.13vw' : '83px'};
    height: ${isMobile ? '12.26vw' : '46px'};
    border-radius: ${isMobile ? '2.13vw' : '8px'};
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin: ${isMobile ? '1vw' : '4px'};
  }
  .button-group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    width: 110%;
  }
  .send-btn {
    width: 200px;
    height: 46px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    white-space: nowrap;
  }
  .view-history {
    font-size: 12px;
    font-weight: 500;
    color: #988eb7;
    line-height: 15px;
    text-decoration: underline;
    margin-top: ${isMobile ? '3.2vw' : '16px'};
  }
  .btn-text {
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    line-height: 17px;
  }
  .active {
    background: #48e8cc;
    box-shadow: 0px 2px 4px 0px rgba(78, 91, 201, 0.12);
    border: none;
  }
  .disabled {
    background: #d4d9f0;
    box-shadow: 0px 2px 4px 0px rgba(78, 91, 201, 0.12), inset 0px 0px 5px 0px rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(77, 43, 178, 0.08);
  }
  .warning {
    width: ${isMobile ? '89.5vw' : '326px'};
    height: ${isMobile ? '10vw' : '38px'};
    background: #ffffff;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
    border-radius: ${isMobile ? '3.2vw' : '12px'};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    ${isMobile ? 'top: 0' : 'bottom: 10px'};
    & > img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
    & > span {
      font-size: 12px;
      font-weight: 500;
      color: #e93e5e;
      line-height: 22px;
    }
  }
`

const Input = styled.input`
  border-radius: 12px;
  background-color: #d3d6f3;
  border: none;
  width: ${isMobile ? '68.2vw' : '353px'};
  height: ${isMobile ? '12.26vw' : '52px'};
  outline: none;
  color: white;
  padding: 16px;
  ::placeholder {
    color: #988eb7;
  }
`
const EnvelopeInfo = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #988eb7;
  line-height: 16px;
  padding: 10px 0 30px 0;
  border-top: 1px solid #d3d6f3;
  display: flex;
  align-items: flex-start;
  word-break: break-all;
  img {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
`

interface EidInfoProps {
  isLoading: boolean
  eid: string
  eName: string
  isFinished: boolean
  userOpenedList: string[]
  userCanOpen: boolean
  type: RedEnvelopeType
  amount: string
  hasOpenedAmount: string
  packetNumber: string
  hasOpenedPacketNumber: string
  coinName: string
}
const initEIDInfo: EidInfoProps = {
  isLoading: false,
  eid: '',
  eName: '',
  isFinished: false,
  userOpenedList: [''],
  userCanOpen: false,
  type: RedEnvelopeType.random,
  amount: '0',
  hasOpenedAmount: '0',
  packetNumber: '0',
  hasOpenedPacketNumber: '0',
  coinName: ''
}

export default function Envelope(): React.ReactElement {
  const inputRef = useRef<HTMLInputElement>()
  const { setReceivedIds } = useRedEnvelopeIdsStore()
  const { search } = useLocation()
  const [address, setAddress] = useState('')
  const [isOpenSuccess, setIsOpenSuccess] = useState(false)
  const [openEnvelopeAmount, setOpenEnvelopeAmount] = useState('')
  const [eCIDInfo, setECIDInfo] = useState<EidInfoProps>({ ...initEIDInfo, eid: search.slice(5) ?? '' })
  const [warning, setWarning] = useState(null)
  const [isOpenedButtonClicked, setOpenedButtonClicked] = useState(false)
  const { push } = useHistory()

  const getEidInfo = async () => {
    setECIDInfo({ ...eCIDInfo, isLoading: true })
    const params = {
      redEnvelopId: eCIDInfo.eid,
      cid: '000',
      timestamp: formatRedPacketTimeStamp({
        redEnvelopId: eCIDInfo.eid,
        cid: '000',
        timestamp: 0
      })
    }
    try {
      const { data } = await axios.post(`${COMING_ZERO_API}/redenvelop/info`, params)
      if (data) {
        const {
          finished,
          words,
          luckyAccountList,
          luckyAmount,
          allocation,
          amount,
          number,
          luckyNumber,
          coinName,
          status
        } = data
        const info = {
          type: allocation === 1 ? RedEnvelopeType.fixed : RedEnvelopeType.random,
          amount: toLocaleString(amount),
          hasOpenedAmount: toLocaleString(luckyAmount),
          packetNumber: number,
          hasOpenedPacketNumber: luckyNumber,
          coinName
        }
        if (finished === 1 && status === 4) {
          setECIDInfo({ ...eCIDInfo, ...info, eName: words, isLoading: false, isFinished: true, userCanOpen: false })
          setWarning("You're too late! The red envelope was taken out !")
        } else {
          const openedAccountList = []
          luckyAccountList?.map(item => openedAccountList.push(item.cid))
          setECIDInfo({
            ...eCIDInfo,
            ...info,
            eName: words,
            isLoading: false,
            userOpenedList: openedAccountList,
            userCanOpen: true
          })
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
  const openEnvelop = async (eId: string, address: string) => {
    if (!ethers.utils.isAddress(address)) {
      setWarning('Invalid evm address')
      return 'error'
    }

    const cid = calculateCRC64(address)
    if (eCIDInfo?.userOpenedList.find(item => item === cid))
      return setWarning('You have already opened this red envelope ')

    try {
      setOpenedButtonClicked(true)

      const { data } = await axios.post(`${COMING_ZERO_API}/redenvelop/open`, {
        redEnvelopId: eId,
        cid,
        address: address,
        timestamp: formatRedPacketTimeStamp({
          redEnvelopId: eId,
          cid,
          address: address,
          timestamp: 0
        })
      })
      setTimeout(() => {
        if (data?.status === 1) {
          const { luckyAmount } = data
          setIsOpenSuccess(true)
          setOpenedButtonClicked(false)
          setOpenEnvelopeAmount(luckyAmount)
          setReceivedIds(address, [{ timestamp: dayjs().unix(), id: eId }])
          getEidInfo()
        } else {
          setWarning("You're too late! The red envelope was taken out !")
        }
      }, 2000)
    } catch (e) {
      console.error(e)
      setWarning("You're too late! The red envelope was taken out !")
    } finally {
    }
  }

  const onShare = () => {
    let shareText = ''
    if (navigator.language.includes('zh')) {
      shareText = `${promotionTwitterZhCn} #sats #bevm ${window.location.href}`
    } else {
      shareText = `${promotionTwitterEnUs} #sats #bevm ${window.location.href}`
    }
    const twitterLink = encodeURI(`https://twitter.com/intent/tweet?text=${shareText}`).replace(
      new RegExp(/(#)/g),
      '%23'
    )
    window.open(twitterLink)
  }

  useEffect(() => {
    setECIDInfo({ ...initEIDInfo, eid: search.slice(5) ?? '' })
    getEidInfo()
  }, [])

  return (
    <EnvelopeWrapper>
      {warning && (
        <div className="warning">
          <img src={warningIcon} alt="" />
          <span>{warning}</span>
        </div>
      )}
      <div className="title">You Received a {eCIDInfo?.coinName} Red Envelope</div>
      <div className="content">{eCIDInfo?.eName}</div>
      {/*<img*/}
      {/*  className={isOpenSuccess ? 'envelope-icon-opened' : 'envelope-icon-closed'}*/}
      {/*  src={isOpenSuccess ? envelopeOpenIcon : envelopeCloseIcon}*/}
      {/*  alt=""*/}
      {/*/>*/}
      <EnvelopeAnimation
        isOpenedButtonClicked={isOpenedButtonClicked}
        isOpened={isOpenSuccess}
        onClick={() => openEnvelop(eCIDInfo.eid, address)}
        openedAmount={openEnvelopeAmount}
        disabled={!address || !eCIDInfo?.userCanOpen}
        coinSymbol={eCIDInfo?.coinName}
      />
      {isOpenSuccess ? (
        <>
          {eCIDInfo.eName && (
            <EnvelopeInfo>
              <img src={IconPacket} alt="" />
              {eCIDInfo?.type} Opened: {eCIDInfo?.hasOpenedPacketNumber}/{eCIDInfo?.packetNumber}, Amount:{' '}
              {eCIDInfo?.hasOpenedAmount}/{eCIDInfo?.amount}
            </EnvelopeInfo>
          )}
          <div className="button-group">
            <ButtonLightGreen className="send-btn" onClick={() => push('/redEnvelope?received-list')}>
              Received Envelopes
            </ButtonLightGreen>
            <ButtonNext width={'47%'} onClick={onShare}>
              Share to <IconX style={{ width: '16px', height: '16px', marginLeft: '6px' }} />
            </ButtonNext>
          </div>
          <Text
            fontSize={14}
            fontWeight={400}
            color={'#988EB7'}
            lineHeight={'16px'}
            style={{ textDecoration: 'underline', cursor: 'pointer', margin: '14px' }}
            onClick={() =>
              window.open(
                'https://bswap.gitbook.io/bswap-1/features-and-tutorial-of-bswap/how-to-send-receive-brc20-red-envelop'
              )
            }
          >
            How to claim token
          </Text>
          {/*<Link className="view-history" to={'/redEnvelope'}>*/}
          {/*  View red envelope history*/}
          {/*</Link>*/}
        </>
      ) : (
        <div className="open-envelope">
          <Input
            ref={inputRef as any}
            placeholder={'Input EVM address'}
            value={address}
            onChange={e => {
              setAddress(e.target.value)
              setWarning(null)
            }}
          />
          <button
            disabled={!address || !eCIDInfo?.userCanOpen}
            className={`open-btn ${address && eCIDInfo?.userCanOpen ? 'active' : 'disabled'}`}
            onClick={() => openEnvelop(eCIDInfo.eid, address)}
          >
            <div className="btn-text">
              {eCIDInfo?.isLoading && ethers.utils.isAddress(address) ? <Loading size={16} /> : 'Open'}
            </div>
          </button>
        </div>
      )}
    </EnvelopeWrapper>
  )
}
