import React, { useCallback } from 'react'
import styled from 'styled-components'
import DoubleCurrencyLogo from '../DoubleLogo'
import CurrencyLogo from '../CurrencyLogo'
import DropDownBlue from '../../assets/Swap/icon_dropDown_blue.svg'
import DropDownWhite from '../../assets/Swap/icon_dropDown_white.svg'
import { Pair } from 'so-swap-sdk'
import { useBridgeIcon } from '../../hooks/useBridgeIcon'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'

export const CurrencySelect = styled.button<{ purpleSelect: string; selectPointer: boolean }>`
  align-items: center;
  height: 2.2rem;
  font-size: 20px;
  font-weight: 500;
  background-color: ${({ purpleSelect }) => (purpleSelect ? purpleSelect : '#f5f6f9')};
  border-radius: 16px;
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  padding: 0 0.5rem;

  :hover {
    background-color: ${({ purpleSelect }) => (purpleSelect ? '#908ACC' : '#EAEAF3')};
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledTokenName = styled.span<{ isSelected?: string }>`
  margin: 0 6px;
  font-size: 18px;
  color: ${({ isSelected }) => (isSelected ? '#281071' : '#FFFFFF')};
`

interface Props {
  currency?: any
  currencySymbol: string
  onClick?: () => any
  disableCurrencySelect?: boolean
  pair?: Pair | null
  backgroundPurple?: boolean
  showOptionsList?: boolean
  isBridge?: boolean
}

export default function ShowSelectedBtn({
  backgroundPurple,
  onClick,
  currency,
  disableCurrencySelect,
  pair,
  currencySymbol,
  showOptionsList,
  isBridge = false
}: Props) {
  const { error, account } = useWeb3React()
  const getIcon = useBridgeIcon()
  const selectedTo = useCallback(() => {
    let result = ''
    if (currencySymbol && currencySymbol.length > 20) {
      result =
        currencySymbol.slice(0, 4) + '...' + currencySymbol.slice(currencySymbol.length - 5, currencySymbol.length)
    } else {
      result = currencySymbol
    }
    return result
  }, [currencySymbol])

  return (
    <CurrencySelect
      purpleSelect={backgroundPurple && '#B5ADFF'}
      className="open-currency-select-button"
      onClick={onClick}
      selectPointer={disableCurrencySelect}
      disabled={error instanceof UnsupportedChainIdError || !account}
    >
      <Aligner>
        {/*是不是成对的币*/}
        {pair ? (
          <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={24} margin={true} />
        ) : currency && currency?.logoURI ? (
          <img src={currency?.logoURI} alt="" style={{ width: '24px' }} />
        ) : (
          <>
            {isBridge
              ? getIcon(currency?.symbol)
              : currency?.symbol && !currency?.icon && <CurrencyLogo currency={currency} size={'24px'} />}
            {currency?.icon && <img src={currency?.icon} alt="" style={{ width: '24px' }} />}
          </>
        )}
        {pair ? (
          <StyledTokenName>
            {pair?.token0.symbol}:{pair?.token1.symbol}
          </StyledTokenName>
        ) : (
          <StyledTokenName isSelected={selectedTo()}>{selectedTo() || 'select a token'}</StyledTokenName>
        )}
        {showOptionsList && <img src={selectedTo() ? DropDownBlue : DropDownWhite} alt="" />}
      </Aligner>
    </CurrencySelect>
  )
}
