import { Dropdown } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'
import ShowSelectedBtn from '../swap/showSelectedBtn'
import { isMobile } from 'react-device-detect'
import { BridgeTokenItem } from '../../constants/bridge'
import { useBridgeIcon } from '../../hooks/useBridgeIcon'

export const SelectResult = styled.ul<{ listStyle?: string }>`
  //min-width: ${!isMobile && '280px'};
  //position: ${isMobile ? 'relative' : 'absolute'};
  list-style: none;
  z-index: 2;
  right: 0;
  background: #f1f5fb;
  border-radius: ${!isMobile && '16px'};
  border-top: 12px solid #f1f5fb;
  border-bottom: 12px solid #f1f5fb;
  box-shadow: 0px 0px 1px rgb(0 0 0 / 1%), 0px 4px 8px rgb(0 0 0 / 4%), 0px 16px 24px rgb(0 0 0 / 4%),
    0px 24px 32px rgb(0 0 0 / 1%);
  max-height: 15rem;
  overflow-y: scroll;
  color: #565a69;
  font-weight: 500;
  margin: 0;
  padding-inline-start: 0;
  width: 120%;
  ${({ listStyle }) => listStyle && listStyle};

  //&::-webkit-scrollbar {
  //  width: 5px;
  //  height: 8px;
  //  background-color: red;
  //}
  //&::-webkit-scrollbar-thumb {
  //  background: #000;
  //}
  li {
    padding: 0 16px;
    margin-bottom: 8px;
    &:hover {
      cursor: pointer;
      background: #ffffff;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .rowItem {
    display: flex;
    align-items: center;
    //min-width: 280px;
    > img {
      width: 24px;
      height: 24px;
    }
    > div {
      flex-direction: row;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 500;
      color: #281071;
      line-height: 20px;
    }
    .Title {
      flex-direction: column;
      padding: 11px 0;
      margin-left: 8px;
      > div:nth-child(2) {
        font-size: 12px;
        font-weight: 400;
        color: #aba5bd;
        line-height: 17px;
      }
    }
    .balance {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-left: 16px;
      overflow-x: scroll;
    }
  }
`

interface Props {
  options: BridgeTokenItem[]
  selectedToken: BridgeTokenItem
  setToken: (token: BridgeTokenItem) => void
}

const AntdSelect: React.FC<Props> = ({ selectedToken, setToken, options }: Props) => {
  const [open, setOpen] = useState(false)
  const getIcon = useBridgeIcon()

  const onSelect = token => {
    setToken(token)
    setOpen(false)
  }

  const menu = (
    <SelectResult>
      {options?.map(option => (
        <li key={option?.symbol} onClick={() => onSelect(option)}>
          <div className="rowItem">
            {getIcon(option.symbol)}
            <div className="Title">
              <div>{option?.symbol}</div>
              {/*<div>{option?.name}</div>*/}
            </div>
            {/*<div className="balance">{option?.balance}</div>*/}
          </div>
        </li>
      ))}
    </SelectResult>
  )
  return (
    <>
      {/*{isMobile ? (*/}
      {/*  <>*/}
      {/*    <ShowSelectedBtn*/}
      {/*      backgroundPurple={currentObj && currentObj.length === 0}*/}
      {/*      disableCurrencySelect={open}*/}
      {/*      onClick={() => setOpen(true)}*/}
      {/*      currency={currentObj}*/}
      {/*      currencySymbol={value}*/}
      {/*      showOptionsList={options.length > 1}*/}
      {/*    />*/}
      {/*    <Dialog*/}
      {/*      overflow={'visible'}*/}
      {/*      className="currency-search-dialog"*/}
      {/*      bodyStyle={{*/}
      {/*        padding: '0',*/}
      {/*        display: 'flex',*/}
      {/*        flexDirection: 'column',*/}
      {/*        overflowX: 'inherit',*/}
      {/*        overflowY: 'inherit'*/}
      {/*      }}*/}
      {/*      title={*/}
      {/*        <Text fontWeight={600} fontSize={16} color="#281071" lineHeight="20px">*/}
      {/*          Select a token*/}
      {/*        </Text>*/}
      {/*      }*/}
      {/*      visible={open}*/}
      {/*      onCancel={() => setOpen(false)}*/}
      {/*      footer={null}*/}
      {/*    >*/}
      {/*      {menu}*/}
      {/*    </Dialog>*/}
      {/*  </>*/}
      {/*) : (*/}
      <Dropdown overlay={menu} trigger={['click']}>
        <ShowSelectedBtn
          backgroundPurple={false}
          disableCurrencySelect={open}
          // onClick={()=>setOpen(true)}
          currency={selectedToken}
          currencySymbol={selectedToken?.symbol}
          showOptionsList={options?.length > 1}
          isBridge
        />
      </Dropdown>
      {/*)}*/}
    </>
  )
}

export default AntdSelect
