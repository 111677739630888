import { Trade } from 'so-swap-sdk'
import React from 'react'
import styled from 'styled-components'
import { TYPE } from '../../theme'
import { isAddress, shortenAddress } from '../../utils'
import { AutoColumn } from '../Column'
import { RowBetween, RowFixed } from '../Row'
import switchIcon from '../../assets/Swap/icon_trade_arrow.svg'
import PanelContainer from '../PanelContainer'
import ShowSelectedBtn from './showSelectedBtn'

const ItemContent = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #281071;
  line-height: 29px;
`
export default function SwapModalHeader({
  trade,
  recipient
}: {
  trade: Trade
  allowedSlippage: number
  recipient: string | null
  showAcceptChanges: boolean
  onAcceptChanges: () => void
}) {

  const top = (
    <RowBetween align="flex-end" padding={'18px 16px !important'}>
      <RowFixed gap={'0px'}>
        <ItemContent> {trade.inputAmount.toSignificant(6)}</ItemContent>
      </RowFixed>
      <RowFixed gap={'0px'}>
        <ShowSelectedBtn
          disableCurrencySelect={true}
          pair={null}
          currencySymbol={trade.inputAmount.currency.symbol}
          currency={trade.inputAmount.currency}
        />
      </RowFixed>
    </RowBetween>
  )

  const bottom = (
    <RowBetween align="flex-end" padding={'18px 16px !important'}>
      <RowFixed gap={'0px'}>
        <ItemContent> {trade.outputAmount.toSignificant(6)}</ItemContent>
      </RowFixed>
      <RowFixed gap={'0px'}>
        <ShowSelectedBtn
          disableCurrencySelect={true}
          pair={null}
          currencySymbol={trade.outputAmount.currency.symbol}
          currency={trade.outputAmount.currency}
        />
      </RowFixed>
    </RowBetween>
  )

  return (
    <AutoColumn gap={'md'}>
      <PanelContainer
        topBox={top}
        bottomBox={bottom}
        switchIcon={<img src={switchIcon} />}
        switchIconPosition={'margin-left:44px'}
      />

      {recipient !== null ? (
        <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
          <TYPE.main>
            Output will be sent to{' '}
            <b title={recipient}>{isAddress(recipient) ? shortenAddress(recipient) : recipient}</b>
          </TYPE.main>
        </AutoColumn>
      ) : null}
    </AutoColumn>
  )
}
