import React from 'react'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { useActiveWeb3React } from '../../hooks'

interface Props {
  address?: string
  size: number
}

const EvmAvatar: React.FC<Props> = ({ address, size }: Props) => {
  const { account } = useActiveWeb3React()

  return <Jazzicon diameter={size} seed={jsNumberForAddress(address ? address : account)} />
}

export default React.memo(EvmAvatar)
