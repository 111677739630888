import { ChainId } from 'so-swap-sdk'
import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import Modal from '../Modal'
import { Text } from 'rebass'
import { CloseIcon, Spinner } from '../../theme/components'
import { RowBetween } from '../Row'
import { ArrowUpCircle } from 'react-feather'
import { ButtonPrimary } from '../Button'
import { AutoColumn, ColumnCenter } from '../Column'
import Circle from '../../assets/Swap/loading.svg'

import { useActiveWeb3React } from '../../hooks'
import SwapModalContainer from '../swap/SwapModalContainer'
import Dialog from '../Dialog'
import { getEtherscanLink } from '../../utils'
import WramingIcon from '../../assets/images/icon_warming.svg'
import AddTokenInMetamask from '../AddTokenInMetamask'
import loadingAnimation from '../../assets/Assets/loading.json'
import Lottie from 'react-lottie'
import { ReactComponent as IconTip } from '../../assets/images/icon_tip.svg'

const Wrapper = styled.div`
  width: 100%;
  background: #f1f5fb;
  box-shadow: 0 4px 10px 0 rgba(0, 1, 1, 0.17);
  border: 1px solid #ffffff;
`
const Section = styled(AutoColumn)`
  padding: 24px;
`

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 30px 0;
`

const CustomLightSpinner = styled(Spinner)<{ size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`

function ConfirmationPendingContent({ onDismiss, pendingText }: { onDismiss: () => void; pendingText: string }) {
  return (
    <Wrapper>
      <Section>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CloseIcon onClick={onDismiss} />
        </div>
        {/*<ConfirmedIcon>*/}
        {/*  <CustomLightSpinner src={Circle} alt="loader" size={'98px'} />*/}
        {/*</ConfirmedIcon>*/}
        <Lottie options={{ loop: true, autoplay: true, animationData: loadingAnimation }} />
        <AutoColumn justify={'center'}>
          <Text fontWeight={600} fontSize={18} color={'#3B199F'} paddingBottom={'16px'}>
            Waiting For Confirmation
          </Text>
          <AutoColumn justify={'center'}>
            <Text fontWeight={500} fontSize={16} color="" textAlign="center" paddingBottom={'8px'}>
              {pendingText}
            </Text>
          </AutoColumn>
          <Text fontSize={14} fontWeight={500} color="#988EB7" textAlign="center">
            Confirm this transaction in your wallet
          </Text>
          {/*<TipWrapper>*/}
          {/*  <IconTip />*/}
          {/*</TipWrapper>*/}
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  tokenAddress,
  tokenDecimals,
  tokenSymbol,
  tokenLogoURI
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  tokenAddress?: string
  tokenDecimals?: number
  tokenSymbol?: string
  tokenLogoURI?: string
}) {
  const theme = useContext(ThemeContext)

  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <div />
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <ConfirmedIcon>
          <ArrowUpCircle strokeWidth={0.5} size={72} color={'#37DCBF'} />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify={'center'}>
          <Text fontWeight={500} color={'#3B199F'} fontSize={20}>
            Transaction Submitted
          </Text>
          <a href={getEtherscanLink(chainId, hash, 'transaction')} target="_blank" rel="noreferrer">
            <Text fontWeight={500} color={'#876CD7'} fontSize={14}>
              View on scan
            </Text>
          </a>
          {tokenAddress && tokenSymbol !== 'BTC' && (
            <AddTokenInMetamask
              address={tokenAddress}
              symbol={tokenSymbol}
              decimals={tokenDecimals}
              image={tokenLogoURI}
            />
          )}
          <ButtonPrimary onClick={onDismiss} style={{ margin: '20px 0 0 0' }}>
            <Text fontWeight={500} fontSize={20}>
              Close
            </Text>
          </ButtonPrimary>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  title,
  bottomContent,
  onDismiss,
  topContent
}: {
  title: string
  onDismiss: () => void
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}) {
  return (
    <Dialog
      className="currency-search-dialog"
      bodyStyle={{
        padding: '0'
      }}
      visible={true}
      title={
        <Text fontWeight={600} fontSize={16} color="#281071" lineHeight="20px">
          {title}
        </Text>
      }
      onCancel={onDismiss}
      footer={null}
    >
      <SwapModalContainer topBox={topContent()} bottomBox={bottomContent()} />
    </Dialog>
  )
}

export function TransactionErrorContent({ message, onDismiss }: { message: string; onDismiss: () => void }) {
  const theme = useContext(ThemeContext)
  return (
    <Dialog
      className="currency-search-dialog"
      bodyStyle={{
        padding: '0'
      }}
      visible={true}
      title={
        <Text fontWeight={600} fontSize={16} color="#281071" lineHeight="20px">
          Error
        </Text>
      }
      onCancel={onDismiss}
      footer={null}
    >
      <SwapModalContainer
        topBox={
          <AutoColumn style={{ marginTop: 20, padding: '2rem 0' }} gap="24px" justify="center">
            <img src={WramingIcon} alt="" style={{ width: '64px' }} />
            <Text fontWeight={500} fontSize={16} color={theme.red1} style={{ textAlign: 'center', width: '85%' }}>
              {message}
            </Text>
          </AutoColumn>
        }
        bottomBox={<ButtonPrimary onClick={onDismiss}>Dismiss</ButtonPrimary>}
      />
    </Dialog>
  )
}

interface ConfirmationModalProps {
  isOpen: boolean
  onDismiss: () => void
  hash: string | undefined
  content: () => React.ReactNode
  attemptingTxn: boolean
  pendingText: string
  tokenAddress?: string
  tokenDecimals?: number
  tokenSymbol?: string
  tokenLogoURI?: string
}

export default function TransactionConfirmationModal({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,
  content,
  tokenAddress,
  tokenDecimals,
  tokenSymbol,
  tokenLogoURI
}: ConfirmationModalProps): React.ReactElement {
  const { chainId } = useActiveWeb3React()

  if (!chainId) return null

  // confirmation screen
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      {attemptingTxn ? (
        <ConfirmationPendingContent onDismiss={onDismiss} pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent
          tokenAddress={tokenAddress}
          tokenDecimals={tokenDecimals}
          tokenSymbol={tokenSymbol}
          tokenLogoURI={tokenLogoURI}
          chainId={chainId}
          hash={hash}
          onDismiss={onDismiss}
        />
      ) : (
        content()
      )}
    </Modal>
  )
}
