import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useActiveWeb3React } from '../../hooks'
import { useAddPopup, useBlockNumber } from '../application/hooks'
import { AppDispatch, AppState } from '../index'
import { checkedTransaction, finalizeTransaction } from './actions'
import { BridgeTxType } from '../../constants/bridge'
import httpRequest from '../../utils/httpRequest'
import { CHAINX_BRIDGE_ADDRESS, COMING_ZERO_API } from '../../constants/btc'

export function shouldCheck(
  lastBlockNumber: number,
  tx: { addedTime: number; receipt?: {}; lastCheckedBlockNumber?: number }
): boolean {
  if (tx.receipt) return false
  if (!tx.lastCheckedBlockNumber) return true
  const blocksSinceCheck = lastBlockNumber - tx.lastCheckedBlockNumber
  if (blocksSinceCheck < 1) return false
  const minutesPending = (new Date().getTime() - tx.addedTime) / 1000 / 60
  if (minutesPending > 60) {
    // every 10 blocks if pending for longer than an hour
    return blocksSinceCheck > 9
  } else if (minutesPending > 5) {
    // every 3 blocks if pending more than 5 minutes
    return blocksSinceCheck > 2
  } else {
    // otherwise every block
    return true
  }
}

export default function Updater(): null {
  const { chainId, library } = useActiveWeb3React()

  const lastBlockNumber = useBlockNumber()

  const dispatch = useDispatch<AppDispatch>()
  const state = useSelector<AppState, AppState['transactions']>(state => state.transactions)

  const transactions = chainId ? state[chainId] ?? {} : {}

  // show popup on confirm
  const addPopup = useAddPopup()

  useEffect(() => {
    if (!chainId || !library || !lastBlockNumber) return

    Object.keys(transactions)
      .filter(hash => shouldCheck(lastBlockNumber, transactions[hash]))
      .forEach(hash => {
        // if (transactions[hash].txType === BridgeTxType.Bridge) {
        //   httpRequest
        //     .get(`${COMING_ZERO_API}/bswap/bridgeRecord`, { params: { hash } })
        //     .then(res => {
        //       if (res?.data?.targetHash) {
        //         const targetHash = res?.data?.targetHash
        //         httpRequest
        //           .post(`${COMING_ZERO_API}/bswap/btcTxStatus`, {
        //             hashes: [targetHash]
        //           })
        //           .then(res => {
        //             const status: boolean = res?.data?.status?.[targetHash]?.confirmations >= 3
        //             const confirmations: number = res?.data?.status?.[targetHash]?.confirmations
        //             if (status) {
        //               dispatch(
        //                 finalizeTransaction({
        //                   chainId,
        //                   hash,
        //                   receipt: {
        //                     blockHash: res?.data?.targetHash,
        //                     blockNumber: res?.data?.originBlockHeight,
        //                     contractAddress: CHAINX_BRIDGE_ADDRESS,
        //                     from: res?.data?.senderAddress,
        //                     status: 1,
        //                     to: res?.data?.targetAddress,
        //                     transactionHash: res?.data?.originHash,
        //                     transactionIndex: 0
        //                   }
        //                 })
        //               )
        //             } else {
        //               dispatch(checkedTransaction({ chainId, hash, blockNumber: lastBlockNumber }))
        //             }
        //           })
        //       }
        //     })
        //     .catch(error => {
        //       console.error(`failed to check transaction hash: ${hash}`, error)
        //     })
        // } else {
        library
          .getTransactionReceipt(hash)
          .then(receipt => {
            if (receipt) {
              dispatch(
                finalizeTransaction({
                  chainId,
                  hash,
                  receipt: {
                    blockHash: receipt.blockHash,
                    blockNumber: receipt.blockNumber,
                    contractAddress: receipt.contractAddress,
                    from: receipt.from,
                    status: receipt.status,
                    to: receipt.to,
                    transactionHash: receipt.transactionHash,
                    transactionIndex: receipt.transactionIndex
                  }
                })
              )

              addPopup(
                {
                  txn: {
                    hash,
                    success: receipt.status === 1,
                    summary: transactions[hash]?.summary
                  }
                },
                hash
              )
            } else {
              dispatch(checkedTransaction({ chainId, hash, blockNumber: lastBlockNumber }))
            }
          })
          .catch(error => {
            console.error(`failed to check transaction hash: ${hash}`, error)
          })
        // }
      })
  }, [chainId, library, transactions, lastBlockNumber, dispatch, addPopup])

  return null
}
