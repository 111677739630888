import styled from 'styled-components'
import React from 'react'
import useRedEnvelopeStore, { RedEnvelopeType } from '../../store/redEnvelope'
import { ReactComponent as IconRandom } from '../../assets/RedEnvelope/icon_random.svg'
import { ReactComponent as IconFixed } from '../../assets/RedEnvelope/icon_fixed.svg'
import { ReactComponent as IconSelected } from '../../assets/RedEnvelope/icon_selected.svg'
import { ButtonNext } from '../../components/Button'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  .type-selector {
    display: flex;
    justify-content: center;
    padding: 64px 0 56px 0;
    gap: 24px;
    .type-item {
      background: #ffffff;
      border-radius: 12px;
      border: 2px solid #988eb7;
      width: 112px;
      height: 112px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;
      color: #281071;
      line-height: 17px;
      cursor: pointer;
      position: relative;
      padding-top: 12px;
      &.selected {
        background: #dcd8ff;
      }
      svg {
        width: 24px;
        height: 24px;
        margin-bottom: 15px;
      }
      .icon-selected {
        position: absolute;
        left: 7px;
        top: 7px;
        width: 24px;
        height: 24px;
      }
    }
  }
`

const redEnvelopeTypes: RedEnvelopeType[] = [RedEnvelopeType.random, RedEnvelopeType.fixed]

interface Props {
  nextStep: () => void
}

export const Step1: React.FC<Props> = ({ nextStep }: Props) => {
  const { createParams, setCreateParams } = useRedEnvelopeStore()

  const onSelect = (type: RedEnvelopeType) => {
    setCreateParams({ type })
  }

  return (
    <Wrapper>
      <div className={'type-selector'}>
        {redEnvelopeTypes.map(type => (
          <div
            onClick={() => onSelect(type)}
            key={type}
            className={`type-item${createParams.type === type ? ' selected' : ''}`}
          >
            {createParams.type === type && <IconSelected className={'icon-selected'} />}
            {type === RedEnvelopeType.random ? <IconRandom /> : <IconFixed />}
            {type}
          </div>
        ))}
      </div>

      <ButtonNext onClick={nextStep}>Continue</ButtonNext>
    </Wrapper>
  )
}
