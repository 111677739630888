import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { useBridgeIcon } from '../../hooks/useBridgeIcon'
import { Text } from 'rebass'
import { JSBI, Pair } from 'so-swap-sdk'
import { useTotalSupply } from '../../data/TotalSupply'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { formatInfoTablePrice } from '../../helper/formatPrice'
import { RemoveButtonLink } from '../../components/Button'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { Percent } from '@uniswap/sdk'
import BigNumber from 'bignumber.js'
import { ReactComponent as Arrow } from '../../assets/Pools/icon_arrow_right.svg'
import { useHistory } from 'react-router-dom'

const PoolItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(109, 78, 201, 0.06);
  border-radius: 24px;
  padding: 24px;
  color: #281071;
  cursor: pointer;
  margin-bottom: 12px;
  .item-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
    width: 100%;
    .pool-name {
      display: flex;
      align-items: center;
    }
    .link-lp {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #fbfbfd;
      border: 1px solid #f0f2f5;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
  .item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`

const InfoItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 4px;
`

const getTokenAddressToLink = (symbol: string, address: string): string => {
  if (symbol === 'WBTC' || symbol === 'BTC') {
    return 'BTC'
  }
  return address
}

interface Props {
  pair: Pair
  liquidityValue: string | number
  apr: string | number
}

const PoolItem: React.FC<Props> = ({ pair, apr, liquidityValue }: Props) => {
  const getIcon = useBridgeIcon()
  const { account } = useActiveWeb3React()
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)
  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)
  const { push } = useHistory()

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
        ]
      : [undefined, undefined]
  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const infoList: { title: string; value: string }[] = useMemo(() => {
    const ownLiquidity = new BigNumber(poolTokenPercentage?.toSignificant(6) || 0)
      .dividedBy(100)
      .multipliedBy(liquidityValue || 0)
      .toNumber()
    return [
      { title: 'Est. Value ', value: `$ ${formatInfoTablePrice(ownLiquidity)}` },
      { title: currency0.symbol, value: token0Deposited?.toSignificant(6) },
      { title: currency1.symbol, value: token1Deposited?.toSignificant(6) },
      { title: 'Fee Tier', value: '0.25%' },
      { title: 'Estimated APR', value: `${apr}%` }
    ]
  }, [poolTokenPercentage, token0Deposited, token1Deposited, pair, liquidityValue, apr])

  const onLink = useCallback(() => {
    const address0 = getTokenAddressToLink(pair.token0.symbol, pair.token0.address)
    const address1 = getTokenAddressToLink(pair.token1.symbol, pair.token1.address)
    push(`/poolDetails/${address0}/${address1}`)
  }, [pair.token0.symbol, pair.token0.address, pair.token1.symbol, pair.token1.address])

  return (
    <PoolItemWrapper onClick={onLink}>
      <div className={'item-top'}>
        <div className={'pool-name'}>
          {getIcon(pair.token0.symbol)}
          {getIcon(pair.token1.symbol)}
          <Text marginLeft={'12px'} fontSize={18} fontWeight={600} lineHeight={'21px'}>
            {currency0.symbol} - {currency1.symbol}
          </Text>
        </div>
        <div className={'link-lp'}>
          <Arrow />
        </div>
        {/*<RemoveButtonLink to={`/remove/${currencyId(currency0)}/${currencyId(currency1)}`}>Remove</RemoveButtonLink>*/}
      </div>
      <div className={'item-bottom'}>
        {infoList.map(i => (
          <InfoItem title={i.title} value={i.value} key={i.title} />
        ))}
        <div />
      </div>
    </PoolItemWrapper>
  )
}

export default React.memo(PoolItem)

const InfoItem: React.FC<{ title: string; value: string }> = ({ title, value }: { title: string; value: string }) => {
  return (
    <InfoItemWrapper>
      <Text paddingBottom={'7px'} fontSize={14} fontWeight={500} color={'#988EB7'} lineHeight={'17px'}>
        {title}
      </Text>
      <Text fontSize={16} fontWeight={500} lineHeight={'20px'}>
        {value}
      </Text>
    </InfoItemWrapper>
  )
}
