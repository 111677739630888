import React from 'react'
import { TokenNameWrapper } from './styles'
import { useBridgeIcon } from '../../hooks/useBridgeIcon'

interface Props {
  name: string
  subName: React.ReactNode
}

const TokenName: React.FC<Props> = ({ name, subName }: Props) => {
  const getIcon = useBridgeIcon()
  const tokenName = name.includes('Token') ? (subName as string).slice(1).slice(0, -1) : name
  const index = tokenName.indexOf('/') > 0 ? tokenName.indexOf('/') : tokenName.indexOf('-')
  const tokenNames = index > 0 ? [tokenName.slice(0, index), tokenName.slice(index + 1)] : [tokenName]
  const tokenIcons = tokenNames?.map(i => ({
    name: i,
    // icon: getIcon(i)
    icon: <></>
  }))

  // console.log('tokenIcons', tokenIcons)

  return (
    <TokenNameWrapper>
      {tokenIcons.map(i => (
        <React.Fragment key={i.name}>{i.icon}</React.Fragment>
      ))}
      <span className={'token-name'}>
        {name} {subName}
      </span>
    </TokenNameWrapper>
  )
}

export default React.memo(TokenName)
