import BigNumber from 'bignumber.js'

export const transformBigNumber = (bigNumber: number | string, decimals: number, precision = 8): string => {
  if (bigNumber === undefined || bigNumber === null || !decimals) {
    return undefined
  }
  return new BigNumber(bigNumber).dividedBy(Math.pow(10, decimals)).toFixed(precision)
}

export const transformBigNumberNoPrecision = (bigNumber: number | string, decimals: number): string => {
  if (bigNumber === undefined || bigNumber === null || !decimals) {
    return undefined
  }
  return new BigNumber(bigNumber).dividedBy(Math.pow(10, decimals)).toString()
}
