import { create } from 'zustand'
import {
  BITCOIN_API,
  BRC20_DESTINATION_ADDRESS,
  BTC_DESTINATION_ADDRESS,
  COMING_ZERO_API,
  DEFAULT_BTC,
  MEMPOOL_API,
  OKLINK_API,
  OKLINK_API_KEY,
  TX_FEE_RATE_API
} from '../constants/btc'
import { BridgeChain, BridgeTokenItem, BridgeTokenType, BridgeTxType } from '../constants/bridge'
import dayjs from 'dayjs'
import httpRequest from '../utils/httpRequest'
import axios from 'axios'

const DEFAULT_CHAIN_LIST = [BridgeChain.bitcoin, BridgeChain.chainx]

export const unisatWalletHeader = (sourceAddress: string) => ({
  'X-Address': sourceAddress,
  'X-Channel': 'store',
  'X-Client': 'UniSat Wallet',
  'X-Udid': 'yksbNL5jPHsp',
  'X-Version': '1.1.23'
})

export const oklinkApiHeader = (): Record<string, string> => ({
  'Ok-Access-Key': OKLINK_API_KEY
})

const BTC_TRANSACTION = 'LOCAL_BTC_TRANSACTIONS'
const BITCOIN_TOKEN_LIST = 'LOCAL_BITCOIN_TOKEN_LIST'

type TxType = 'btc' | 'evm' | undefined

export type TransferableInscription = {
  amount: string
  inscriptionId: string
  inscriptionNumber: number
  ticker: string
}

export type HistoryItem = {
  hash: string
  summary: string
  timestamp: number
  txType: BridgeTxType
  status: number
  confirmations: number
}

export type BtcFeeRate = {
  rapidFee: number
  fastFee: number
  normalFee: number
}

interface BridgeStore {
  txType: TxType
  setTxType: (val: TxType) => void
  transferableInscriptionList: TransferableInscription[]
  availableInscription: {
    amount: string
    ticker: string
  }
  selectedInscription: TransferableInscription[]
  setSelectedInscription: (inscription: TransferableInscription | string) => void
  selectOrClearAllInscription: (val: boolean) => void
  loadingInscription: boolean
  setLoadingInscription: (val: boolean) => void
  getTransferableInscriptionList: (sourceAddress: string, tick: string) => Promise<void>
  loadingUnConfirmedInscriptions: boolean
  setLoadingUnConfirmedInscriptions: (val: boolean) => void
  unConfirmedInscriptions: TransferableInscription[]
  setUnConfirmedInscriptions: (inscriptions: TransferableInscription[]) => void
  getBTCTransferTransaction: (
    sourceAddress: string,
    evmAddress: string,
    feeRate: number,
    { type, data, addition }: { type: 'token' | 'inscription' | 'satpoint'; data: string; addition?: string[] }
  ) => Promise<string>
  fromChain: string
  setFromChain: (val: string) => void
  toChain: string
  setToChain: (val: string) => void
  chainList: string[]
  fromToken: BridgeTokenItem
  setFromToken: (token: BridgeTokenItem) => void
  amount: string
  setAmount: (val: string) => void
  getWithdrawEstGas: (
    tokenAddress: string,
    amount: string,
    destinationAddress: string,
    feeRate: number,
    protocol?: string
  ) => Promise<string>
  btcHistoryTxList: Record<string, HistoryItem[]>
  addHistoryTxItem: (txItem: Omit<HistoryItem, 'status' | 'timestamp' | 'confirmations'>, account: string) => void
  updateHistoryStatus: (txIds: string[], account: string) => Promise<void>
  clearHistoryList: (account: string) => void

  bridgeWithdrawFee: string
  setBridgeWithdrawFee: (val: string) => void

  bridgeTokenList: {
    [BridgeChain.chainx]: Omit<BridgeTokenItem, 'tick'>[]
    [BridgeChain.bitcoin]: Omit<BridgeTokenItem, 'decimal'>[]
  }
  setBridgeTokenList: (chainName: BridgeChain, list: BridgeTokenItem[]) => void
  pushPsbtTransaction: (commit: string, reveal: string[]) => Promise<string>
  getTxHashByOrderId: (orderId: string) => Promise<string>
  currentBtcFeeRate: number
  setCurrentBtcFeeRate: (val: number) => void
  loadingBtcFeeRates: boolean
  btcFeeRates: BtcFeeRate | null
  getBtcFeeRates: () => Promise<BtcFeeRate>
}

const useBridgeStore = create<BridgeStore>((set, get) => ({
  txType: undefined,
  setTxType: val => set({ txType: val }),
  transferableInscriptionList: [],
  availableInscription: {
    amount: '0',
    ticker: ''
  },
  selectedInscription: [],
  setSelectedInscription: data => {
    if (typeof data === 'string') {
      data = get().transferableInscriptionList.find(
        i => i.inscriptionId.toLowerCase() === (data as string).toLowerCase()
      )
    }
    set(() => ({ selectedInscription: [data as TransferableInscription] }))
    //todo
    // let inscription: TransferableInscription
    // if (typeof data === 'string') {
    //   inscription = get().transferableInscriptionList?.find(
    //     i => i.inscriptionId.toLowerCase() === (data as string).toLowerCase()
    //   )
    // } else {
    //   inscription = data
    // }
    // const targetInscription = get().selectedInscription.find(
    //   i => i.inscriptionId.toLowerCase() === inscription.inscriptionId.toLowerCase()
    // )
    // if (targetInscription) {
    //   const resultInscriptionList = get().selectedInscription.filter(
    //     i => i.inscriptionId.toLowerCase() !== targetInscription.inscriptionId.toLowerCase()
    //   )
    //   if (get().selectedInscription.length !== 1) {
    //     set(() => ({ selectedInscription: resultInscriptionList }))
    //   }
    // } else {
    //   set(() => ({ selectedInscription: [...get().selectedInscription, inscription as TransferableInscription] }))
    // }
  },
  selectOrClearAllInscription: val => {
    if (val) {
      set(() => ({ selectedInscription: get().transferableInscriptionList }))
    } else {
      set(() => ({
        selectedInscription: get().transferableInscriptionList?.[0] ? [get().transferableInscriptionList?.[0]] : []
      }))
    }
  },
  loadingInscription: false,
  setLoadingInscription: val => set(() => ({ loadingInscription: val })),
  getTransferableInscriptionList: async (sourceAddress, tick) => {
    if (!tick || !sourceAddress) return
    try {
      set(() => ({ loadingInscription: true }))
      const { data } = await httpRequest.get(`${OKLINK_API}/v5/explorer/btc/address-balance-details`, {
        params: {
          address: sourceAddress,
          token: tick
        },
        headers: oklinkApiHeader()
      })

      const resultData = data?.data?.[0]
      const list = (resultData?.transferBalanceList || []).map(i => ({
        ...i,
        ticker: tick,
        inscriptionNumber: `#${i.inscriptionNumber}`
      }))
      const availableInscriptionAmount = resultData?.availableBalance
      set(() => ({ availableInscription: { amount: availableInscriptionAmount ?? '0', ticker: tick } }))
      if (list?.length > 0) {
        set(() => ({
          transferableInscriptionList: [...list],
          selectedInscription: [list[0]],
          amount: list[0].amount
        }))
      } else {
        set(() => ({ transferableInscriptionList: [] }))
      }
    } catch (e) {
      throw e
    } finally {
      set(() => ({ loadingInscription: false }))
    }
  },
  loadingUnConfirmedInscriptions: false,
  setLoadingUnConfirmedInscriptions: val => set(() => ({ loadingUnConfirmedInscriptions: val })),
  unConfirmedInscriptions: [],
  setUnConfirmedInscriptions: inscriptions => {
    set(() => ({ unConfirmedInscriptions: inscriptions }))
  },
  getBTCTransferTransaction: async (sourceAddress, evmAddress, feeRate, { type, data, addition = [] }) => {
    const destinationAddress = type === BridgeTokenType.token ? BTC_DESTINATION_ADDRESS : BRC20_DESTINATION_ADDRESS
    if (type === BridgeTokenType.token) {
      data = `${data} BTC`
    }
    const brc20Transfer = type === BridgeTokenType.inscription

    // const { data: feeRate } = await httpRequest.get(TX_FEE_RATE_API)
    const {
      data: { transaction, network_fee }
    } = await httpRequest.post(`${BITCOIN_API}/transfer`, {
      jsonrpc: '2.0',
      id: 1,
      method: 'transfer',
      params: {
        source: sourceAddress,
        // 手续费率
        fee_rate: feeRate,
        op_return: evmAddress,
        destination: destinationAddress,
        // destination: 'tb1pza3xsl2jkedrl53d39hvxtktvfdhg09v8z4976wxkq0uhmgsqp9slx3h23',
        outgoing: data,
        brc20_transfer: brc20Transfer,
        addition_outgoing: addition
      }
    })

    const signatureTransaction = await window?.unisat?.signPsbt(transaction)
    const txId = await window?.unisat?.pushPsbt(signatureTransaction)
    console.log('res', txId)
    return txId
  },
  fromChain: BridgeChain.bitcoin,
  setFromChain: val => {
    if (get().toChain === val) {
      set(() => ({ toChain: get().fromChain }))
    }
    set(() => ({
      fromChain: val,
      fromToken: get().bridgeTokenList?.[val]?.[0]
    }))
  },
  toChain: BridgeChain.chainx,
  setToChain: val => {
    if (get().fromChain === val) {
      set(() => ({ fromChain: get().toChain }))
    }
    set(() => ({
      toChain: val,
      fromToken: get().bridgeTokenList?.[get().toChain]?.[0]
    }))
  },
  chainList: DEFAULT_CHAIN_LIST,
  fromToken: DEFAULT_BTC,
  setFromToken: token => {
    set(() => ({ fromToken: token, amount: '' }))
  },
  amount: '',
  setAmount: val => set(() => ({ amount: val })),
  bridgeWithdrawFee: '0',
  setBridgeWithdrawFee: val => set(() => ({ bridgeWithdrawFee: val })),
  getWithdrawEstGas: async (tokenAddress, amount, destinationAddress, feeRate, protocol = 'brc-20') => {
    // const { data: feeRate } = await httpRequest.get(TX_FEE_RATE_API)

    const { data } = await httpRequest.get(`${COMING_ZERO_API}/bswap/estimateBridgeGas`, {
      params: {
        tokenAddress,
        amount,
        toAddress: destinationAddress,
        protocol,
        feeRate
      }
    })
    set(() => ({ bridgeWithdrawFee: data?.estGas }))
    return data?.estGas ?? '0'
  },
  btcHistoryTxList: JSON.parse(window.localStorage.getItem(BTC_TRANSACTION) || '{}'),
  addHistoryTxItem: (txItem, account) => {
    const list = get().btcHistoryTxList[account] ?? []

    const newTxItem: HistoryItem = {
      ...txItem,
      timestamp: parseInt((dayjs().valueOf() / 1000).toString()),
      status: 0,
      confirmations: 0
    }
    const newHistoryList = [...list, newTxItem]
    set(() => ({ btcHistoryTxList: { [account]: newHistoryList } }))
    window.localStorage.setItem(BTC_TRANSACTION, JSON.stringify({ [account]: newHistoryList }))
  },
  updateHistoryStatus: async (txIds, account) => {
    const { data } = await httpRequest.post(`${COMING_ZERO_API}/bswap/btcTxStatus`, {
      hashes: txIds
    })
    const list = (get().btcHistoryTxList[account] || []).filter(i => Boolean(i.hash))
    const formatTxIds = txIds.map(i => i.toLowerCase())

    for (let i = 0; i < list.length; i++) {
      const item = list[i]
      if (formatTxIds.includes(item.hash.toLowerCase())) {
        const status: boolean = data?.status?.[item.hash]?.confirmations >= 3
        const confirmations: number = data?.status?.[item.hash]?.confirmations
        if (status) {
          item.status = status ? 1 : 0
        }
        if (confirmations !== item.confirmations) {
          item.confirmations = confirmations
        }
      }
    }
    set(() => ({ btcHistoryTxList: { [account]: list } }))
    window.localStorage.setItem(BTC_TRANSACTION, JSON.stringify({ [account]: list }))
  },
  clearHistoryList: account => {
    if (!account) return
    const ownHistoryList = {
      ...get().btcHistoryTxList,
      [account]: []
    }
    set(() => ({ btcHistoryTxList: ownHistoryList }))
    window.localStorage.setItem(BTC_TRANSACTION, JSON.stringify(ownHistoryList))
  },
  bridgeTokenList: {
    // [BridgeChain.bitcoin]: JSON.parse(window.localStorage.getItem(BITCOIN_TOKEN_LIST) || null) || [DEFAULT_BTC],
    [BridgeChain.bitcoin]: [DEFAULT_BTC],
    [BridgeChain.chainx]: [DEFAULT_BTC]
  },
  setBridgeTokenList: (chainName, list) => {
    set(() => ({
      bridgeTokenList: {
        ...get().bridgeTokenList,
        [chainName]: [DEFAULT_BTC, ...list]
      }
    }))
  },
  pushPsbtTransaction: async (commit, reveal) => {
    const { data } = await axios.put(`${COMING_ZERO_API}/ordinal/tx/BitBox`, {
      commit,
      reveal
    })
    return data?.orderId
  },
  getTxHashByOrderId: async orderId => {
    const { data } = await httpRequest.get(`${COMING_ZERO_API}/ordinal/tx/brc20`, {
      params: {
        pageSize: 1,
        pageStart: 0,
        orderId
      }
    })
    return data?.data?.[0]?.tx || ''
  },
  // bitcoinTokenList: JSON.parse(window.localStorage.getItem(BITCOIN_TOKEN_LIST) || null) || [DEFAULT_BTC],
  // setBitcoinTokenList: list => {
  //   const newTokenList = [...get().bitcoinTokenList, ...list]
  //   set(() => ({ bitcoinTokenList: newTokenList }))
  //   window.localStorage.setItem(BITCOIN_TOKEN_LIST, JSON.stringify(newTokenList))
  // }
  currentBtcFeeRate: 0,
  setCurrentBtcFeeRate: val => set(() => ({ currentBtcFeeRate: val })),
  btcFeeRates: null,
  loadingBtcFeeRates: true,
  getBtcFeeRates: async () => {
    const { data } = await axios.get(TX_FEE_RATE_API)
    set(() => ({
      btcFeeRates: { rapidFee: data?.fastestFee, fastFee: data?.halfHourFee, normalFee: data?.hourFee },
      currentBtcFeeRate: data?.fastestFee,
      loadingBtcFeeRates: false
    }))
    return data
  }
}))

export default useBridgeStore
