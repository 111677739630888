import React, { useCallback, useState } from 'react'
import { useActiveWeb3React } from '../../hooks'
import { Input as NumericalInput } from '../../components/NumericalInput'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import CurrencyLogo from '../../components/CurrencyLogo'
import DropDown from '../../assets/images/dropdown.svg'
import CurrencySearchDialog from '../../components/Dialog/CurrencySearchDialog'
import { Currency, Pair } from 'so-swap-sdk'
import { Aligner, Container, InputPanel, InputRow, StyledBalanceMax, StyledTokenName } from './RemoveCurrencyPairPanel'
import { CurrencySelect } from '../../components/PanelCard'

interface Props {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
}

const CurrencyNoBalanceInputPanel: React.FC<Props> = ({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  pair = null,
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { account } = useActiveWeb3React()

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  return (
    <InputPanel id={id}>
      <Container hideInput={hideInput}>
        <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}} selected={disableCurrencySelect}>
          {!hideInput && (
            <>
              <NumericalInput
                className="token-amount-input"
                value={value}
                onUserInput={val => {
                  onUserInput(val)
                }}
              />
              {account && currency && showMaxButton && (
                <StyledBalanceMax style={{ marginRight: '8px' }} onClick={onMax}>
                  MAX
                </StyledBalanceMax>
              )}
            </>
          )}
          <CurrencySelect
            purpleSelect={!(currency && currency.symbol) && '#B5ADFF'}
            className="open-currency-select-button"
            onClick={() => {
              if (!disableCurrencySelect) {
                setModalOpen(true)
              }
            }}
          >
            <Aligner>
              {pair ? (
                <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={24} margin={true} />
              ) : currency ? (
                <CurrencyLogo currency={currency} size={'28px'} />
              ) : null}
              {pair ? (
                <StyledTokenName className="pair-name-container">
                  {pair?.token0.symbol}:{pair?.token1.symbol}
                </StyledTokenName>
              ) : (
                <StyledTokenName className="token-symbol-container" active={Boolean(currency && currency.symbol)}>
                  {(currency && currency.symbol && currency.symbol.length > 20
                    ? currency.symbol.slice(0, 4) +
                      '...' +
                      currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                    : currency?.symbol) || 'select a token'}
                </StyledTokenName>
              )}
              {!disableCurrencySelect && <img src={DropDown} alt="" />}
            </Aligner>
          </CurrencySelect>
        </InputRow>
      </Container>
      {!disableCurrencySelect && onCurrencySelect && (
        <CurrencySearchDialog
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
        />
      )}
    </InputPanel>
  )
}

export default CurrencyNoBalanceInputPanel
