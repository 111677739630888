import styled from 'styled-components'

export const StyledBalanceMax = styled.button`
  background-color: #e2fffa;
  border: 1px solid transparent;
  border-radius: 8px;
  font-size: 12px;
  padding: 5px 8px;
  font-weight: 500;
  cursor: pointer;
  color: #48e8cc;
  margin-left: 8px;
`

export const PanenlTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #3B199F;
  line-height: 17px;
`
export const PanenlContent = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #988EB7;
  line-height: 17px;
`
