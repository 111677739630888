import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

export const InfoWrapper = styled.div`
  width: 100%;
  max-width: 1280px;
  ${!isMobile && 'padding: 0 44px;margin-top: -30px;'};
`

export const InfoTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  .title {
    padding: 16px 0;
    font-size: 18px;
    font-weight: 600;
    color: #281071;
    line-height: 21px;
  }
`

export const CardWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  grid-gap: 16px;
  .card {
    ${isMobile && 'width:95.5vw'};
    height: 390px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(109, 78, 201, 0.06);
    border-radius: 24px;
    padding: 24px;
  }
  .title {
    font-size: 14px;
    font-weight: 600;
    color: #6d4ec9;
    line-height: 22px;
  }
`

export const Text = styled.div<{ color?: string; bold?: number; fontSize?: string }>`
    color:  ${({ color }) => (color ? color : '#281071')}
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
    font-weight: ${({ bold }) => (bold ? bold : 400)};
    line-height: 1.5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis

`
