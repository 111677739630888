import styled from 'styled-components'
import React, { useContext, useEffect, useState } from 'react'
import { ButtonLightGreen, ButtonNext } from '../../components/Button'
import useRedEnvelopeStore, { RedEnvelopeType } from '../../store/redEnvelope'
import { ReactComponent as IconSats } from '../../assets/RedEnvelope/icon_sats.svg'
import { useRedEnvelopeAction } from '../../hooks/useRedEnvelopeAction'
import { Loading } from '../../components/Loading'
import { PopUpMessageContext } from '../../hooks/PopUpMessageProvider'
import { useActiveWeb3React } from '../../hooks'
import { Text } from 'rebass'
import BigNumber from 'bignumber.js'
import { useBridgeIcon } from '../../hooks/useBridgeIcon'
import { Dropdown } from 'antd'
import { SelectResult } from '../../components/AntdSelect'
import { SUPPORTED_TOKENS } from '../../constants/redEnvelope'
import { useMultipleContractSingleData } from '../../state/multicall/hooks'
import ERC20_INTERFACE from '../../constants/abis/erc20'
import { ethers } from 'ethers'
import SwapArrow from '../Swap/SwapArrow'
import { toLocaleString } from '../../utils/toLocaleString'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  .input-group {
    padding: 32px 0 56px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    .input-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .title {
        font-size: 14px;
        font-weight: 400;
        color: #988eb7;
        line-height: 22px;
        padding-bottom: 6px;
      }
      .input-wrapper {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #988eb7;
        min-width: 406px;
        padding: 6px;

        input {
          font-size: 14px;
          font-weight: 400;
          color: #281071;
          line-height: 17px;
          flex: 1;
          border: none;
          outline: none;
          &:focus {
            border: none;
            outline: none;
          }
        }
        .icon-sats {
          display: flex;
          align-items: center;
          min-width: 64px;
          font-size: 14px;
          font-weight: 500;
          color: #281071;
          line-height: 17px;
        }
      }
    }
  }
  .button-group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
  .total-amount-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 8px;
    .total-amount {
      display: flex;
      align-items: center;
      svg {
        width: 20px;
        height: 20px;
        margin-right: 7px;
      }
    }
  }
`

const formatNumber = (val: string, int = false, max?: number): string => {
  let inputValue = val
  if (max && parseFloat(inputValue) >= max) {
    inputValue = max.toString()
  }

  if (int) {
    inputValue = inputValue.replace(/[^0-9]/g, '')
  } else {
    inputValue = inputValue.replace(/[^0-9.]/g, '');

    inputValue = inputValue.replace(/^(\d*\.?\d*)/, '$1');
  }
  const parts = inputValue.split('.')

  if (parts.length > 1) {
    // 如果有多个小数点，只取第一个小数点之前的部分，并且只允许一个小数点
    return parts[0] + '.' + parts[1]
  } else {
    return inputValue
  }
}
interface Props {
  nextStep: () => void
  backStep: () => void
}

export const Step2: React.FC<Props> = ({ nextStep, backStep }: Props) => {
  const { createParams, setCreateParams, clearCreateParams } = useRedEnvelopeStore()
  const createRedPacket = useRedEnvelopeAction()
  const [txLoading, setTxLoading] = useState(false)
  const { showFn, setIsShowPop } = useContext(PopUpMessageContext)
  const { account } = useActiveWeb3React()
  const [envelopeAmount, setEnvelopeAmount] = useState('')
  const getIcon = useBridgeIcon()
  const balances = useMultipleContractSingleData(
    Object.values(SUPPORTED_TOKENS).map(token => token.address),
    ERC20_INTERFACE,
    'balanceOf',
    [account]
  )
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const handleInput = (type: 'count' | 'name', val: string) => {
    if (type === 'name') {
      setCreateParams({ [type]: val })
    } else {
      setCreateParams({ [type]: formatNumber(val, true, 1000) })
    }
  }

  const inputItems = [
    {
      title: 'Number of Envelopes',
      value: createParams.count,
      placeholder: 'Maximum 1000',
      onChange: e => handleInput('count', e.target.value)
    },
    {
      title: createParams.type === RedEnvelopeType.random ? 'Total Red Envelope amount' : 'Each Red Envelope amount',
      value: envelopeAmount,
      placeholder: 'Enter amount',
      onChange: e => setEnvelopeAmount(formatNumber(e.target.value)),
      suffixItem: (
        <Dropdown
          visible={dropdownOpen}
          onVisibleChange={val => setDropdownOpen(val)}
          getPopupContainer={() => document.getElementById('token-selector')}
          overlayStyle={{ minWidth: '260px', right: '0' }}
          overlay={
            <SelectResult style={{ width: '100%', maxHeight: '216px', overflowY: 'scroll' }}>
              {Object.keys(SUPPORTED_TOKENS).map((token, index) => (
                <li
                  key={SUPPORTED_TOKENS[token].address}
                  onClick={() => {
                    setCreateParams({ tokenInfo: SUPPORTED_TOKENS[token] })
                    setDropdownOpen(false)
                  }}
                >
                  <div
                    className="rowItem"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '12px 0',
                      margin: 0
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ marginRight: '8px' }}>{getIcon(SUPPORTED_TOKENS[token].symbol)}</div>
                      {SUPPORTED_TOKENS[token].symbol}
                    </div>
                    <div>
                      {new BigNumber(String(balances?.[index]?.result?.[0] || 0))
                        .dividedBy(Math.pow(10, SUPPORTED_TOKENS[token].decimals))
                        .toFixed(4)}
                    </div>
                    {/*<div>{option?.name}</div>*/}
                    {/*<div className="balance">{option?.balance}</div>*/}
                  </div>
                </li>
              ))}
            </SelectResult>
          }
          trigger={['click']}
        >
          <div id={'token-selector'} className="icon-sats" style={{ cursor: 'pointer' }}>
            {getIcon(createParams.tokenInfo.symbol, { width: '20px', height: '20px', marginRight: '6px' })}
            <Text paddingRight={'8px'}>{createParams.tokenInfo.symbol}</Text>
            <div>
              <SwapArrow isUp={dropdownOpen} />
            </div>
          </div>
        </Dropdown>
      )
    },
    {
      title: 'Envelope Greeting',
      value: createParams.name,
      placeholder: 'Best wishes',
      onChange: e => handleInput('name', e.target.value)
    }
  ]

  const handleTx = async () => {
    try {
      setIsShowPop(false)
      setTxLoading(true)
      await createRedPacket()
      nextStep()
    } catch (e) {
      let errorMessage = ''
      if (e?.data?.message && e?.data?.message === 'evm error: OutOfFund') {
        errorMessage = 'Insufficient BTC balance'
      } else {
        errorMessage = e?.response?.data || e?.data?.message || e?.message || e
      }
      showFn('', '', false, errorMessage)
    } finally {
      setTxLoading(false)
    }
  }

  useEffect(() => {
    if (createParams.type === RedEnvelopeType.random) {
      setCreateParams({ tokenAmount: envelopeAmount })
    } else {
      const totalAmount = new BigNumber(createParams.count || 0).multipliedBy(envelopeAmount || 0).toString()
      setCreateParams({ tokenAmount: totalAmount })
    }
  }, [createParams.count, createParams.type, envelopeAmount])

  return (
    <Wrapper>
      <div className="input-group">
        {inputItems.map(i => (
          <div key={i.title} className={'input-item'}>
            <div className={'title'}>{i.title}:</div>
            <div className={'input-wrapper'}>
              <input placeholder={i.placeholder} value={i.value} onChange={i.onChange} />
              {i.suffixItem && i.suffixItem}
            </div>
          </div>
        ))}
        <div className="total-amount-wrapper">
          <Text fontSize={14} fontWeight={400} color={'#988EB7'} lineHeight={'22px'}>
            Total Amount:
          </Text>
          <div className={'total-amount'}>
            {getIcon(createParams.tokenInfo.symbol, { width: '20px', height: '20px' })}
            <Text marginLeft={'7px'} fontSize={16} fontWeight={500} color={'#281071'} lineHeight={'20px'}>
              {toLocaleString(createParams.tokenAmount)} {createParams.tokenInfo.symbol}
            </Text>
          </div>
        </div>
      </div>
      <div className="button-group">
        <ButtonLightGreen
          borderRadius={'20px'}
          padding={'12px 0'}
          width={'124px'}
          onClick={() => {
            backStep()
            clearCreateParams()
          }}
        >
          Back
        </ButtonLightGreen>
        <ButtonNext
          disabled={
            !createParams.name ||
            !createParams.count ||
            !createParams.tokenAmount ||
            Number(createParams.count) === 0 ||
            Number(createParams.tokenAmount) === 0 ||
            txLoading ||
            !account
          }
          onClick={handleTx}
        >
          {txLoading && <Loading size={16} />}
          {account ? 'Continue' : 'Connect Wallet'}
        </ButtonNext>
      </div>
    </Wrapper>
  )
}
