import { Table } from 'antd'
import React from 'react'
import styled from 'styled-components'

const TableWrapper = styled.div`
  margin: 24px;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(109, 78, 201, 0.06);
  border-radius: 12px;
  .ant-table-container {
    .ant-table-thead {
      .ant-table-cell {
        background: #eef0fc;
        font-size: 14px;
        font-weight: 600;
        color: #6d4ec9;
        line-height: 22px;
        padding: 8px 16px;
        white-space: nowrap;
        //border-radius: 2px;
        &:before {
          display: none;
        }
        &:first-child {
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
        }
        &:last-child {
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
        }
      }
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      font-size: 14px;
      font-weight: 400;
      color: #281071;
      line-height: 20px;
      padding: 12px 16px;
      background: white;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      &.ant-table-cell-row-hover {
        background: #f1f5fb;
        //border-radius: 12px;
        &:first-child {
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
        }
        &:last-child {
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }
    }
  }
`

interface Props {
  data: any[]
  columns: Record<string, React.ReactNode>[]
  loading: boolean
}

const HistoryTable: React.FC<Props> = ({ data, columns, loading }: Props) => {
  return (
    <TableWrapper>
      <Table loading={loading} columns={columns} dataSource={data} pagination={false} />
    </TableWrapper>
  )
}

export default React.memo(HistoryTable)
