import React, { useEffect, useState } from 'react'
import QRCode from 'qrcode.react'
import ReactDOM from 'react-dom'
// import QrcodeBg from '../../assets/RedEnvelope/img_qrcode_bg.png'
import QrcodeBg from '../../assets/RedEnvelope/img_redenvelope_banner.png'
import styled from 'styled-components'
import useRedEnvelopeStore from '../../store/redEnvelope'
import { ButtonLightGreen, ButtonNext } from '../Button'
import { Text } from 'rebass'
import { ReactComponent as IconClose } from '../../assets/RedEnvelope/icon_close.svg'
import html2canvas from 'html2canvas'
import useCopyClipboard from '../../hooks/useCopyClipboard'
import { promotionWritingsEnUs, promotionWritingsZhCn } from '../../constants/redEnvelope'

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .qrcode {
    width: fit-content;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    .banner {
      //height: 90vh;
      width: 400px;
    }
    .HpQrcode {
      position: absolute;
      top: 81.3%;
      left: 17%;
      transform: translate(-50%, -40%);
    }
    .txHash {
      position: absolute;
      top: 4%;
      left: 81.5%;
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
      line-height: 12px;
      width: 50px;
      height: 49px;
      word-break: break-all;
      overflow: hidden;
    }
    .button-group {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 16px;
    }
  }
  .mask {
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, 0.45);
  }
  .close {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    top: 2%;
    left: 104%;
    cursor: pointer;
    z-index: 4;
    svg {
      width: 14px;
      height: 14px;
    }
  }
  .name {
    position: absolute;
    left: 61%;
    top: 76.5%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    word-break: break-all;
    text-align: left;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
    width: 210px;
    height: 60px;
    overflow: hidden;
  }
`

interface Props {
  visible: boolean
  onClose: () => void
}

const QRCodeGenerator: React.FC<Props> = ({ visible, onClose }: Props) => {
  const { createdId, createParams, txHash } = useRedEnvelopeStore()
  const [isCopied, setCopied] = useCopyClipboard()

  const url = createdId ? `${window.location.origin}/#/envelope?eid=${createdId}` : ''
  // const url = `${window.location.href}?id=f48b9cea-64d3-4bdc-80f7-52c4cd3faecb`

  const saveQRCode = async () => {
    // 保存二维码图片
    if (url) {
      const container: HTMLElement = document.querySelector('.qrcode-container')
      if (container) {
        const canvas = await html2canvas(container, { backgroundColor: null })
        const dataUrl = canvas.toDataURL('image/png')
        const a = document.createElement('a')
        a.href = dataUrl
        a.download = `${createdId}.png`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    }
  }

  const onCopy = () => {
    if (navigator.language.includes('zh')) {
      setCopied(`${promotionWritingsZhCn}${url}`)
    } else {
      setCopied(`${promotionWritingsEnUs}${url}`)
    }
  }

  useEffect(() => {
    if (visible) {
      document.body.style.overflowY = 'hidden'
      document.body.style.height = '100vh'
    } else {
      document.body.style.overflowY = 'auto'
      document.body.style.height = 'auto'
    }
  }, [visible])

  if (!visible) {
    return null
  }

  return ReactDOM.createPortal(
    <Wrapper>
      <div className={'qrcode'}>
        <div className="qrcode-container">
          <img className={'banner'} src={QrcodeBg} alt="" />
          <Text className={'name'} fontSize={16} fontWeight={500} color={'#281071'} lineHeight={'20px'}>
            {createParams.name}
          </Text>
          {url && (
            <div className={'HpQrcode'}>
              <QRCode size={95} value={url} />
            </div>
          )}
          <div className="txHash">{txHash}</div>
        </div>
        <div className="button-group">
          <ButtonLightGreen borderRadius={'20px'} padding={'12px 0'} width={'47%'} onClick={saveQRCode}>
            Down Image
          </ButtonLightGreen>
          <ButtonNext width={'47%'} onClick={onCopy}>
            {isCopied ? 'Copied !' : 'Copy URL'}
          </ButtonNext>
        </div>
        <div className={'close'} onClick={onClose}>
          <IconClose />
        </div>
      </div>
      <div className="mask" />
    </Wrapper>,
    document.body
  )
}

export default QRCodeGenerator
