import React from 'react'
import { ModalProps } from 'antd'
import { AntdModalWrapper } from './styles'
import { CloseIcon } from '../../theme'

interface Props extends ModalProps {
  children: React.ReactNode
  onCancel: () => void
  title: React.ReactNode
  width?: string | number
  minHeight?: string
  overflow?: string
}

const Dialog: React.FC<Props> = ({ title, onCancel, children, minHeight, overflow, width = 432, ...rest }: Props) => {
  return (
    <AntdModalWrapper
      title={
        <>
          {title}
          <CloseIcon onClick={onCancel} />
        </>
      }
      centered
      closable={false}
      closeIcon={null}
      width={width}
      minheight={minHeight}
      overflow={overflow}
      placement={'bottom'}
      {...rest}
    >
      {children}
    </AntdModalWrapper>
  )
}

export default Dialog
