import React, { useEffect } from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

const Wrapper = styled.div`
  box-shadow: 0px 2px 4px 0px rgba(109, 78, 201, 0.06);
  border-radius: 16px;
  border: 2px solid #ffffff;
  margin: ${isMobile?'2.1vw 0 0':'16px 0 0'};
`
interface WrapperProps {
  content: React.ReactNode
}
export default function WrapperBox({ content }: WrapperProps) {
  return <Wrapper>{content}</Wrapper>
}
