import React from 'react'
import styled from 'styled-components'

const CardWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(109, 78, 201, 0.06);
  border-radius: 24px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 12px;
  &:last-child {
    margin: 0;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
    color: #281071;
    line-height: 21px;
    padding-bottom: 16px;
    margin: 0;
  }
  .spin-wrapper {
    width: 100%;
    padding: 0;
  }
`

interface Props {
  children: React.ReactNode
  title: string
}

export const PoolDetailsCard: React.FC<Props> = ({ children, title }: Props) => {
  return (
    <CardWrapper>
      <h4>{title}</h4>
      {children}
    </CardWrapper>
  )
}
