import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Tabs } from 'antd'
import { useLocation } from 'react-router-dom'
import animationData from '../../assets/RedEnvelope/animation.json'
import Lottie from 'react-lottie'

const SelectWrapper = styled.div`
  .ant-tabs-tab-btn {
    font-size: 14px;
    font-weight: 500;
    color: #281071;
    line-height: 22px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #6d4ec9 !important;
  }

  .ant-tabs-tab-btn:focus,
  .ant-tabs-tab-remove:focus,
  .ant-tabs-tab-btn:active,
  .ant-tabs-tab-remove:active {
    color: #6d4ec9 !important;
  }

  .ant-tabs-ink-bar {
    margin-top: -2px;
    background-color: #6d4ec9 !important;
  }

  .ant-tabs-top > .ant-tabs-nav:before {
    border-bottom: transparent;
  }
  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    margin: 0 !important;
  }
  .ant-tabs-tab {
    margin: 0 20px;
    padding: 4px 0;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 20px;
  }
`

const { TabPane } = Tabs

const RouteList = [
  { title: 'Redeem', route: 'swap' },
  // { title: 'Pool', route: 'pool' },
  { title: 'Pools', route: 'pools' },
  { title: 'Bridge', route: 'https://bevm.io/bridge' },
  { title: 'Mint brc-20', route: 'https://bitbox.bevm.io' },
  { title: 'Info', route: 'info' },
  // { title: 'Envelope', route: 'envelope' },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center'}}>
        Red Envelope
        <Lottie
          isClickToPauseDisabled
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData
          }}
          style={{ height: '20px', marginLeft: '6px' }}
        />
      </div>
    ),
    route: 'redEnvelope'
  },
  { title: 'Tool', route: 'tool' },
]

const HeaderRouter: React.FC = () => {
  const routeArray = RouteList.map(i => i.route)
  const { pathname } = useLocation()

  const [initPath, setInitPath] = useState(pathname.slice(1).split('/')[0])

  const swapTab = useCallback(key => {
    if (key.includes('https://')) {
      window.open(key)
      return
    }
    window.location.hash = key
  }, [])

  useEffect(() => {
    const resultPath = pathname.slice(1).split('/')[0]
    setInitPath(resultPath)
  }, [pathname])

  return (
    <SelectWrapper>
      <Tabs className={'swapTab'} activeKey={routeArray.includes(initPath) ? initPath : 'pools'} onChange={swapTab}>
        {RouteList?.map(item => {
          return (
            <TabPane
              tab={item.title}
              // disabled={chainId === 1501 && !CanUseList.includes(item.title)}
              key={item.route}
            />
          )
        })}
      </Tabs>
    </SelectWrapper>
  )
}

export default HeaderRouter
